// @mui material components
import { useTheme } from "@emotion/react";
import { Button, Divider, Icon, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import MDTypography from "components/MDTypography";
import CategoryDialog from "components/controls/CategoryDialog";

//  React example components
import Controls from "components/controls/Controls";
import CustomInput from "components/controls/CustomInput";
import { useState } from "react";

function ClaimAmount({
  features,
  isLoading,
  stepName,
  handleInputChange,
  updateStep,
}) {
  const showFeatures = [
    "Claim Amount (ZAR)",
    "Claim Status",
    "Category and claim amount",
    "Reason for Invalidation",
  ];
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const categories = features[showFeatures[2]]?.value;

  const theme = useTheme();
  const { palette } = theme;
  const { white, secondary, primary, error } = palette;

  const handleDeleteItem = (name) => {
    delete categories[name]
    handleInputChange(
      {
        target: {
          name: "Category and claim amount",
          value: categories
        },
      },
      features[showFeatures[2]].type
    )
  }
  const handleAddOrUpdateItem = (name, amount) => {
    handleInputChange(
      {
        target: {
          name: "Category and claim amount",
          value: {
            ...categories,
            [name]: amount != "" ? parseFloat(amount) : parseFloat("0"),
          },
        },
      },
      features[showFeatures[2]].type
    );
  };

  const displayFeatures = ([featureName, feature]) => {
    let ControlType = Controls[feature.type];
    let isViewable = feature.view;
    let isEditable = feature.edit;
    let preValidationList = feature["pre_update_validation_functions"];
    if (isViewable && ControlType && showFeatures.includes(featureName)) {
      return (
        <ControlType
          name={featureName}
          label={featureName}
          value={feature.value}
          onChange={(e) => {
            handleInputChange(e, feature.type);
          }}
          disabled={!isEditable}
          options={feature.scope}
          keyscopes={feature.keyscope}
          stepDetails={features}
          amount={""}
          preValidationList={preValidationList}
          showborder={false}
          textAlign={"center"}
        />
      );
    }
  };

  return (
    <MDBox sx={{ padding: "1rem", width: "100%", height: "100%" }}>
      <MDBox
        sx={{
          alignContent: "center",
          gap: "1rem",
          spacing: 2,
        }}
      >
        {displayFeatures([showFeatures[0], features[showFeatures[0]]])}
        <MDBox sx={{ marginTop: "1rem" }}></MDBox>
        {displayFeatures([showFeatures[3], features[showFeatures[3]]])}
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "1rem",
            gap: "1rem",
          }}
        >
          {displayFeatures([showFeatures[1], features[showFeatures[1]]])}
          <MDBox
            sx={{
              border: `1px solid ${palette.grey[300]} !important`,
              padding: "0.5rem",
              borderRadius: "0.75rem",
              width: "100%",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="headline"
                sx={{ fontSize: "1rem !important", fontWeight: "400" }}
              >
                {" "}
                {showFeatures[2]}{" "}
              </Typography>
              <Tooltip title={"Add New Item"}>
                <Icon
                  sx={{ cursor: "pointer", fontSize: "1.5em !important" }}
                  onClick={() => {
                    setShowCategoryDialog(!showCategoryDialog);
                  }}
                >
                  add
                </Icon>
              </Tooltip>
            </MDBox>
            <MDBox p={2}>
              {categories.length != 0 &&
                Object.keys(categories).map((category, index) => {
                  return (
                    <MDBox
                      sx={{ display: "flex", position: "relative" }}
                      key={index}
                    >
                      <MDBox
                        sx={{
                          display: "flex",
                          width: "100%",
                          textAlign: "right",
                          float: "right",
                          padding: "0.5rem",
                          marginBottom: "0.5rem",
                          justifyContent: "space-between",
                          border: `0.1px solid ${theme.palette.grey[300]}`,
                          borderRadius: "5px",
                        }}
                      >
                        <MDTypography
                          sx={{
                            textAlign: "left",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1em",
                          }}
                        >
                          {category}
                        </MDTypography>
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                            maxWidth: "40%",
                          }}
                        >
                          <CustomInput
                            value={
                              category == "Not decided"
                                ? categories[category]?.toFixed(2)
                                : categories[category]
                            }
                            name={category}
                            parentName={name}
                            inputProps={{ style: { textAlign: "center" } }}
                            style={{
                              textAlign: "center",
                              display: "block",
                            }}
                            onChange={(e) => {
                              handleAddOrUpdateItem(category, e.target.value);
                            }}
                            disabled={category == "Not decided" ? true : false}
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          right: "-8px",
                          top: "-5px",
                          position: "absolute",
                        }}
                      >
                        <Tooltip
                          title={"Delete"}
                          sx={{ cursor: "pointer", fontSize: "1.2em !important" }}
                          className={"font-unset"}
                        >
                          {category != "Not decided" && (
                            <Icon onClick={(e) => {handleDeleteItem(category)}} color={"error"}>delete</Icon>
                          )}
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  );
                })}
            </MDBox>
          </MDBox>
        </MDBox>

        <MDChip
          sx={{
            backgroundColor: primary.main,
            padding: "5px 10px",
            color: white.main,
            fontWeight: "bold",
            cursor: "pointer",
            marginTop: "10px",
            position: "sticky",
            float: "right",
            right: 0,
            bottom: 0,
          }}
          label={"Update"}
          onClick={(e) => updateStep(stepName)}
        />
      </MDBox>

      {showCategoryDialog && (
        <CategoryDialog
          open={showCategoryDialog}
          handleClose={setShowCategoryDialog}
          updateCategoryList={handleAddOrUpdateItem}
          categoryList={features[showFeatures[2]]?.keyscope?.filter(
            (category) => {
              if (!categories[category]) {
                return true;
              } else {
                return false;
              }
            }
          )}
        ></CategoryDialog>
      )}
    </MDBox>
  );
}

export default ClaimAmount;
