import { AppBar, Dialog, Grow, Paper, Toolbar, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import { useStyles } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import RenderNotifications from "examples/Lists/NotificationsList/renderNotifications";

const videoLinks = {
  '/cases': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/All-claim-list.mp4",
  'cases/': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/Claim-details.mp4",
  'analytics': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/Analytics-screen.mp4",
  'userManagement': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/User-management.mp4",
  'bulk': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/Upload-claims.mp4",
  'workflowUpdate': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/Workflow-update.mp4",
  'workflowview': "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/walkthrough/Workflow-view.mp4"
};

const ClaimNavbar = ({ icon, leftContent, rightContent }) => {
  const styles = useStyles();
  const location = useLocation();
  const navigation = useNavigate();
  const pathname = location.pathname.replace("/", "");
  const [showVideo, setShowVideo] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { palette, functions } = useTheme();
  const { primary, white, secondary, warning } = palette;
  const notificationRef = useRef(null);
  const [notificationCount, setNotificationsCount] = useState(0)

  useEffect(() => {
    const handleClickOutside = (event) => {
    if (showNotification && notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotification(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showNotification]);

  const videoLink = (() => {
    for (const [key, link] of Object.entries(videoLinks)) {
      if (pathname.includes(key)) return link;
    }
    return false;
  })();

  return (
    <>
      <AppBar position={"sticky"} color="white" sx={styles.navbarContainer}>
        <Toolbar sx={styles.flexBox}>
          <MDBox sx={styles.leftCard}>
            {icon}
            {leftContent}
            <Tooltip title={"Video Demo Of This Page"}>
              {videoLink && (
                <MDBox
                  onClick={() => setShowVideo(true)}
                  color={"primary"}
                  sx={{ display: "flex", cursor: "pointer" }}
                >
                  <span class="material-symbols-outlined">info</span>
                </MDBox>
              )}
            </Tooltip>
          </MDBox>
          <MDBox sx={{ ...styles.flexBox, gap: "1rem" }}>
            {rightContent}
            <MDBox
              sx={{ display: "flex", position: "relative" }}
              color={"primary"}
              ref={notificationRef}
            >
              {notificationCount !== 0 && (
                <MDBox
                  sx={{
                    position: "absolute",
                    backgroundColor: secondary.main,
                    paddingTop: "1px",
                    top: "-.8em",
                    right: "-.8em",
                    transform: "scale(.8)",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    fontSize: "12px",
                    borderRadius: "100px",
                    ...(notificationCount < 100
                      ? {}
                      : {
                          width: "10px",
                          height: "10px",
                          top: "-.3em",
                          right: "-.2em",
                        }),
                  }}
                  color={white.main}
                >
                  {notificationCount < 100 ? notificationCount : ""}
                </MDBox>
              )}
              <span
                style={{ cursor: "pointer" }}
                class="material-symbols-outlined"
                onClick={() => setShowNotification((pre) => !pre)}
              >
                notifications
              </span>
              <Grow in={showNotification}>
                <MDBox
                  p={1}
                  sx={{
                    position: "absolute",
                    right: "-24px",
                    top: "45px",
                    width: "350px",
                    minHeight: "350px",
                    maxHeight: "400px",
                    borderRadius: "10px",
                    backgroundColor: white.main,
                    boxShadow: "0px 0px 8px 1px #0000000D",
                    overflowY: "auto",
                  }}
                >
                  <MDBox
                    pl={1}
                    pt={1}
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    Notifications
                  </MDBox>
                  <RenderNotifications
                    pastDays={7}
                    fontSize={"14px"}
                    setNotificationsCount={setNotificationsCount}
                  />
                  <MDBox
                    sx={{
                      display: "flex",
                      position: 'sticky',
                      top: '95%',
                      gap: "5px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    color={primary.main}
                    onClick={() => navigation("/profile", { state: { tab: "Notifications" } })}
                  >
                    <span
                      style={{ fontSize: "1.2em" }}
                      class="material-symbols-outlined"
                    >
                      north_east
                    </span>
                    More Notifications
                  </MDBox>
                </MDBox>
              </Grow>
            </MDBox>
          </MDBox>
        </Toolbar>
      </AppBar>
      <Dialog
        open={showVideo}
        onClose={() => setShowVideo(false)}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
            height: "70%",
            maxHeight: "450px",
          },
        }}
      >
        <video
          src={videoLink}
          style={{ width: "100%", height: "100%" }}
          controls
          autoPlay
        ></video>
      </Dialog>
    </>
  );
};

export default ClaimNavbar;
