// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { workflowAPI } from "api";
import { useTheme } from "@emotion/react";
import MDChip from "components/MDChip";

function NameChange({ open, handleClose, customerDetails, callbackFunction }) {
  const [state, setState] = useState(customerDetails);
  const navigation = useNavigate();

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return word[0].toUpperCase() + word.slice(1);
      })
      .join(" ");
    return name;
  };

  const handleChange = (event) => {
    setState({ ...state, value: event.target.value });
  };

  const handleDataChange = () => {
    workflowAPI
      .updateCustomerCodeMaps({
        customer_code: state.customer_code,
        [state.key]: state.value,
      })
      .then((res) => {
        if (res.status == 200) {
          handleClose(!open);
          callbackFunction();
        }
      })
      .catch((error) => {});
  };

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "27rem" }}
      >
        <MDBox p={3}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: typography.size["xl"],
              fontWeight: typography.fontWeightBold,
            }}
            pb={3}
          >
            <MDBox sx={{ fontSize: "inherit" }}>
              Change {convertKeyToName(state.key)}
            </MDBox>
            <span
              class="material-symbols-outlined"
              style={{ color: primary.main }}
            >
              upload
            </span>
          </MDBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  value={state.customer_code}
                  name={"customer_code"}
                  label={"Customer Code"}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label={convertKeyToName(state.key)}
                onChange={handleChange}
                name={state.key}
                value={state.value}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={2} md={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                pt={1}
              >
                <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: "1px solid " + error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />
                <MDChip
                  sx={{
                    backgroundColor: primary.main,
                    padding: "5px 10px",
                    color: white.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "140px",
                  }}
                  label={"Change"}
                  onClick={handleDataChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
    </Dialog>
  );
}

export default NameChange;
