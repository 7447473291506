import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SuggestionSkeleton() {
  return (
    <SkeletonTheme highlightColor="white">
      <Card
        sx={{
          width: "90%",
          padding: "0 1rem 1rem 1rem",
          margin: "0rem 1rem 1rem 1rem ",
          position: "relative",
          whiteSpace: "pre-wrap",
        }}
      >
        <Skeleton height={"5rem"} />
      </Card>
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Card
          sx={{
            width: "90%",
            padding: "1rem",
            margin: "0 1rem",
            position: "relative",
            marginBottom: "1rem",
          }}
        >
          <Skeleton height={"10rem"} />
        </Card>
      </MDBox>
    </SkeletonTheme>
  );
}

export default SuggestionSkeleton;
