import MDBox from "components/MDBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MessageSkeleton = () => {
  return (
    <SkeletonTheme highlightColor="white">
      {Array(2)
        .fill(0)
        .map((item, index) => {
          return (
            <MDBox
              my={2}
            >
              <MDBox
                sx={{
                  width: "90%",
                  padding: "0.5rem",
                  marginBottom: "0",
                }}
              >
                <Skeleton width={"30%"} height={'10px'}/>
                <Skeleton width={"60%"} height={'8px'}/>
                <Skeleton height={'10px'}/>
                <Skeleton width={"70%"} height={'10px'}/>
                <Skeleton width={"40%"} height={'10px'} />
              </MDBox>
            </MDBox>
          );
        })}
    </SkeletonTheme>
  );
};

export default MessageSkeleton;
