// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  AppBar,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { TabContext, TabPanel } from "@mui/lab";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

//  React components

import MDButton from "components/MDButton";

// Billing page components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import LoadingOverlay from "react-loading-overlay";
import CustomInput from "components/controls/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import MaxHeightTextarea from "utils/maxHeightTextArea";
import CustomAutoComplete from "components/controls/AutoComplete";
import { AdminAPI } from "api";
import TableDisplay from "layouts/tableDisplay/tableDisplay";
import UserDetailsForm from "./dialogs/updateUserDetails";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import { OutlinedButton } from "components/MDButton/button";

const ignoreColumns = ["id"];

function UserManagement() {
  const navigation = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [showUserDetailsForm, setShowUserDetailsForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userEmailList, setUserEmailList] = useState([]);
  const [formType, setFormType] = useState("Update");

  const handleRowClick = (row) => {
    setShowUserDetailsForm(true);
    setSelectedUser(row?.values);
    setFormType("Update");
  };

  const getUsers = () => {
    setIsLoading(true);
    AdminAPI.getAdminUserData()
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setUserEmailList(res.data.map((user) => user.email_id));
          setUserList(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console;
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const styles = useStyles();

  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              manage_accounts
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            User Management
          </MDBox>
        }
        rightContent={
          <OutlinedButton
            name={"Add User"}
            onClick={() => {
              setShowUserDetailsForm(true);
              setSelectedUser({});
              setFormType("Create");
            }}
            icon={
              <Icon className={"font-unset"} fontSize="1em">
                add
              </Icon>
            }
          />
        }
      />
      <MDBox pt={2}>
        <TableDisplay
          data={userList}
          isLoading={isLoading}
          downloadable={true}
          canSearch={true}
          ignoreColumns={ignoreColumns}
          rowClick={handleRowClick}
          tableHeight={'calc(var(--h-100vh) - 185px)'}
        />
      </MDBox>
      {showUserDetailsForm && (
        <UserDetailsForm
          open={showUserDetailsForm}
          handleClose={setShowUserDetailsForm}
          getUsers={getUsers}
          userDetails={selectedUser}
          userList={userEmailList}
          type={formType}
        />
      )}
    </DashboardLayout>
  );
}

export default UserManagement;
