import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const NotesSkeleton = () => {
  const { palette, typography, functions } = useTheme();
  const { primary, white } = palette;
  const { pxToRem } = functions;
  return (
    <SkeletonTheme highlightColor="white">
      {Array(2)
        .fill(0)
        .map((item, index) => {
          return (
            <MDBox
              key={index}
              sx={{
                margin: pxToRem(20),
                marginTop: pxToRem(5),
              }}
            >
              <MDBox
                sx={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  textAlign: "right",
                }}
              >
                <Skeleton width={"50%"} />
              </MDBox>
              <MDBox
                style={{
                  fontSize: typography.size["sm"],
                  padding: "7px 11px",
                  background: primary.light,
                  borderRadius: "8px",
                  background: white.main,
                  boxShadow: "0 0 5px 1px #00000014",
                }}
              >
                <Skeleton />
                <Skeleton width={"70%"} />
              </MDBox>
              <MDBox
                sx={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  textAlign: "right",
                }}
              >
                <Skeleton width={"40%"} />
              </MDBox>
            </MDBox>
          );
        })}
    </SkeletonTheme>
  );
};

export default NotesSkeleton;
