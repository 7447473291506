// react-router-dom components
import { useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

//  React components
import { setGlobalState } from "Global";
import { SignUpAPI } from "api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import OtpInput from "react-otp-input";
import * as yup from "yup";
import usePasswordGenerator from "./PasswordGen";
import bgImage from "assets/images/background.png"

// Authentication layout components

// Images
import BasicLayout from "../components/BasicLayout";
import { useTheme } from "@emotion/react";

function Cover() {
  const [emailBool, setEmailBool] = useState(true);
  const [emailState, setEmailState] = useState("");
  const [otpBool, setOtpBool] = useState(false);
  const [otp, setOtp] = useState(null);
  const [complete, setComplete] = useState(false);
  const [text, setText] = useState("");
  const [passwdBool, setPasswdBool] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const { password, generatePassword } = usePasswordGenerator();

  const passwordRules =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*()_+!]).{8,}$/;
  const passwdValidationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .matches(passwordRules, { message: "Please create a stronger password" })
      .required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const submitEmail = async (email) => {
    setEmailState(email.replace(/['"]+/g, ""));
    try {
      const res = await SignUpAPI.signUpEmail(email.replace(/['"]+/g, ""));
      if (res.status == 200) {
        setOtpBool(true);
        setEmailBool(false);
        setGlobalState("error", {
          open: true,
          message: `${res.data}`,
          type: "success",
        });
      }
    } catch (err) {
      setGlobalState("error", {
        open: true,
        message:
          "User not authorized, please contact admin for more information",
        type: "error",
      });
    }
  };

  const submitPassword = async (passwd) => {
    const pass = passwd.replace(/['"]+/g, "");
    try {
      const params = {
        otp: otp,
        passwd: pass,
      };
      const res = await SignUpAPI.signUpEmailPasswd(params, emailState);

      if (res.status == 200) {
        setEmailBool(false);
        setPasswdBool(false);
        setComplete(true);
        setGlobalState("error", {
          open: true,
          message: `${res.data}`,
          type: "success",
        });
      }
    } catch (err) {
      console.log(err);
      setGlobalState("error", {
        open: true,
        message: `${err.response.data}`,
        type: "error",
      });
    }
  };

  const handleOTPSubmission = async () => {
    try {
      const res = await SignUpAPI.signUpEmailOTP({ otp: otp }, emailState);
      if (res.status == 200) {
        setOtpBool(false);
        setPasswdBool(true);
        setGlobalState("error", {
          open: true,
          message: `Success, Enter the password. The password must contain at least one digit (0-9), one lowercase letter (a-z), one uppercase letter (A-Z) and it must have one special character`,
          type: "success",
        });
      }
    } catch (err) {
      console.log(err.response.data);
      setGlobalState("error", {
        open: true,
        message: `${err.response.data}`,
        type: "error",
      });
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = SignUpAPI.signUpEmailResend(emailState);
      if (res.status == 200) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik2 = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: passwdValidationSchema,
    onSubmit: (values) => {
      const passwd = JSON.stringify(values.password, null, 1);
      submitPassword(passwd);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const email = JSON.stringify(values.email, null, 1);
      submitEmail(email);
    },
  });
  let textVal = null;
  if (emailBool) textVal = "Enter your Email to Verify User";
  else if (otpBool) textVal = "Verify OTP sent to the email";
  else textVal = "Please set the Password";

  const inputClass = {
    width: "1.7rem",
    height: "45px",
    borderRadius: "5px",
    border: "1px solid #000",
    marginLeft: "10px",
    marginRight: "8px",
    background: "#FAF3F0",
    fontSize: "20px",
    color: "#000",
  };

  // console.log(password);
  // const setGeneratedPassword = () => {
  //   formik2.setFieldValue("password", password)
  //   formik2.setFieldValue("passwordConfirmation", password);
  // };
  const theme = useTheme()

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: "2rem" }}>
        <MDBox
          sx={(theme) => ({
            fontSize: theme.typography.size["xl"],
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          })}
          px={3}
          pt={2}
        >
          <MDBox sx={{ fontSize: "inherit" }}>Reset Password</MDBox>
          <span
            class="material-symbols-outlined"
            style={{ color: theme.palette.primary.main }}
          >
            restart_alt
          </span>
        </MDBox>

        {emailBool && (
          <MDBox py={2} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </MDBox>
              <MDBox mt={4}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  sx={(theme) => ({
                    borderRadius: "100px",
                    backgroundColor: theme.palette.primary.main,
                  })}
                >
                  Verify Email
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        {otpBool && (
          <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography variant="h6" fontWeight="medium" mt={1}>
              Enter OTP
            </MDTypography>
            <MDBox
              mt={3}
              mb={4}
              textAlign="center"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={inputClass}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </MDBox>
            <MDTypography variant="button" color="text">
              Didn't Receive?
              <MDTypography
                ml={1}
                variant="button"
                color="info"
                onClick={handleResendOtp}
                textGradient
                fontWeight="medium"
                sx={{ cursor: "pointer" }}
              >
                Resend
              </MDTypography>
            </MDTypography>

            <MDBox mt={4}>
              <MDButton
                onClick={handleOTPSubmission}
                variant="gradient"
                color="info"
                sx={(theme) => ({
                  borderRadius: "100px",
                  backgroundColor: theme.palette.primary.main,
                })}
              >
                Submit OTP
              </MDButton>
            </MDBox>
          </MDBox>
        )}
        {passwdBool && (
          <MDBox component="form" role="form" onSubmit={formik2.handleSubmit}>
            <MDBox m={2}>
              <MDInput
                type="password"
                fullWidth
                id="password"
                name="password"
                label="Pasword"
                value={formik2.values.password}
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                error={
                  formik2.touched.password && Boolean(formik2.errors.password)
                }
                helperText={formik2.touched.password && formik2.errors.password}
              />
            </MDBox>
            {/* <MDTypography
              ml={24}
              variant="button"
              color="info"
              onClick={() => {
                generatePassword();
                setGeneratedPassword();
              }}
              textGradient
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
            >
              Generate Password
            </MDTypography> */}
            <MDBox m={2}>
              <MDInput
                type="password"
                fullWidth
                id="passwordConfirmation"
                name="passwordConfirmation"
                label="confirm password"
                value={formik2.values.passwordConfirmation}
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                error={
                  formik2.touched.passwordConfirmation &&
                  Boolean(formik2.errors.passwordConfirmation)
                }
                helperText={
                  formik2.touched.passwordConfirmation &&
                  formik2.errors.passwordConfirmation
                }
              />
            </MDBox>
            <MDBox mt={4} ml={2} mr={2}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                sx={(theme) => ({
                  borderRadius: "100px",
                  backgroundColor: theme.palette.primary.main,
                })}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        )}
        {complete && (
          <MDBox mt={4} textAlign="center">
            <MDTypography
              variant="h6"
              fontWeight="medium"
              mt={1}
              color={"success"}
            >
              Password Reset Successful, Sign in to continue
            </MDTypography>
            <MDBox mt={2} mb={2}>
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        {!complete && (
          <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        )}
      </Card>
    </BasicLayout>
  );
}

export default Cover;
