import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

const DateRangePicker = ({ from, to, onChangeFrom, onChangeTo }) => {
  const theme = useTheme()

  const [fromDate, setFrom] = useState(null)
  const [toDate, setTo] = useState(null)
  const handleSetDate =()=> {
    setFrom((from)? dayjs(from): null)
    setTo((to)? dayjs(to): null)
  }
  useEffect(()=>{
    handleSetDate()
  }, [from, to])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        sx={{
          width: "115px",
          "& .MuiInputBase-root": { padding: "0" },
          "& input": {
            textAlign: "right",
            color: theme.palette.text.disabled,
            borderRadius: "100px 0 0 100px",
            backgroundColor: theme.palette.white.main,
            padding: "10px",
          },
          "& fieldset": {
            borderRadius: "100px 0 0 100px",
            borderRight: "0",
            borderColor: theme.palette.text.light,
            padding: "0",
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.text.light,
            },
            "&:hover fieldset": {
              borderColor: theme.palette.text.light,
            },
            "&.Mui-focused fieldset": {
              border: "1px solid " + theme.palette.text.light,
            },
          },
        }}
        value={fromDate}
        onAccept={onChangeFrom}
        onClose={handleSetDate}
        format="DD/MM/YYYY"
      />
      <MobileDatePicker
        format="DD/MM/YYYY"
        value={toDate}
        onAccept={onChangeTo}
        onClose={handleSetDate}
        sx={{
          width: "115px",
          "& .MuiInputBase-root": { padding: "0" },
          "& input": {
            color: theme.palette.text.disabled,
            borderRadius: "0 100px 100px 0",
            backgroundColor: theme.palette.white.main,
            padding: "10px",
          },
          "& fieldset": {
            borderRadius: "0 100px 100px 0",
            borderLeft: "0",
            borderColor: theme.palette.text.light,
            padding: "0",
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.text.light,
            },
            "&:hover fieldset": {
              borderColor: theme.palette.text.light,
            },
            "&.Mui-focused fieldset": {
              border: "1px solid " + theme.palette.text.light,
            },
          },
          "&::after": {
            content: "'--'",
            position: "absolute",
            letterSpacing: "-2px",
            left: "-3px",
            fontSize: "1em",
            top: "9px",
            color: theme.palette.text.disabled,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
