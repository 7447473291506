
import { Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Paper } from "@mui/material";
import Controls from "components/controls/Controls";
import MDChip from "components/MDChip";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { convertKeyToName } from "utils/conversion";

function DataInputDialog({
  open,
  handleClose,
  defaultInputs,
  handleSubmit,
  buttonName = "Add buttonName",
  heading = "Add heading",
  iconName = "Add google iconName",
  isLoading,
}) {
  const [state, setState] = useState({});

  const handleSubmitClick = () => {
    let isValid = true
    let temp = {}
    Object.keys(state).forEach((key) => {
        temp[key] = state[key]
        if(state[key].required && !state[key].value){
            state[key].error = true
            isValid = false
        } else {
            state[key].error = false
        }
    })
    if(isValid) {handleSubmit(state)} else {setState(temp)}
  }

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
      },
    });
  };

  const initializeUserDetails = () => {
    let tempState = {};
    Object.keys(defaultInputs).map((feature) => {
      tempState[feature] = defaultInputs[feature];
    });
    setState(tempState);
  };

  useEffect(() => {
    if (defaultInputs) {
      initializeUserDetails();
    }
  }, [defaultInputs]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "29rem" }}
      >
        <LoadingOverlay active={isLoading} spinner>
          <MDBox p={3}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: typography.size["xl"],
                fontWeight: typography.fontWeightBold,
              }}
              pb={2}
            >
              <MDBox sx={{ fontSize: "inherit" }}>{heading}</MDBox>
              <span
                class="material-symbols-outlined"
                style={{ color: primary.main }}
              >
                {iconName}
              </span>
            </MDBox>
            <Grid container columnSpacing={2}>
              {Object.keys(state).map((feature, index) => {
                let ControlType = Controls[state[feature]?.type];
                return (
                  <Grid
                    item
                    xs={index < 4 ? 12 : 6}
                    sx={{
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <FormControl fullWidth>
                      <ControlType
                        name={feature || ""}
                        variant={index < 3 ? "standard" : "outlined"}
                        label={
                          state[feature]?.label || convertKeyToName(feature)
                        }
                        value={state[feature]?.value}
                        onChange={(e, value) =>
                          handleChange(
                            value !== undefined
                              ? {
                                  target: {
                                    name: feature,
                                    value: state[feature]?.dataKey
                                      ? value?.[state[feature]?.dataKey]
                                      : value,
                                  },
                                }
                              : e
                          )
                        }
                        disabled={!state[feature]?.editable}
                        options={state[feature]?.options || []}
                        error={state[feature]?.error}
                        helperText={
                          state[feature]?.error
                            ? "This field is required."
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                );
              })}

              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: "1px solid " + error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />
                  <MDChip
                    sx={{
                      backgroundColor: primary.main,
                      padding: "5px 10px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "140px",
                    }}
                    label={buttonName}
                    onClick={() => handleSubmitClick()}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default DataInputDialog;
