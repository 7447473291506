import MDBox from "components/MDBox";
import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ZAxis,
  Scatter,
  ScatterChart,
} from "recharts";
import CustomAreaChart from "./areaChart/CustomAreaChart";
import CustomPieChart from "./pieChart/CustomPieChart";
import PropTypes from "prop-types";
import CustomBarChart from "./barChart/CustomBarChart";
import CustomScatterChart from "./scatterChart/CustomScatterChart";
import EmptyResult from "utils/emptyResult";
import { CircularProgress } from "@mui/material";

const Chart = ({
  data,
  type,
  ...props
}) => {
  return data && data?.length > 0 ? (
    type === "pie" ? (
      <CustomPieChart data={data} {...props} />
    ) : type === "bar" ? (
      <CustomBarChart data={data} {...props} />
    ) : type === "scatter" ? (
      <CustomScatterChart data={data} {...props} />
    ) : (
      <CustomAreaChart data={data} {...props} />
    )
  ) : data?.length === 0 ? (
    <EmptyResult text={"No Data Found"} />
  ) : (
    <MDBox
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'white',
      }}
    >
      <CircularProgress color="primary" />
    </MDBox>
  );
};

Chart.propTypes = {
  axisStyle: PropTypes.shape({
    stroke: PropTypes.string,
    strokeWidth: PropTypes.number,
    fontSize: PropTypes.number,
  }),
  grid: PropTypes.shape({
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool,
  }),
  graph: PropTypes.objectOf(
    PropTypes.shape({
      stroke: PropTypes.string,
      strokeWidth: PropTypes.number,
      fill: PropTypes.string,
      fillOpacity: PropTypes.number,
    })
  ),
  type: PropTypes.string,
  legend: PropTypes.shape({
    show: PropTypes.bool,
    align: PropTypes.string,
  }),
};

export default Chart;
