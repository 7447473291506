export const sparkleSvg = (color) => (
  <svg
    height="1em"
    width="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.98215 0.81543C6.08638 4.50917 4.04069 7.1007 -0.000537872 7.78113C3.52785 9.03682 5.96216 11.2634 6.98215 14.7468C8.16854 11.4851 10.506 9.17097 13.9648 7.78113C10.8918 6.97735 8.61301 4.55578 6.98215 0.81543Z"
      fill={color}
    />
    <path
      d="M15.686 10.791C15.0847 13.2694 13.7123 15.0079 11.0008 15.4647C13.3683 16.3069 15.0017 17.8009 15.686 20.1377C16.4819 17.9491 18.0504 16.3972 20.3711 15.4647C18.3092 14.9251 16.7799 13.3002 15.686 10.791Z"
      fill={color}
    />
  </svg>
);

export const plusSvg = (color) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    width="1em"
    viewBox="0 -960 960 960"
    fill={color}
  >
    <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
  </svg>
);

export const deleteSvg = (color) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    width="1em"
    viewBox="0 -960 960 960"
    fill={color}
  >
    <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
  </svg>
);
