import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { useGlobalState } from "Global";
import EmptyResult from "utils/emptyResult";

// data = {image, name, claimsCount}
const Leaderboard = ({ data, imageRef }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  const [userDetails] = useGlobalState("userDetails");

  return (
    (data && data?.length > 0) ?
    <MDBox
      sx={{
        height: "100%",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#00000000",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D3D3D3",
          borderRadius: "50px",
        },
      }}
    >
      <style jsx>{`td{border: inherit; padding: 5px}`}</style>
      <table
        style={{
          width: "100%",
          color: text.disabled,
          borderCollapse: "collapse",
        }}
        ref={imageRef}
      >
        {data?.map((data, index) => (
          <tr
            style={{
              borderBottom: "1px solid " + text.light,
              color: data?.agent_email === userDetails?.email ? white.main : (index === 0)? text.main : '',
            }}
          >
            <td
              style={{
                textAlign: "center",
                borderRadius: "100px 0 0 100px",
                background:
                  data?.agent_email === userDetails?.email ? primary.main : "",
              }}
            >
              {index + 1}
            </td>
            <td
              style={{
                background:
                  data?.agent_email === userDetails?.email ? primary.main : "",
              }}
            >
              <MDBox
                sx={{
                  height: "32px",
                  width: "32px",
                  fontSize: typography.size["lg"],
                  fontWeight: "bold",
                  background: primary.light,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100%",
                  position: "relative",
                  zIndex: "1",
                  border: `1px solid ${palette.primary.main}`,
                }}
                title={data?.agent_email || data?.agent_name }
              >
                {data?.agent_name[0]?.toUpperCase()}
              </MDBox>
            </td>
            <td
              style={{
                background:
                  data?.agent_email === userDetails?.email ? primary.main : "",
              }}
            >
              {data?.agent_name || data?.agent_email}
            </td>
            <td
              style={{
                background:
                  data?.agent_email === userDetails?.email ? primary.main : "",
              }}
            >
              {data?.total_claim_amount.toFixed(2)} ZAR
            </td>
            <td
              style={{
                background:
                  data?.agent_email === userDetails?.email ? primary.main : "",
                textAlign: "right",
                borderRadius: "0 100px 100px 0",
                paddingRight:'1em'
              }}
            >
              {data?.total_claim_count} Claims
            </td>
          </tr>
        ))}
      </table>
    </MDBox> : <EmptyResult text={"No Data Found"} />
  );
};

export default Leaderboard;
