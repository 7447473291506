import { caseAPI, workflowAPI } from "api";

export const workflowService = {
  getWorkflowDetails: (workflowId) => {
    return caseAPI
      .getWorkflowById(workflowId)
      .then((res) => {
        if (res.status == 200) {
          let tempWorkflowDetail = {
            workflow: res.data.workflow,
            workflow_first_step: res.data.workflow_first_step,
            short_name: res.data.short_name,
            init_agent: res.data.init_agent,
            departments_list: res.data.departments_list,
            name: res.data.name,
            permission: res.data.permission,
            bucketPositions: workflowService.getBucketPosition(res.data.departments_list),
          };
          return tempWorkflowDetail;
        }
      })
      .catch((error) => {});
  },
  getWorkflows: () => {
    return caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
      })
      .catch((error) => {});
  },
  createWorkflowNodes: (workflowDetail, highlightNode, onNodeClick) => {
    const { workflow, workflow_first_step } = workflowDetail
    return new Promise((resolve, reject) => {
      try {
        let tempEdges = [];
        let tempNodes = [];
        
        for (const step of Object.keys(workflow)) {
          tempNodes.push({
            id: step,
            position: { x: 0, y: 0 },
            data: { label: step, department: workflow[step]["department"], isFirstStep: workflow_first_step === step,onNodeClick},
            className: step === highlightNode ? "node-selected" : "",
            type: 'custom'
          });
  
          for (const edge of Object.keys(workflow[step]["edges"] || {})) {
            tempEdges.push({
              id: "e" + step + "-" + workflow[step]["edges"][edge],
              source: step,
              target: workflow[step]["edges"][edge],
              label: edge.includes("next_step") ? null : edge,
            });
          }
        }
        resolve({ nodes: tempNodes, edges: tempEdges });
      } catch (error) {
        reject(error);
      }
    });
  },
  
  getBucketPosition: (departmentList) => {
    let bucketWidth = 150 / departmentList.length;
    let bucketPosition = {};
    departmentList.forEach((department, index) => {
      bucketPosition[department] = (bucketWidth / 2 + bucketWidth * index) * 2;
    });
    return bucketPosition;
  },
  updateWorkflow: (data) => {
    return workflowAPI
      .updateWorkflow(data)
  },  
  updateWorkflowStep: (data) => {
    return workflowAPI
      .updateWorkflowStep(data)
  },
  createNewWorkflow: (data) => {
    return workflowAPI
      .createWorkflow(data)
  },
};
