export const formatDate = (date) => {
    const dateObj = new Date(date);

    return dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });
}

export const formatTime = (date) => {
    if (!date.includes('Z') && !date.includes('+')) {
        date += 'Z';
    }
    
    const dateObj = new Date(date);

    return dateObj.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
}