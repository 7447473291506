import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { Card, Grid, List } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Validations } from "components/controls/Validations";
import { useGlobalState } from "Global";
import ClaimOverview from "./components/claimOverview/ClaimOverview";
import { setGlobalState } from "Global";
import { featureAPI } from "api";
import CaseFiles from "./components/documents/caseFiles";
import ClaimAmount from "./components/claimAmount/claimAmount";
import PerfectScrollbar from "react-perfect-scrollbar";
import Proceed from "./components/proceed/proceed";
import AISuggestedActions from "../ai/aiSuggestedActions/AISuggestedActions";
import FilesSkeleton from "skeletons/forms/filesSkeleton";
import SummarySkeleton from "skeletons/forms/summarySkeleton";
import MDChip from "components/MDChip";
import { toFloatMark } from "utils/conversion";

const tabs = [
  "CLAIM OVERVIEW",
  "DOCUMENTS",
  "CLAIM AMOUNT",
  "REASSIGN/RESOLVE",
];
const tabicons = [
  "quick_reference",
  "folder",
  "account_balance_wallet",
  "tv_options_input_settings",
];
const featureMapping = {
  amountName: "Claim Amount (ZAR)",
};
const _ = require("lodash");

const LeftPanel = ({ caseId, caseDetails, reloadComponent }) => {
  const theme = useTheme();
  const navigation = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [validationList, setValidationList] = useState([]);
  const [userDetails] = useGlobalState("userDetails");
  const [currFeatures, setCurrFeatures] = useState({});
  const [featuresStepName, setFeaturesStepName] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [showAiSuggestedActions, setShowAisuggestedAction] = useState(false);

  useEffect(() => {
    getFeatures();
  }, [location.pathname]);

  const reloadContents = () => {
    getFeatures();
  };

  const getFeatures = async () => {
    setIsLoading(true);
    featureAPI
      .getFeatures(params.case_id)
      .then((res) => {
        // Get the current step features
        let tempData = res.data;
        // Assign first value of the dictionary to the current features if features_isempty is not true else assign prev_features' first dict value
        let tempFeatures = {};
        if (tempData["features_isempty"] == false) {
          tempFeatures = tempData["features"];
        } else {
          tempFeatures = tempData["prev_features"];
        }
        setFeaturesStepName(Object.keys(tempFeatures)[0]);
        tempFeatures = Object.values(tempFeatures)[0];

        setCurrFeatures(tempFeatures);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const setBullet = (dict, value) => {
    Object.keys(dict).forEach((item) => {
      dict[item] = false;
    });
    dict[value] = true;
    return dict;
  };

  const handleInputChange = (e, type) => {
    let { name, value } = e.target;
    setCurrFeatures((prevFeatures) => {
      let tempFeatures = { ...prevFeatures };
      if (type == "Bullet") {
        value = setBullet(tempFeatures[name], value);
      } else if (type == "Date") {
        value = format(value, "yyyy-MM-dd");
      } else if (type == "Integer") {
        if (value) {
          let newVal = toFloatMark(value);
          if (name == featureMapping.amountName) {
            setAmountValue(newVal);
          }
          if (value.slice(-1) == ".") {
            value = newVal + ".";
          } else if (value.slice(-2) == ".0") {
            value = newVal + ".0";
          } else {
            value = newVal;
          }
        }
      } else if (type == "KeyvalueConstOptions") {
        // Convert string values in object to float
        let tempValue = {};
        Object.keys(value).forEach((key) => {
          tempValue[key] =
            typeof value[key] == "string"
              ? toFloatMark(value[key])
              : value[key];
        });
        value = tempValue;
      }

      tempFeatures[name].value = value;
      return tempFeatures;
    });

    // // Perform Preupdate validation functions
    // let tempPreValidationList =
    //   featuresData[stepName][e.target.name]["pre_update_validation_functions"];
    // let preValidationChecks = preUpdateValidations(
    //   newValues[stepName],
    //   tempPreValidationList,
    //   stepType
    // );

    // if(!preValidationChecks){
    //   return;
    // }

    // Add Validation Functions to the validation queue

    // let tempValidationsList =
    //   featuresData[stepName][e.target.name]?.validation_functions;

    // if (
    //   typeof tempValidationsList == typeof [] &&
    //   tempValidationsList.length > 0
    // ) {
    //   tempValidationsList = tempValidationsList.filter(
    //     (validation) =>
    //       !validationList.some((item) => _.isEqual(validation, item))
    //   );
    //   setValidationList((preValidationList) => {
    //     let newValidationList = [...preValidationList, ...tempValidationsList];
    //     return newValidationList;
    //   });
    // }
  };

  const checkValidations = (type, stepName) => {
    let isValid = true;
    validationList.forEach((validation) => {
      let dynamicValidation = Validations[validation.name];
      let result = true;
      if (validation.name != "C2") {
        result = dynamicValidation(
          validation.parameters["feature_name1"],
          type[validation.parameters["feature_name1"]],
          validation.parameters["feature_name2"],
          type[validation.parameters["feature_name2"]]
        );
      } else {
        result = dynamicValidation(
          type["Reason for Invalidation"],
          type["Claim Status"]
        );
      }
      if (result !== true) {
        setGlobalState("error", {
          open: true,
          message: result,
          type: "error",
        });
        isValid = false;
      }
    });
    return isValid;
  };
  const updateStep = (stepName) => {
    // if (checkValidations(updateStepData, stepName)) {
    setIsLoading(true);
    let tempFeatures = { ...currFeatures };

      Object.keys(tempFeatures).forEach((key) => {

          tempFeatures[key] = tempFeatures[key].value;
        
      });

    featureAPI
      .updateFeatures(params.case_id, stepName, tempFeatures)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);

          getFeatures();
          setGlobalState("error", {
            open: true,
            message: "Features Successfully updated.",
            type: "success",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
    // }
  };

  return (
    <MDBox
      px={"10px"}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          marginBottom: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <MDBox sx={{ display: "flex" }}>
          <MDBox color={theme.palette.primary.main} sx={{ marginTop: "2px" }}>
            Current Step
          </MDBox>
          <MDTypography class="material-symbols-outlined">
            arrow_right
          </MDTypography>
          <MDBox color={theme.palette.text.disabled} sx={{ marginTop: "2px" }}>
            {caseDetails["Current Step"]?.name}
          </MDBox>
        </MDBox>
        <MDChip
          sx={{
            transition: "all .5s",
            padding: "3px 10px",
            fontWeight: "bold",
            right: "0",
            color: theme.palette.primary.main,
            border: "1px solid " + theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
              boxShadow: "0 0 5px 1px " + theme.palette.primary.main + "82",
            },
            cursor: "pointer",
          }}
          label={"AI Suggested Actions"}
          onClick={() => setShowAisuggestedAction((pre) => !pre)}
        />
      </MDBox>
      {showAiSuggestedActions ? (
        <MDBox sx={{ height: "100%", width: "100%" }}>
          <AISuggestedActions
            close={() => setShowAisuggestedAction(false)}
            caseId={caseId}
          />
        </MDBox>
      ) : (
        <>
          <Grid
            container
            sx={{
              display: "flex",

              fontSize: "14px",
              justifyContent: "space-around",
              width: "100%",
            }}
            color={theme.palette.text.main}
          >
            {tabs.map((tab, index) => (
              <Grid
                item
                xs={3}
                sx={{
                  padding: "5px 10px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  backgroundColor:
                    activeTab === tab ? theme.palette.primary.main : ``,
                  borderRadius: "10px",
                }}
                key={tab}
                color={
                  activeTab != tab
                    ? theme.palette.text.header
                    : theme.palette.white.main
                }
                onClick={() => {
                  setActiveTab(tab);
                  setShowAisuggestedAction(false);
                }}
              >
                <MDTypography class="material-symbols-outlined">
                  {tabicons[index]}
                </MDTypography>
                <MDBox color={"inherit"} sx={{ marginTop: "2px" }}>
                  {tab}
                </MDBox>
              </Grid>
            ))}
          </Grid>

          <Card
            sx={{
              padding: "10px",
              marginTop: "5px",
              overflow: "auto",
              flex: 1,
              height: "100%",
              position: "relative",
              boxShadow: "0px 0px 8px 1px #0000000D",
            }}
          >
            <PerfectScrollbar sx={{ height: "100%" }}>
              <List sx={{ height: "100%" }}>
                {isLoading ? (
                  <SummarySkeleton />
                ) : (
                  <>
                    {activeTab === tabs[0] && currFeatures && (
                      <ClaimOverview
                        features={currFeatures}
                        handleInputChange={handleInputChange}
                        stepName={featuresStepName}
                        updateStep={updateStep}
                      />
                    )}
                    {activeTab === tabs[1] && (
                      <CaseFiles stepName={caseDetails["Current Step"]?.name} />
                    )}
                    {activeTab === tabs[2] && (
                      <ClaimAmount
                        features={currFeatures}
                        handleInputChange={handleInputChange}
                        stepName={featuresStepName}
                        updateStep={updateStep}
                      />
                    )}
                    {activeTab === tabs[3] && (
                      <Proceed
                        caseDetails={caseDetails}
                        reloadComponent={reloadComponent}
                      />
                    )}
                  </>
                )}
              </List>
            </PerfectScrollbar>
          </Card>
        </>
      )}
    </MDBox>
  );
};

export default LeftPanel;
