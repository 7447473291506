export const features = {
    "Customer": {
        "type": "Text"
    },
    "Customer code": {
        "type": "Text"
    },
    "Retailer": {
        "type": "DropdownSingleSelect",
        "scope": [
            "DIPLOMAT",
            "PHOENIX",
            "EAGLE",
            "INDO",
            "TAKEALOT",
            "PICK N PAY",
            "SHOPRITE",
            "LESOTHO",
            "SPAR",
            "DISCHEM",
            "GAME",
            "MAKRO",
            "CLICKS",
            "WOOLWORTHS",
            "ASCO",
            "AA TAX",
            "HNY",
            "ACTUA",
            "AFRICA C&C",
            "CITY MEDICAL",
            "LIMPOPO C&C",
            "CONTINENTAL CC",
            "PG J&E",
            "HYPERCHECK",
            "ACKERMANS",
            "ALPHA PHARM",
            "BOXER (SW)",
            "BOXER",
            "FREE STATE",
            "KIT KAT",
            "ARROW CC",
            "NEW LOOK",
            "SOMERSET",
            "SUPERSTRIKE",
            "AMIC",
            "CJ PHARMA",
            "SAMYS",
            "SUPER SAVE",
            "UPD JOBURG",
            "UPD CPT",
            "UPD DURBAN",
            "UPD BFN",
            "UPD PE",
            "WATERBERG",
            "TFG",
            "SG CONVENIENCE",
            "DSV",
            "LITEGA",
            "DEHAEN",
            "RBM",
            "SGRP",
            "P&G OFFICE",
            "L-EDCON",
            "FAIRY TALES CC",
            "PEP TRAVEA",
            "PEP",
            "PG DISCHEM",
            "TOPMED",
            "GEKA",
            "NAMPHARM",
            "KAWARI",
            "OSIRIS",
            "KHARWASTAN",
            "CURASANA",
            "MEDSWANA",
            "DELTA",
            "JJ STRYDOM",
            "NORPHARM",
            "UPTOWN",
            "APTEKOR",
            "HELDERBERG",
            "MALAN & BUYS",
            "PHARMED",
            "ARRIE",
            "TRANSPHARM",
            "(ZA) City Med KZN",
            "(ZA) City Med Polokwane",
            "MR PRICE",
            "(ZA) SHOPRITE SUPERMARKETS",
            "(ZA) ACTUAL INTERNATIONAL",
            "PENMARK"
        ]
    },
    "Document Number": {
        "type": "Text"
    },
    "Claim Amount (ZAR)": {
        "type": "Integer",
        "validation_functions": [
            {
                "name": "C1",
                "parameters": {
                    "feature_name1": "Claim Amount (ZAR)",
                    "step_name1": "Enter on claims report",
                    "feature_name2": "Category and claim amount",
                    "step_name2": "Enter on claims report"
                }
            },
            {
                "name": "C1",
                "parameters": {
                    "feature_name1": "Claim Amount (ZAR)",
                    "step_name1": "Enter on claims report",
                    "feature_name2": "Claim Status",
                    "step_name2": "Enter on claims report"
                }
            }
        ]
    },
    "Credit Note": {
        "type": "Text"
    },
    "Claim type": {
        "type": "DropdownSingleSelect",
        "scope": [
            "BASE",
            "PHC"
        ]
    },
    "Age-Bucket": {
        "type": "DropdownSingleSelect",
        "scope": [
            "1-20",
            "21-30",
            "31-90",
            "91-180",
            "180+"
        ]
    },
    "Invoice Number": {
        "type": "Text"
    },
    "P&G Delivery Number": {
        "type": "Text"
    },
    "Reason for Claim": {
        "type": "Text"
    },
    "SKUs": {
        "type": "Text"
    },
    "Quantity": {
        "type": "Text"
    },
    "Reference Key": {
        "type": "Text"
    },
    "Extra input from excel": {
        "type": "Paragraph"
    },
    "Initial Comment History": {
        "type": "Paragraph"
    },
    "Posting Date": {
        "type": "Date"
    },
    "Reason Codes": {
        "type": "DropdownSingleSelect",
        "scope": [
            "X14",
            "X15",
            "X20"
        ]
    },
    "Reason for Invalidation": {
        "type": "Text",
        "validation_functions": [
            {
                "name": "C2",
                "parameters": {}
            }
        ]
    },
    "Region": {
        "type": "DropdownSingleSelect",
        "scope": [
            "Gauteng",
            "Limpopo",
            "North West",
            "Free State",
            "Eastern Cape",
            "Mpumalanga",
            "Northern Cape",
            "Western Cape",
            "KwaZulu Natal",
            "Lesotho",
            "Botswana",
            "Namibia",
            "Zambia",
            "Zimbabwe",
            "Mozambique",
            "Other"
        ]
    },
    "Claim Status": {
        "type": "KeyvalueConstOptions",
        "keyscope": [
            "Valid",
            "Invalid",
            "Awaiting Decision"
        ],
        "validation_functions": [
            {
                "name": "C1",
                "parameters": {
                    "feature_name1": "Claim Amount (ZAR)",
                    "step_name1": "Enter on claims report",
                    "feature_name2": "Claim Status",
                    "step_name2": "Enter on claims report"
                }
            },
            {
                "name": "C2",
                "parameters": {}
            }
        ],
        "pre_update_validation_functions": [
            {
                "name": "C1",
                "parameters": {
                    "feature_name1": "Claim Amount (ZAR)",
                    "step_name1": "Enter on claims report",
                    "feature_name2": "Claim Status",
                    "step_name2": "Enter on claims report"
                }
            }
        ]
    },
    "Category and claim amount": {
        "type": "Keyvalue",
        "keyscope": [
            "Fem Care",
            "Baby Care",
            "Beauty Care",
            "Shave Care",
            "Fabric Care",
            "Home Care",
            "Oral Care",
            "Hair Care",
            "Clearblue Rapid Diagnostic",
            "Fixodent Denture Care",
            "Iliadin Respiratory Care",
            "Medinait Respiratory Care",
            "Neurobion Nerve Care",
            "Promagnor Vitamin Supplement",
            "Slow-Mag Vitamin Supplement",
            "Vicks Baby Balsam Respiratory Care",
            "Vicks First Defense Respiratory Care",
            "Vicks Inhalers Respiratory Care",
            "Vicks Vaporub Respiratory Care",
            "Vicks Vapodrops Respiratory Care",
            "Vicks/Wick Respiratory Care",
            "Voost Vitamin Supplement",
            "Not decided"
        ],
        "validation_functions": [
            {
                "name": "C1",
                "parameters": {
                    "feature_name1": "Claim Amount (ZAR)",
                    "step_name1": "Enter on claims report",
                    "feature_name2": "Category and claim amount",
                    "step_name2": "Enter on claims report"
                }
            }
        ],
        "pre_update_validation_functions": [
            {
                "name": "C1",
                "parameters": {
                    "feature_name1": "Claim Amount (ZAR)",
                    "step_name1": "Enter on claims report",
                    "feature_name2": "Category and claim amount",
                    "step_name2": "Enter on claims report"
                }
            }
        ]
    }
}

export const files = {
    "POD": {
        "type": "ListofFiles"
    },
    "Claim Support": {
        "type": "ListofFiles"
    },
    "Invoice": {
        "type": "ListofFiles"
    },
    "Transporter Images": {
        "type": "ListofFiles"
    },
    "Claim Dispute Documentation": {
        "type": "ListofFiles"
    },
    "Invalid Claim Documentation": {
        "type": "ListofFiles"
    }
}