// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import AnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/analytics";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";
// Data

// Dashboard components
import { useEffect, useState } from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { caseDocumentAPI } from "api";
import DataTable from "examples/Tables/DataTable";

import inputData from "./InputTableData";
import updateData from "./UpdateTableData";
import resolveData from "./resolveTableData";
import { bulkStatAPI } from "api";
import LoadingOverlay from "react-loading-overlay";
import AccessWrapper from "wrappers/accessWrapper";
import excelData from "./ExcelData";
import rejectedData from "./RejectedData";
import resolveConflictData from "./resolveConflictTableData";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import { useTheme } from "@emotion/react";
import { OutlinedButton } from "components/MDButton/button";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { CustomPopper } from "components/controls/CustomPopper";
function Bulk(props) {
  const navigation = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputAllChecked, setInputAllChecked] = useState(false);
  const [resolveAllChecked, setResolveAllChecked] = useState(false);
  const [updateAllChecked, setUpdateAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [isLoadingResolved, setIsLoadingResolved] = useState(false);
  const [isLoadingResolvedConflict, setIsLoadingResolvedConflict] =
    useState(false);

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingRejected, setIsLoadingRejected] = useState(false);
  const [insertPageNo, setInsertPageNo] = useState(null);
  const [resolvePageNo, setResolvePageNo] = useState(null);
  const [updatePageNo, setUpdatePageNo] = useState(null);
  const [insertPageSize, setInsertPageSize] = useState(null);
  const [resolvePageSize, setResolvePageSize] = useState(null);
  const [updatePageSize, setUpdatePageSize] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const [
    insertRows,
    insertAPI,
    setInsertAPI,
    setInputSelects,
    inputSelects,
    changeInputValues,
    insertColumns,
  ] = inputData({ setIsLoadingNew: setIsLoadingNew });

  const [
    resolveRows,
    resolveAPI,
    setResolveAPI,
    setResolveSelects,
    resolveSelects,
    changeResolveValues,
    resolveColumns,
  ] = resolveData({ setIsLoadingResolved: setIsLoadingResolved });

  const [
    resolveconflictRows,
    resolveconflictAPI,
    setResolveConflictAPI,
    setResolveConflictSelects,
    resolveconflictSelects,
    changeResolveConflictValues,
    resolveconflictColumns,
  ] = resolveConflictData({
    setIsLoadingResolvedConflict: setIsLoadingResolvedConflict,
  });

  const [
    updateRows,
    updateAPI,
    setUpdateAPI,
    setUpdateSelects,
    updateSelects,
    changeUpdateValues,
    updateColumns,
  ] = updateData({ setIsLoadingUpdate: setIsLoadingUpdate });

  const [
    excelRows,
    excelAPI,
    setExcelAPI,
    setExcelSelects,
    excelSelects,
    changeExcelValues,
    excelColumns,
  ] = excelData({ setIsLoadingExcel: setIsLoadingExcel });

  const [
    rejectedRows,
    rejectedAPI,
    setRejectedAPI,
    setRejectedSelects,
    rejectedSelects,
    changeRejectedValues,
    rejectedColumns,
  ] = rejectedData({ setIsLoadingRejected: setIsLoadingRejected });

  const column_insert_update = insertColumns
    ? insertColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_resolve = resolveColumns
    ? resolveColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_resolveconflict = resolveconflictColumns
    ? resolveconflictColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_update = updateColumns
    ? updateColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_excel = excelColumns
    ? excelColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_rejected = rejectedColumns
    ? excelColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const handleInputButtonClick = async () => {
    try {
      setIsLoadingNew(true);
      setRefreshing(true);
      const statusRes = await bulkStatAPI.getInsertingStatus();
      const res = await bulkStatAPI.postInsert(inputSelects);
      if (res.status == 200) {
        setGlobalState("error", {
          open: true,
          message: res.data,
          type: "info",
        });
        // setInputAllChecked((prev) => !prev);
        changeInputValues();
      }
      InsertPollApi();
    } catch (error) {
      setIsLoadingNew(false);
    }
  };

  const handleResolveButtonClick = async () => {
    try {
      setIsLoadingResolved(true);
      setRefreshing(true);
      const res = await bulkStatAPI.postResolve(resolveSelects);
      // setIsLoadingResolved(false);
      if (res.status == 200) {
        setGlobalState("error", {
          open: true,
          message: res.data,
          type: "info",
        });
        // setResolveAllChecked((prev) => !prev);
        changeResolveValues();
      }
      resolvePollApi();
    } catch (error) {
      setIsLoadingResolved(false);
    }
  };

  const handleUpdateButtonClick = async () => {
    try {
      setIsLoadingUpdate(true);
      const statusRes = await bulkStatAPI.getUpdatingStatus();
      const res = await bulkStatAPI.postUpdate(updateSelects);
      setIsLoadingUpdate(false);
      if (res.status == 200) {
        setGlobalState("error", {
          open: true,
          message: res.data,
          type: "info",
        });
        setUpdateAllChecked((prev) => !prev);
        changeUpdateValues();
      }
    } catch (error) {
      setIsLoadingUpdate(false);
    }
  };

  const handleInputSelectAll = () => {
    if (!inputAllChecked) {
      const startIndex = (insertPageNo - 1) * insertPageSize;
      const endIndex = startIndex + insertPageSize;
      const slicedData = insertAPI
        .slice(startIndex, endIndex)
        .map((item) => item["EXTERNAL REF"]);
      setInputSelects(slicedData);
      setInsertAPI((previousRows) => {
        return previousRows.map((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return {
              ...row,
              checked: true,
            };
          }
          return row;
        });
      });
    } else {
      setInputSelects([]);
      setInsertAPI((previousRows) => {
        return previousRows.map((row) => ({
          ...row,
          checked: false,
        }));
      });
    }
    setInputAllChecked((prev) => !prev);
  };

  const handleResolveSelectAll = () => {
    if (!resolveAllChecked) {
      const startIndex = (resolvePageNo - 1) * resolvePageSize;
      const endIndex = startIndex + resolvePageSize;
      const slicedData = resolveAPI
        .slice(startIndex, endIndex)
        .map((item) => item["EXTERNAL REF"]);
      setResolveSelects(slicedData);
      setResolveAPI((previousRows) => {
        return previousRows.map((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return {
              ...row,
              checked: true,
            };
          }
          return row;
        });
      });
    } else {
      setResolveSelects([]);
      setResolveAPI((previousRows) => {
        return previousRows.map((row) => ({
          ...row,
          checked: false,
        }));
      });
    }
    setResolveAllChecked((prev) => !prev);
  };

  const handleUpdateSelectAll = () => {
    if (!updateAllChecked) {
      const startIndex = (updatePageNo - 1) * updatePageSize;
      const endIndex = startIndex + updatePageSize;
      const slicedData = updateAPI
        .slice(startIndex, endIndex)
        .map((item) => item["EXTERNAL REF"]);
      setUpdateSelects(slicedData);
      setUpdateAPI((previousRows) => {
        return previousRows.map((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return {
              ...row,
              checked: true,
            };
          }
          return row;
        });
      });
    } else {
      setUpdateSelects([]);
      setUpdateAPI((previousRows) => {
        return previousRows.map((row) => ({
          ...row,
          checked: false,
        }));
      });
    }
    setUpdateAllChecked((prev) => !prev);
  };

  const handleInputClick = (event) => {
    setInputSelects((prevCheckedItems) => {
      if (!event.row.values.select.props.checked) {
        return [...prevCheckedItems, event.row.values["EXTERNAL REF"]];
      } else {
        return prevCheckedItems.filter(
          (item) => item !== event.row.values["EXTERNAL REF"]
        );
      }
    });

    setInsertAPI((previousRows) => {
      return previousRows.map((row, index) => {
        return index == event.row.id
          ? {
              ...row,
              checked: !row.checked,
            }
          : row;
      });
    });

    // setClickedInputStates(event)
  };

  const handleResolveClick = (event) => {
    setResolveSelects((prevCheckedItems) => {
      if (!event.row.values.select.props.checked) {
        return [...prevCheckedItems, event.row.values["EXTERNAL REF"]];
      } else {
        return prevCheckedItems.filter(
          (item) => item !== event.row.values["EXTERNAL REF"]
        );
      }
    });

    setResolveAPI((previousRows) => {
      return previousRows.map((row, index) => {
        return index == event.row.id
          ? {
              ...row,
              checked: !row.checked,
            }
          : row;
      });
    });
  };

  const handleUpdateClick = (event) => {
    setUpdateSelects((prevCheckedItems) => {
      if (!event.row.values.select.props.checked) {
        return [...prevCheckedItems, event.row.value["EXTERNAL REF"]];
      } else {
        return prevCheckedItems.filter(
          (item) => item !== event.row.values["EXTERNAL REF"]
        );
      }
    });

    setUpdateAPI((previousRows) => {
      return previousRows.map((row, index) => {
        return index == event.row.id
          ? {
              ...row,
              checked: !row.checked,
            }
          : row;
      });
    });
  };

  const checkServerStatus = async () => {
    try {
      const res = await bulkStatAPI.getOverallStatus();
      setRefreshing((prevRefreshing) => {
        if (prevRefreshing) {
          InsertPollApi();
          resolvePollApi();
          updatePollApi();
        }
        return false;
      });
    } catch (err) {
      setRefreshing(true);
      InsertPollApi();
      resolvePollApi();
      updatePollApi();
      return;
    }
  };

  const toggleAllLoading = () => {
    setIsLoadingExcel((prevLoading) => !prevLoading);
    setIsLoadingNew((prevLoading) => !prevLoading);
    setIsLoadingRejected((prevLoading) => !prevLoading);
    setIsLoadingResolved((prevLoading) => !prevLoading);
    setIsLoadingUpdate((prevLoading) => !prevLoading);
  };

  const handleExcelFileChange = async (event, type) => {
    toggleAllLoading();
    const formdata = new FormData();
    formdata.append(event.target.name, event.target.files[0]);

    caseDocumentAPI
      .uploadBulkCases(formdata, type)
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: "Successfully uploaded the File",
            type: "success",
          });
          // changeInputValues();
          // changeResolveValues();
          // changeUpdateValues();
          // changeExcelValues();
          checkServerStatus();
          toggleAllLoading();
          event.target.value = null;
        }
      })
      .catch((error) => {
        event.target.value = null;
        toggleAllLoading();
      });
  };

  const InsertPollApi = async () => {
    try {
      const statusRes = await bulkStatAPI.getInsertingStatus();
      if (statusRes.status === 200) {
        changeInputValues();
      } else {
      }
    } catch (error) {
      changeInputValues();
    }
  };
  const updatePollApi = async () => {
    try {
      const statusRes = await bulkStatAPI.getUpdatingStatus();
      if (statusRes.status === 200) {
        changeUpdateValues();
      } else {
        changeUpdateValues();
      }
    } catch (error) {
      changeUpdateValues();
    }
  };
  const resolvePollApi = async () => {
    try {
      const statusRes = await bulkStatAPI.getResolveStatus();
      if (statusRes.status === 200) {
        changeResolveValues();
      } else {
        changeResolveValues();
      }
    } catch (error) {
      changeResolveValues();
    }
  };

  useEffect(() => {
    const serverInterval = setInterval(checkServerStatus, 15000);
    return () => {
      clearInterval(serverInterval);
    };
  }, []);

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const tabs = [
    {
      value: "All Claims",
      count: excelRows?.length,
      props: {
        table: {
          columns: column_excel,
          rows: excelRows,
        },
        isSorted: true,
        canSearch: true,
        showTotalEntries: true,
        noEndBorder: true,
        downloadFileName: "Excel Data",
        isLoading: isLoadingExcel,
      },
    },
    {
      value: "Add New Claims",
      count: insertRows?.length,
      props: {
        table: {
          columns: column_insert_update,
          rows: insertRows,
        },
        isSorted: true,
        canSearch: true,
        downloadFileName: "Insert New Cases Data",
        showTotalEntries: true,
        noEndBorder: true,
        submit: {
          value: refreshing ? "Refreshing..." : "Insert",
          handleClick: refreshing ? () => {} : handleInputButtonClick,
          iconName: "add",
          loading: refreshing,
        },
        isLoading: isLoadingNew,
      },
    },
    {
      value: "Resolved on I&C",
      count: resolveRows?.length,
      props: {
        table: {
          columns: column_resolve,
          rows: resolveRows,
        },
        isSorted: true,
        canSearch: true,
        showTotalEntries: false,
        noEndBorder: true,
        submit: {
          value: refreshing ? "Refreshing..." : "Resolve",
          handleClick: refreshing ? () => {} : handleResolveButtonClick,
          iconName: "task_alt",
          loading: refreshing,
        },
      },
    },
    {
      value: "Resolved on System",
      count: resolveconflictRows?.length,
      props: {
        table: {
          columns: column_resolveconflict,
          rows: resolveconflictRows,
        },
        isSorted: true,
        canSearch: true,
        downloadFileName: "Resolved Cases Data",
        showTotalEntries: true,
        noEndBorder: true,
        isLoading: isLoadingResolvedConflict,
      },
    },
    {
      value: "Unchanged Claims",
      count: updateRows?.length,
      props: {
        table: {
          columns: column_update,
          rows: updateRows,
        },
        isSorted: true,
        canSearch: true,
        showTotalEntries: false,
        noEndBorder: true,
        isLoading: isLoadingUpdate,
      },
    },
    {
      value: "Invalid Claims",
      count: rejectedRows?.length,
      props: {
        table: {
          columns: column_rejected,
          rows: rejectedRows,
        },
        isSorted: true,
        canSearch: true,
        downloadFileName: "Rejected Cases Data",
        showTotalEntries: true,
        noEndBorder: true,
        isLoading: isLoadingRejected,
      },
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]?.value);
  const [bulkUploadFiles, setBulkUploadFiles] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false)
  const [activeFile, setActiveFile] = useState(null);
  const downloadFile = async (filename) => {
    if (filename) {
      setIsDownloading(true)
      bulkStatAPI
        .downloadFile(filename)
        .then((res) => {
          if (res.data) {
            const blob = new Blob([res.data]);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            console.log(link.href);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsDownloading(false)
          }
        })
        .catch((e) => {setIsDownloading(false)});
    }
  };
  const getBulkUploadFiles = () => {
    bulkStatAPI
      .getBulkUploadFiles()
      .then(({ data }) => {
        data.forEach((tdata, index) => {
          const dateObject = new Date(tdata?.last_modified);
          const formattedDate = dateObject.toLocaleDateString("en-GB");
          data[index] = {
            fileNameWithDate: formattedDate + " | " + tdata?.file_name,
            filename: tdata?.file_name,
            date: tdata?.last_modified,
          };
        });
        setBulkUploadFiles(data);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getBulkUploadFiles();
  }, []);

  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              upload_file
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            Upload Claims
          </MDBox>
        }
      />

      <MDBox
        my={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <MDBox>
            <MDBox
              sx={{
                display: "flex", gap: '5px'
              }}
            >
              <Autocomplete
                value={activeFile?.fileNameWithDate}
                options={bulkUploadFiles || []}
                onChange={(e, value) => {
                  setActiveFile(value);
                }}
                getOptionLabel={(option) => option?.fileNameWithDate || ""}
                PopperComponent={CustomPopper}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select a file" sx={{}} />
                )}
                sx={{
                  width: "300px",
                  position: "relative",
                  background: white.main,
                  borderRadius: "100px",
                  "& .MuiInputBase-root": { padding: 0, borderRadius: 0 },
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: text.light,
                    },
                    "&:hover fieldset": {
                      borderColor: text.light,
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid " + text.light,
                    },
                  },
                  "& input": {
                    color: text.main,
                    padding: "10px 15px !important",
                  },
                  "& fieldset": {
                    borderRadius: "100px",
                    borderColor: text.light,
                  },
                }}
              />
              <OutlinedButton
                name={isDownloading ? "Downloading..." :"Download"}
                onClick={() => {
                  isDownloading ? {} :
                  downloadFile(activeFile?.filename);
                }}
                icon={
                  isDownloading ?
                  <CircularProgress color="primary" sx={{height: '1.3em !important', width: '1.3em !important'}} />
                  :<Icon className={"font-unset"} fontSize="1em">
                    download
                  </Icon>
                }
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <label htmlFor="file">
            <input
              type="file"
              onChange={(e) => handleExcelFileChange(e, "nonbase")}
              name="file"
              id="file"
              style={{ display: "none" }}
            />
            <OutlinedButton
              name={"Upload New Claims"}
              onClick={() => {}}
              icon={
                <Icon
                  className={"font-unset"}
                  fontSize="1em"
                  style={{ transform: "rotate(180deg)", color: white.main }}
                >
                  download
                </Icon>
              }
              sx={{
                backgroundColor: primary.main,
              }}
              color={white.main}
            />
          </label>
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          display: "flex",
          fontSize: typography.size["sm"],
          fontWeight: typography.fontWeightMedium,
          borderBottom: `1px solid ${palette.grey[300]}`,
          gap: "1em",
        }}
      >
        {tabs.map((tab, index) => (
          <MDBox
            key={index}
            sx={{
              padding: pxToRem(8),
              cursor: "pointer",
              gap: "5px",
              display: "flex",
              paddingBottom: pxToRem(5),
              borderBottom:
                activeTab === tab?.value
                  ? `2px solid ${palette.primary.main}`
                  : ``,
            }}
            color={palette.text.header}
            onClick={() => setActiveTab(tab?.value)}
          >
            {tab?.value}
            <MDBox
              color={primary.main}
              fontWeight={typography.fontWeightRegular}
            >
              ({tab?.count})
            </MDBox>
          </MDBox>
        ))}
      </MDBox>

      <LoadingOverlay active={false} spinner text="Loading your content...">
        <TabContext value={activeTab}>
          {tabs?.map((tab, index) => (
            <TabPanel
              value={tab.value}
              index={index}
              key={index}
              sx={{ padding: 0, paddingTop: "1.5em" }}
            >
              <MDBox>
                <LoadingOverlay
                  active={false}
                  spinner
                  text="Loading your content..."
                >
                  <Card>
                    <DataTable
                      {...tab.props}
                      tableHeight={"calc(var(--h-100vh) - 300px)"}
                    />
                  </Card>
                </LoadingOverlay>
              </MDBox>
            </TabPanel>
          ))}
        </TabContext>
      </LoadingOverlay>
    </DashboardLayout>
  );
}
export default AccessWrapper(Bulk, ["agent1", "superuser", "admin"]);
