import { useTheme } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";

const ActionMessage = ({
  action,
  successMessage,
  errorMessage,
  isloading,
  loadingMessage,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, error, text } = palette;
  const { pxToRem } = functions;
  return isloading ? (
    <MDBox
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <CircularProgress
        color="primary"
        sx={{ height: "18px !important", width: "18px !important" }}
      />
      {loadingMessage}
    </MDBox>
  ) : action ? (
    <MDBox
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <span
        class="material-symbols-outlined"
        style={{
          backgroundColor: "#d32f2f21",
          borderRadius: "100%",
          color: error.main,
          padding: "1px",
        }}
      >
        cancel
      </span>
      {errorMessage}
    </MDBox>
  ) : (
    <MDBox
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <span
        class="material-symbols-outlined"
        style={{
          backgroundColor: "#e6fff9",
          borderRadius: "100%",
          color: secondary.main,
          padding: "1px",
        }}
      >
        check_circle
      </span>
      {successMessage}
    </MDBox>
  );
};

export default ActionMessage;
