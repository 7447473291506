import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CaseStateChip from "examples/CaseStateChip";
import { formatDate } from "layouts/formDetails/rightPanel/components/general/utils";
import { convertKeyToName } from "utils/conversion";

const TableCell = ({ data, type }) => {
  return (
    <>
      {type === "state" ? (
        <CaseStateChip state={data?.toUpperCase()}/>
      ) : type === "object" ? (
        <ObjectDisplay data={data} />
      ) : type === "timestamp" ? (
        formatDate(data)
      ) : type === "chip" ? (
        <CaseStateChip state={data} />
      ) : type === "action" ? (
        <MDTypography component="a" name="action" color="text" sx={{textAlign:'center'}}>
          <Icon name="action" id="action">
            more_vert
          </Icon>
        </MDTypography>
      ) : (
        <>{data}</>
      )}
    </>
  );
};

export default TableCell;

const ObjectDisplay = ({ data }) => {
  return (
    <>
      {Object.keys(data).map((key, index) => (
        <MDBox color={'inherit'} key={index} sx={{ fontSize: "12px" }}>
          {convertKeyToName(key) + ": " + data[key]}
        </MDBox>
      ))}
    </>
  );
};
