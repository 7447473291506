import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import MDBox from 'components/MDBox';

import { useTheme } from '@emotion/react';
import ContentBox from '../general/ContentBox';
import ExpandableCard from '../general/ExpandableCard';
import useHistory from './hooks/useHistory';
import { formatDate, formatTime } from '../general/utils';
import MessageSkeleton from 'skeletons/forms/MessageSkeleton';
import EmptyResult from 'utils/emptyResult';

const HistoryCard = ({ data }) => {
    const { palette, typography } = useTheme();
    const { primary } = palette;

    return (
        <MDBox
            sx={{
                display: 'flex',
            }}
        >
            <MDBox
                sx={{
                    marginRight: '15px',
                    position: 'relative',
                }}
            >
                <MDBox
                    sx={{
                        height: '38px',
                        width: '38px',
                        fontSize: typography.size['lg'],
                        fontWeight: 'bold',
                        background: primary.light,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '100%',
                        position: 'relative',
                        marginTop: '20px',
                        zIndex: '1',
                        border: `1px solid ${palette.primary.main}`
                    }}
                    title={data?.agent_name}
                >
                    {data?.agent_name[0].toUpperCase()}
                </MDBox>
                <MDBox
                    sx={{
                        height: '100%',
                        borderLeft: `1px solid ${palette.text.light}`,
                        zIndex: '0',
                        position: 'absolute',
                        top: '0',
                        left: '50%'
                    }}
                ></MDBox>
            </MDBox>

            <MDBox
                sx={{
                    width: '100%',
                    overflowX: 'hidden',
                    marginRight: '15px',
                }}
            >
                <MDBox
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '10px',
                        fontSize: typography.size['sm'],
                        whiteSpace: 'nowrap',
                        marginTop: '20px'
                    }}
                    color={palette.text.disabled}
                >
                    {formatDate(data?.timestamp)}
                    <MDBox sx={{
                        height: '5px',
                        width: '5px',
                        borderRadius: '100%',
                        background: palette.text.disabled
                    }} />
                    <MDBox
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                        color={palette.text.disabled}
                    >
                        <PermIdentityOutlinedIcon />
                        <MDBox sx={{
                            maxWidth: '110px',
                            gap: '5px',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: typography.size['sm'],
                        }}
                            color={palette.text.disabled}
                        >
                            {data?.agent_email}
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox
                    sx={{
                        fontSize: typography.size['sm'],
                        marginBottom: '5px',
                        marginTop: '2px'
                    }}
                    color={palette.primary.main}
                >
                    {data?.status}
                </MDBox>
                <ExpandableCard
                    style={{
                        fontSize: typography.size['sm'],
                        padding: '7px 11px',
                        background: primary.light,
                        borderRadius: '8px'
                    }}
                    color={palette.text.secondary}
                    content={data?.description}
                />
                <MDBox
                    sx={{
                        fontSize: typography.size['sm'],
                        marginTop: '5px',
                        marginBottom: '15px'
                    }}
                    color={palette.text.disabled}>
                    {formatTime(data?.timestamp)}
                </MDBox>
            </MDBox>
        </MDBox>
    )
}

const HistoryContent = ({ caseId }) => {
    const { history, isLoading } = useHistory(caseId)

    return (
        <ContentBox>
            { !history && <MessageSkeleton/>}
            {
                history?.map((data, index) => (
                    <HistoryCard key={index} data={data} />
                ))
            }
            { history?.length === 0 &&  <EmptyResult text={"No History Found"} />}
        </ContentBox>
    );
}

export default HistoryContent