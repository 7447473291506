import styled from "@emotion/styled";
import { Popper } from "@mui/material";

export const CustomPopper = styled(Popper)(({ theme }) => ({
    '& .MuiAutocomplete-listbox': {
        padding:'0',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#00000000',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D3D3D3',
        borderRadius: '50px',
      },
    },
  }));