import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
} from "@mui/material";

const PermissionsTable = ({
  inputs,
  activeInputEditKey,
  setInputs,
  primary,
}) => {
  const handleCheckboxChange = (key, type, value) => {
    setInputs((prev) => ({
      ...prev,
      [activeInputEditKey]: {
        ...prev[activeInputEditKey],
        permissions: {
          ...prev[activeInputEditKey].permissions,
          [key]: {
            ...prev[activeInputEditKey].permissions[key],
            [type]: value,
          },
        },
      },
    }));
  };

  return (
    <Table
      sx={{
        width: "100%",
        borderCollapse: "collapse",
        "& td, & th": {
          padding: "0px",
          textAlign: "center",
          fontSize: "14px",
        },
      }}
    >
      <TableRow>
        <TableCell />
        <TableCell>Edit</TableCell>
        <TableCell>View</TableCell>
      </TableRow>

      {inputs[activeInputEditKey]?.permissions &&
        Object.keys(inputs[activeInputEditKey]?.permissions).map((key) => (
          <TableRow key={key}>
            <TableCell sx={{textAlign: "left !important"}}>{key}</TableCell>
            <TableCell>
              <Checkbox
                color="primary"
                checked={inputs[activeInputEditKey]?.permissions[key].edit}
                onChange={(e) =>
                  handleCheckboxChange(key, "edit", e.target.checked)
                }
              />
            </TableCell>
            <TableCell>
              <Checkbox
                color="primary"
                checked={inputs[activeInputEditKey]?.permissions[key].view}
                onChange={(e) =>
                  handleCheckboxChange(key, "view", e.target.checked)
                }
              />
            </TableCell>
          </TableRow>
        ))}
    </Table>
  );
};

export default PermissionsTable;
