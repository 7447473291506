import MDBox from 'components/MDBox';

import MDChip from 'components/MDChip';
import { useTheme } from '@emotion/react';
import { sparkleSvg } from 'examples/svgIcons/svgIcons';

const HistoryAction = ({onClick}) => {
    const { palette, typography } = useTheme();
    const { white, secondary, primary } = palette;

    return (
      <MDBox
        sx={{
          position: "absolute",
          right: "40px",
          zIndex: 1,
        }}
      >
        <MDChip
          sx={{
            paddingTop: '2px',
            fontWeight: "bold",
            fontSize: typography.size['xs'],
            height: '2em',
            color: primary.main,
            border: "1px solid " + primary.main,
            backgroundColor: primary.light,
            "&:hover": {
              backgroundColor: primary.light,
              boxShadow: "0 0 5px 1px " + primary.main + "82",
            },
            cursor: "pointer",
          }}
          label={"AI Summarize"}
          onClick={onClick}
        />
        <MDBox
          sx={{            
            position: "absolute",
            top: "-10px",
            right: "-11px",
            fontSize: "16px"}}
        >
          {sparkleSvg(primary.main)}
        </MDBox>
      </MDBox>
    );
}

export default HistoryAction;