import { caseAPI } from "api";
import { useEffect, useState } from 'react';

const useHistory = (caseId) => {
    const [history, setHistory] = useState();

    useEffect(() => {
        caseAPI
            .getHistory({
                case_id: caseId,
                show_variable_changes: false,
            })
            .then((res) => {
                if (res.status == 200) {
                    setHistory(res.data);
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }, [caseId])

    return {
        history: history
    }
}

export default useHistory