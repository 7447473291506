// react-router-dom components
import { useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

//  React components
import { setGlobalState } from "Global";
import { SignUpAPI } from "api";
import bgImage from "assets/images/background.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";


// Authentication layout components

// Images
import { basicAPI, client } from 'api';
import BasicLayout from "../components/BasicLayout";

function OTPAuth() {
  const [otp, setOtp] = useState(null);
  const [complete, setComplete] = useState(false);
  const location = useLocation();
  const from = location.state?.from || "/";
  const navigation = useNavigate();

  const handleOTPSubmission = async () => {
    try {
      const res = await basicAPI.emailVerify({ otp: parseInt(otp) });
      if (res.status == 200) {
        setComplete(true);
        client.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
        setGlobalState("userDetails", res.data);
        navigation(from == "/authentication/sign-in" ? "/" : from, {
          replace: true,
        });
        setGlobalState("error", {
          open: true,
          message: `OTP Verification Successful`,
          type: "success",
        });
      }
    } catch (err) {
      if(err.response?.data)
      setGlobalState("error", {
        open: true,
        message: `${err.response.data}`,
        type: "error",
      });
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = SignUpAPI.signUpEmailResend(location.state?.email);
      if (res.status == 200) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let textVal = "Verify OTP sent to the email";

  const inputClass = {
    width: "1.7rem",
    height: "45px",
    borderRadius: "5px",
    border: "1px solid #000",
    marginLeft: "10px",
    marginRight: "8px",
    background: "#FAF3F0",
    fontSize: "20px",
    color: "#000",
  };

  // console.log(password);
  // const setGeneratedPassword = () => {
  //   formik2.setFieldValue("password", password)
  //   formik2.setFieldValue("passwordConfirmation", password);
  // };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: "2rem" }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            2FA Authentication
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {textVal}
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1} textAlign="center">
          <MDTypography variant="h6" fontWeight="medium" mt={1}>
            Enter OTP
          </MDTypography>
          <MDBox
            mt={3}
            mb={4}
            textAlign="center"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={inputClass}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </MDBox>
          <MDTypography variant="button" color="text">
            Didn't Receive?
            <MDTypography
              ml={1}
              variant="button"
              color="info"
              onClick={handleResendOtp}
              textGradient
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
            >
              Resend
            </MDTypography>
          </MDTypography>

          <MDBox mt={4}>
            <MDButton
              onClick={handleOTPSubmission}
              variant="gradient"
              color="info"
            >
              Submit OTP
            </MDButton>
          </MDBox>
        </MDBox>

        {complete && (
          <MDBox mt={4} textAlign="center">
            <MDTypography
              variant="h6"
              fontWeight="medium"
              mt={1}
              color={"success"}
            >
              Password Reset Successful, Sign in to continue
            </MDTypography>
            <MDBox mt={2} mb={2}>
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        {!complete && (
          <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Go back to Sign In?
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        )}
      </Card>
    </BasicLayout>
  );
}

export default OTPAuth;
