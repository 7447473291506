import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Sector,
  Tooltip,
  Legend,
} from "recharts";
import PropTypes from "prop-types";
import { COLORS_2 } from "../js/const";

const RADIAN = Math.PI / 180;

const renderPecentageInside = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = (innerRadius - 5) + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      style={{ fontSize: '12px' }}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderActiveShape = (props,name, showInnerLabel) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 11;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      {showInnerLabel &&<text
        style={{ fontSize: '12px' }} x={cx} y={cy-4} dy={8} textAnchor="middle" fill={fill}>
        {payload[name]}
      </text>}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 5}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        style={{ fontSize: '12px' }}
        x={ex + (cos >= 0 ? 1 : -1) * 6}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${showInnerLabel?value:payload[name]}`}</text>
      <text
        style={{ fontSize: '10px' }}
        x={ex + (cos >= 0 ? 1 : -1) * 6}
        y={ey}
        dy={13}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${showInnerLabel?'':value} (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const CustomPieChart = ({ data, legend, tooltip, graph, name , dataKey }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  
  const keys = Object.keys(data[0]);
  const tname = name || Object.keys(data?.[0])?.[0]
  if(keys.indexOf(tname) !== -1) keys.splice(keys.indexOf(tname), 1);

  const labelType = tooltip?.show
    ? {}
    : { activeShape: (props) => renderActiveShape(props, tname, graph?.innerRadius) } ;

    const formatLegend = (value, entry) => {
      return `${entry.payload[tname]}`;
    };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={ graph?.width ||400} height={graph?.height ||400}>
        {tooltip?.show && (
          <Tooltip
            itemStyle={{
              fontSize: tooltip?.fontSize || 12,
            }}
            contentStyle={tooltip?.contentStyle}
          />
        )}
        {legend?.show && (
          <Legend
            layout={legend?.layout || "horizontal"}
            align={legend?.align || "center"}
            verticalAlign={legend?.verticalAlign || "bottom"}
            wrapperStyle={{
              fontSize: tooltip?.fontSize || 12,
              textTransform: "capitalize",
              borderRadius: "5px",
            }}
            formatter={formatLegend}
          />
        )}
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          dataKey={dataKey || keys[0]}
          onMouseEnter={onPieEnter}
          activeIndex={activeIndex}
          paddingAngle={1}
          label={graph?.showPercentage !== false ? renderPecentageInside : false}
          {...labelType}
          {...graph}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS_2[index % COLORS_2.length] || "#666CFF"} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

CustomPieChart.propTypes = {
  graph: PropTypes.shape({
    innerRadius: PropTypes.number,
    paddingAngle: PropTypes.number,
    outerRadius: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    fontSize: PropTypes.number,
    showPercentage: PropTypes.bool
  }),
  legend: PropTypes.shape({
    align: PropTypes.string,
    show: PropTypes.bool,
  }),
  tooltip: PropTypes.shape({
    fontSize: PropTypes.number,
    contentStyle: PropTypes.object,
    show: PropTypes.bool,
  }),
};

export default CustomPieChart;
