import { useTheme } from "@emotion/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CustomPopper } from "./CustomPopper";

export default function Dropdown({
  value,
  onChange,
  options,
  getOptionLabel,
  placeholder,
  label,
  size,
  ...props
}) {
  const { palette } = useTheme();
  const { text } = palette;

  return (
    <Autocomplete
      multiple
      value={value}
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      PopperComponent={CustomPopper}
      size={size}
      sx={{
        "& .MuiAutocomplete-tag": {
          backgroundColor: "#F1F2FF",
          color: text.disabled,
        },
        "& svg": {
          fill: text.disabled,
        },
        "& label": {
          lineHeight: "1.2em",
        },
        "& .MuiInputBase-root":{
          padding:'5px'
        }
      }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      {...props}
    />
  );
}
