 // @mui material components
import { useTheme } from "@emotion/react";
import { Button, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";

//  React example components
import Controls from "components/controls/Controls";

function ClaimOverview({ features, isLoading, handleInputChange,updateStep, stepName }) {

  const { palette } = useTheme();
  const { white, secondary, primary } = palette;

  const ignoreFeatures = [
    "Claim Status",
    "Category and claim amount",
    "Posting Date",
    "Reason for Invalidation"
  ];

  const displayFeatures = ([featureName, feature]) => {
    let ControlType = Controls[feature.type];
    let isViewable = feature.view;
    let isEditable = feature.edit;
    let preValidationList = feature["pre_update_validation_functions"];
    if (isViewable && ControlType && !ignoreFeatures.includes(featureName)) {
      return (
        <ControlType
          name={featureName}
          label={featureName}
          value={feature.value}
          onChange={(e, value) => handleInputChange((value !== undefined)? { target: { name: featureName, value: (feature?.dataKey) ? value?.[feature?.dataKey] : value } }: e)}
          disabled={!isEditable}
          options={feature.scope}
          keyscopes={feature.keyscope}
          stepDetails={features}
          amount={""}
          preValidationList={preValidationList}
          // style={{
          //   borderRadius: "5px",
          //   lineHeight: 1.5,
          //   boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
          // }}
        />
      );
    }
  };

  return (
    <MDBox sx={{ padding: "1rem", width: "100%", height: "100%" }}>
      <Grid container sx={{paddingBottom:"1rem" }}>
        <Grid
          item
          xs={4}
          sx={{
            width: "80%",
          }}
        >
          {Object.entries(features).slice(0, 9).map(displayFeatures)}
        </Grid>
        {/* Vertical Divider */}
        <Grid item>
          <Divider orientation="vertical" sx={{ height: "100%" }} />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            width: "80%",
          }}
        >
          {Object.entries(features).slice(9).map(displayFeatures)}
          <MDChip
          sx={{
            backgroundColor: primary.main,
            padding: "5px 10px",
            color: white.main,
            fontWeight: "bold",
            cursor: "pointer",
            marginTop: "10px",
            position: 'sticky',
            float: 'right',
            right: 0,
            bottom: "16px"
          }}
          label={"Update"} 
          onClick={(e) => updateStep(stepName)}
        />

        </Grid>

      </Grid>
    </MDBox>
  );
}

export default ClaimOverview;
