import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LinkedCaseSkeleton = () => {
  const { palette, typography } = useTheme();
  const { primary, white, text } = palette;
  const { size } = typography;
  return (
    <SkeletonTheme highlightColor="white">
      {Array(2)
        .fill(0)
        .map((item, index) => {
          return (
            <MDBox
                key={index}
              sx={{
                borderRadius: "10px",
                boxShadow: "0 0 5px 1px #00000014",
                margin: "20px 10px 20px 5px",
                padding: "15px",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  justifyContent: "space-between",
                }}
              >
                <MDChip
                  sx={{
                    background: primary.light,
                    padding: `2px 8px`,
                    height: "28px",
                    width: '100px'
                  }}
                />

                <Skeleton width={'100px'}/>
              </MDBox>

              <MDBox
                sx={{
                  margin: "10px 0",
                }}
              >
                <Skeleton />
              </MDBox>

              <MDBox
                sx={{
                  textAlign: "right",
                  marginTop: "5px",
                }}
              >
                  <MDChip
                    sx={{
                      background: white.main,
                      height: "2em",
                      border: "1px solid " + primary.main,
                      cursor: "pointer",
                      width: '100px'
                    }}
                  />
              </MDBox>
            </MDBox>
          );
        })}
    </SkeletonTheme>
  );
};
export default LinkedCaseSkeleton;
