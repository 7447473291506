import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { Badge, Popover, Tooltip } from "@mui/material";
import { userAPI } from "api";
import MDTypography from "components/MDTypography";
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "context";
import { useGlobalState } from "Global";
import NotificationList from "layouts/profile2/notificationList";
import InitialsAvatar from "utils/initialsAvatar";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState("sticky");
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [userDetails] = useGlobalState("userDetails");

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getNotifications = () => {
    setIsLoading(true);
    userAPI
      .getNotifications()
      .then((res) => {
        if (res.status == 200) {
          setNotifications(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  // Render the notifications menu
  const renderMenu = () => (
    <Popover
      anchorEl={openMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
    >
      <NotificationList notifications={notifications} isLoading={isLoading} />
    </Popover>
  );

  return (
    <AppBar
      position={"sticky"}
      color="inherit"
      sx={(theme) => navbar(theme, { light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={{ fontSize: "1rem !important", marginLeft: "0.5rem" }}
          className={"font-unset"}
        >
          {route[route.length - 1].toUpperCase()}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox sx={{ display: "flex", flexDirection: "row" }}>
              <MDTypography
                sx={{
                  alignSelf: "center",
                  fontSize: "medium !important",
                  fontWeight: "500",
                  marginRight: "0.5rem",
                }}
                className={"font-unset"}
              >
                {userDetails?.full_name}
              </MDTypography>
              <Tooltip title={"Profile"}>
                <Link
                  to="/profile"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MDBox>
                    <InitialsAvatar
                      name={userDetails?.full_name}
                      sx={{ fontSize: "0.7rem !important", padding: "0.5rem" }}
                      fontSize="small !important"
                      className={"font-unset"}
                    />
                  </MDBox>
                </Link>
              </Tooltip>
              
                <Tooltip title={"Notifications"}>
                  <IconButton
                    disableRipple
                    color="inherit"
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleOpenMenu}
                    className={"font-unset"}
                  >
                    <Badge badgeContent={notifications.length} color="info">
                    <Icon sx={{ fontSize: "1.5rem !important" }}>
                      notifications
                    </Icon>
                    </Badge>
                  </IconButton>
                </Tooltip>
              
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
