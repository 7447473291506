
import { Dialog, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Paper } from "@mui/material";
import Controls from "components/controls/Controls";
import MDChip from "components/MDChip";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { convertKeyToName } from "utils/conversion";
import Dropdown from "components/controls/Dropdown";

function AddNewWorkflowDialog({
  open,
  handleClose,
  handleSubmit,
  buttonName = "Add buttonName",
  heading = "Add heading",
  iconName = "Add google iconName",
  isLoading,
  agents,
  defaultInputs
}) {
  const [state, setState] = useState({});

  const handleSubmitClick = () => {
    let isValid = true
    let temp = {}
    Object.keys(state).forEach((key) => {
        temp[key] = state[key]
        if(state[key].required && !state[key].value){
            state[key].error = true
            isValid = false
        } else {
            state[key].error = false
        }
    })
    if(isValid) {
      let tempData = {}
      Object.keys(state).forEach((key) => {
        tempData[key] = state[key].value
      })

      handleSubmit(tempData)
    } else {setState(temp)}
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    const updatedState = {
      ...state,
      [name]: {
        ...state[name],
        value,
      },
    };
  
    if (name === "departments_list" && state.department) {
      updatedState.department = {
        ...state.department,
        options: value,
      };
    }
  
    setState(updatedState);
  };
  

  const initializeUserDetails = () => {
    let tempState = {};
    Object.keys(defaultInputs).map((feature) => {
      tempState[feature] = defaultInputs[feature];
    });
    setState(tempState);
  };

  useEffect(() => {
    if (defaultInputs) {
      initializeUserDetails();
    }
  }, [agents]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "29rem" }}
      >
        <LoadingOverlay active={isLoading} spinner>
          <MDBox p={3}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: typography.size["xl"],
                fontWeight: typography.fontWeightBold,
              }}
              pb={2}
            >
              <MDBox sx={{ fontSize: "inherit" }}>{heading}</MDBox>
              <span
                class="material-symbols-outlined"
                style={{ color: primary.main }}
              >
                {iconName}
              </span>
            </MDBox>
            <Grid container columnSpacing={2}>
              {Object.keys(state).map((feature, index) => {
                if (state[feature]?.type !== "MultiTagTextInput") {
                  let ControlType = Controls[state[feature]?.type];
                  return (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <FormControl fullWidth>
                        <ControlType
                          name={feature || ""}
                          variant={index < 3 ? "standard" : "outlined"}
                          label={
                            state[feature]?.label || convertKeyToName(feature)
                          }
                          value={state[feature]?.value}
                          onChange={(e, value) =>
                            handleChange(
                              value !== undefined
                                ? {
                                    target: {
                                      name: feature,
                                      value: state[feature]?.dataKey
                                        ? value?.[state[feature]?.dataKey]
                                        : value,
                                    },
                                  }
                                : e
                            )
                          }
                          disabled={!state[feature]?.editable}
                          options={state[feature]?.options || []}
                          error={state[feature]?.error}
                          helperText={
                            state[feature]?.error
                              ? "This field is required."
                              : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} sx={{ margin: "10px 0" }}>
                      <Dropdown
                        value={state[feature]?.value || ""}
                        options={[]}
                        defaultValue={[]}
                        getOptionLabel={""}
                        freeSolo
                        onChange={(e, value) => {
                          const values = value
                            .join(",")
                            .split(",")
                            .map((item) => item.trim())
                            .filter((item) => item.length > 0);

                          handleChange({
                            target: {
                              name: feature,
                              value: values,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              state[feature]?.label || convertKeyToName(feature)
                            }
                            placeholder={
                              state[feature]?.value?.length > 0
                                ? ""
                                : "Type And Press Enter To Add Tag"
                            }
                            error={state[feature]?.error}
                            helperText={
                              state[feature]?.error
                                ? "This field is required."
                                : null
                            }
                          />
                        )}
                      />
                    </Grid>
                  );
                }
              })}
              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: "1px solid " + error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />
                  <MDChip
                    sx={{
                      backgroundColor: primary.main,
                      padding: "5px 10px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "140px",
                    }}
                    label={buttonName}
                    onClick={() => handleSubmitClick()}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default AddNewWorkflowDialog;
