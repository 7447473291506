import { useTheme } from '@emotion/react';
import MDBox from 'components/MDBox';
import { useState } from 'react';
import HistoryAction from './components/history/HistoryAction';
import HistoryContent from './components/history/HistoryContent';
import LinkedCases from './components/linkedCases/LinkedCases';
import Messages from './components/messages/Messages';
import Notes from './components/notes/Notes';
import AISummery from '../ai/aiSummary/AISummary';


const ActionContainer = ({ activeTab, children }) => {
    const theme = useTheme()
    return (
        <MDBox
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px 0'
            }}
        >
            <MDBox sx={{ fontSize: theme.typography.size["2xl"] }}>
                {activeTab}
            </MDBox>
            {children}
        </MDBox>
    )
}

const RightPanel = ({ caseId, caseDetails }) => {
    const tabs = ["History", "Messages", "Notes", "Linked Cases"];

    const { palette, functions, typography } = useTheme();
    const { white } = palette;
    const { pxToRem } = functions;
  

    const [activeTab, setActiveTab] = useState(tabs[0])
    const [showSummary, setShowSummary] = useState(false)

    return (
        <MDBox
            sx={{
                height: '100%',
                maxWidth: '100%',
                width: pxToRem(360),
                padding: pxToRem(15),
                paddingBottom: pxToRem(10),
                background: white.main,
                borderRadius: pxToRem(10),
                overflowY: 'hidden',
                boxShadow:'0px 0px 8px 1px #0000000D',
            }}
        >
            <MDBox
                mb={1}
                sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    justifyContent: 'space-around',
                    width: "100%",
                    fontSize: typography.size['sm'],
                    borderBottom: `1px solid ${palette.grey[300]}`
                }}
                color={palette.text.main}
            >
                {
                    tabs.map((tab, index) => (
                        <MDBox
                            key={index}
                            sx={{
                                padding: pxToRem(8),
                                cursor: 'pointer',
                                paddingBottom: pxToRem(10),
                                borderBottom: (activeTab === tab) ? `2px solid ${palette.primary.main}` : ``
                            }}
                            color={(activeTab === tab) ? palette.primary.main : palette.text.disabled}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </MDBox>
                    ))
                }
            </MDBox>

            {
                (activeTab === tabs[0])
                    ? <>
                        {!showSummary && <HistoryAction onClick={() => setShowSummary(true)} />}
                        {
                        showSummary? 
                          <AISummery caseId={caseId} closeSummary={() => setShowSummary(false)}/>:
                          <HistoryContent caseId={caseId} />
                        }
                    </>
                    : (activeTab === tabs[1])
                        ? <Messages caseDetails={caseDetails} caseId={caseId} />
                        : (activeTab === tabs[2])
                            ? <Notes caseId={caseId} />
                            : (activeTab === tabs[3])
                                ? <LinkedCases caseId={caseId} />
                                : ''
            }
        </MDBox>
    );
}

export default RightPanel;