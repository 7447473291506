import { setGlobalState, useGlobalState } from "Global";
import { useEffect, useState } from "react";
import { workflowService } from "../service/workflowService";
import { messageService } from "layouts/formDetails/rightPanel/components/messages/service/messageService";

const useWorkflow = () => {
    const [workflowDetail, setWorkflowDetail] = useState({});
    const [agents, setAgents] = useState();
    const [workflows, setWorkflows] = useState([]);
    const [workflowView] = useGlobalState("workflowView");
    const [isLoadingWorflowDetails, setIsLoadingWorflowDetails] = useState(false);
    const [state, setState] = useState({});
    const [activeTab, setActiveTab] = useState({
        _id: "64f2fbe7c7f041e2041484a4",
        name: "Collection - Checker",
      });

    useEffect(() => {
        workflowService.getWorkflows().then(data => {setWorkflows(data)});
        messageService.getAgents().then((data) => {
          setAgents(data);
        });
        if (workflowView[activeTab._id]) {
          setWorkflowDetail(workflowView[activeTab._id]);
        } else {
          workflowService.getWorkflowDetails(activeTab._id).then((data) => {
            setWorkflowDetail(data);
            setGlobalState("workflowView", (prevView) => {
              let newView = { ...prevView };
              return { ...newView, [activeTab._id]: data };
            });
            setIsLoadingWorflowDetails(false);
          });
        }
    },[])

    return {
        workflowDetail,
        setWorkflowDetail,
        workflows,
        setWorkflows,
        workflowView,
        isLoadingWorflowDetails,
        setIsLoadingWorflowDetails,
        state,
        setState,
        activeTab,
        setActiveTab,
        agents,
    }
} 

export default useWorkflow