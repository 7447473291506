import { useTheme } from "@emotion/react";
import { Dialog } from "@mui/material";
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";

const Confirmation = ({ onClose, onAccept, open, acceptLable, title, isLoading }) => {

  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingOverlay
        active={isLoading}
        spinner
      >
      <MDBox
        sx={{
          minWidth: "30rem",
          minHeight: "10rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        p={3}
      >
        <MDBox sx={{margin:'0 auto', maxWidth: '85%', fontSize:typography.size['lg'], fontWeight:'bold'}}>
          {title}
        </MDBox>
        <MDBox
          sx={{
            display:'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap:'1em'
          }}
        >
          <MDBox sx={{cursor:'pointer'}} color={primary.main} onClick={onClose}>
            Cancel
          </MDBox>
          <MDBox sx={{cursor:'pointer'}}  color={error.main} onClick={onAccept}>
            {acceptLable}
          </MDBox>
        </MDBox>
      </MDBox>
      </LoadingOverlay>
    </Dialog>
  );
};

export default Confirmation;
