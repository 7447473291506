import TableCell from "./TableCell"

export const applyStyleToEachTableCell = (columnData, data) => {
    const tempData = []
    for(let row of data){
        const tempObject = {}
        for (let column of columnData){
            tempObject[column.accessor] = <TableCell data={row[column.accessor]} type={column.type}/>
        }
        tempData.push(tempObject)
    }
    return tempData
}