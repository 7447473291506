import { Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Paper } from "@mui/material";
import Controls from "components/controls/Controls";
import MDChip from "components/MDChip";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { convertKeyToName } from "utils/conversion";
import CustomInput from "components/controls/CustomInput";
import CustomAutoComplete from "components/controls/AutoComplete";
import { OutlinedButton } from "components/MDButton/button";

function UpdateNodeDialog({
  open,
  handleClose,
  defaultInputs,
  handleSubmit,
  buttonName = "Add buttonName",
  heading = "Add heading",
  iconName = "Add google iconName",
  isLoading,
  defaultEdges,
  workflowSteps,
}) {
  const [state, setState] = useState({});
  const [edges, setEdges] = useState({});

  const handleSubmitClick = () => {
    let isValid = true;
    let temp = {};
    Object.keys(state).forEach((key) => {
      temp[key] = state[key];
      if (state[key].required && !state[key].value) {
        state[key].error = true;
        isValid = false;
      } else {
        state[key].error = false;
      }
    });
    if (isValid) {
      let tempData = {}
      let tempEdges = {}
      Object.keys(state).forEach((key) => {
        tempData[key] = state[key].value
      })

      Object.keys(edges).forEach((key, index) => {
        if(edges[key].value)
          tempEdges[edges[key].label || "next_step"+index] = edges[key].value
      })
      
      handleSubmit({...tempData, edges: tempEdges})
    } else {
      setState(temp);
    }
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
      },
    });
  };
  const handleEdgeChange = (event, key) => {
    setEdges({
      ...edges,
      [event.target.name]: {
        ...edges[event.target.name],
        [key || "value"]: event.target.value,
      },
    });
  };

  const initializeUserDetails = () => {
    let tempState = {};
    Object.keys(defaultInputs).map((feature) => {
      tempState[feature] = defaultInputs[feature];
    });
    setState(tempState);
  };

  useEffect(() => {
    if (defaultInputs && defaultEdges) {
      initializeUserDetails();
      setEdges(() => {
        let tempEdges = {};
        Object.keys(defaultEdges).forEach((key, index) => {
          tempEdges[index] = {
            label: key.includes("next_step") ? "" : key,
            value: defaultEdges[key],
          };
        });
        return tempEdges;
      });
    }
  }, [defaultInputs, defaultEdges]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, grey, text, error } = palette;
  const { pxToRem } = functions;

  const [focusedFields, setFocusedFields] = useState({});

  const handleFocus = (key) => {
    setFocusedFields((prev) => ({ ...prev, [key]: true }));
  };

  const handleBlur = (key) => {
    setFocusedFields((prev) => ({ ...prev, [key]: false }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "29rem" }}
      >
        <LoadingOverlay active={isLoading} spinner>
          <MDBox p={3}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: typography.size["xl"],
                fontWeight: typography.fontWeightBold,
              }}
              pb={2}
            >
              <MDBox sx={{ fontSize: "inherit" }}>{heading}</MDBox>
              <span
                class="material-symbols-outlined"
                style={{ color: primary.main }}
              >
                {iconName}
              </span>
            </MDBox>
            <Grid container columnSpacing={2}>
              {Object.keys(state).map((feature, index) => {
                let ControlType = Controls[state[feature]?.type];
                return (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <FormControl fullWidth>
                      <ControlType
                        name={feature || ""}
                        // variant={(index < 3)? 'standard' : 'outlined'}
                        label={
                          state[feature]?.label || convertKeyToName(feature)
                        }
                        value={state[feature]?.value}
                        onChange={(e, value) =>
                          handleChange(
                            value !== undefined
                              ? {
                                  target: {
                                    name: feature,
                                    value: state[feature]?.dataKey
                                      ? value?.[state[feature]?.dataKey]
                                      : value,
                                  },
                                }
                              : e
                          )
                        }
                        disabled={!state[feature]?.editable}
                        options={state[feature]?.options || []}
                        error={state[feature]?.error}
                        helperText={
                          state[feature]?.error
                            ? "This field is required."
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                );
              })}
              <MDBox sx={{ width: "100%" }} pl={2}>
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: typography.size["sm"],
                    fontWeight: typography.fontWeightBold,
                  }}
                  py={1}
                >
                  <MDBox sx={{ fontSize: "inherit" }}>Edges</MDBox>
                  <OutlinedButton
                    name={"Add Edges"}
                    onClick={() =>
                      setEdges((pre) => ({
                        ...pre,
                        [Object.keys(pre).length]: { label: "", value: "" },
                      }))
                    }
                    sx={{
                      height: "30px",
                      borderRadius: "100px",
                    }}
                    icon={
                      <span
                        class="material-symbols-outlined"
                        style={{
                          color: primary.main,
                          cursor: "pointer",
                        }}
                      >
                        add
                      </span>
                    }
                  />
                </MDBox>
                {Object.keys(edges).map((key, index) => (
                  <MDBox
                    sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                  >
                    <MDBox sx={{ width: "40%" }}>
                      <CustomInput
                        label={`Edge(${key}) label`}
                        value={edges[key].label}
                        name={key}
                        onChange={(e) => handleEdgeChange(e, "label")}
                        InputLabelProps={{
                          shrink: !!edges[key].label || focusedFields[key],
                        }}
                        onFocus={() => handleFocus(key)}
                        onBlur={() => handleBlur(key)}
                        size={"small"}
                      />
                    </MDBox>
                    <CustomAutoComplete
                      label={`Edge(${key}) to Node`}
                      value={edges[key].value}
                      name={key}
                      onChange={(e, value) =>
                        handleEdgeChange(
                          { target: { name: key, value: value } },
                          "value"
                        )
                      }
                      options={workflowSteps || []}
                      size={"small"}
                    />
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: error.main,
                        borderRadius: "100%",
                        cursor: "pointer",
                        fontSize: "1.3em",
                      }}
                      onClick={() => {
                        let temp = edges;
                        delete temp[key];
                        setEdges({ ...temp });
                      }}
                    >
                      delete
                    </span>
                  </MDBox>
                ))}
              </MDBox>
              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: '1px solid '+ error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />

                  <MDChip
                    sx={{
                      backgroundColor: primary.main,
                      padding: "5px 10px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "140px",
                    }}
                    label={buttonName}
                    onClick={() => handleSubmitClick()}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default UpdateNodeDialog;
