import { useTheme } from "@emotion/react";
import { useState } from "react";
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import PropTypes from "prop-types";
import { COLORS_2 } from "../js/const";

function convertRange(value, oldMin, oldMax) {
    // Define the new range
    const newMin = 10;
    const newMax = 100;
    // Calculate the new value
    const newValue = ((value * 1 - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;
    return newValue || 10;
}

const CustomDot = (props) => {
  const [hoveredDot, setHoveredDot] = useState(false);

  const handleMouseEnter = (dot) => {
    setHoveredDot(true);
  };

  const handleMouseLeave = () => {
    setHoveredDot(false);
  };
  const {
    cx,
    cy,
    size,
    color,
    strokeWidth,
    node,
    zAxis,
  } = props;
  return (
      <circle
        cx={cx}
        cy={cy}
        r={convertRange(node?.z, 0, zAxis.domain[1]*2)}
        fill={color}
        fillOpacity={hoveredDot ? 0.5 : 0.25}
        strokeOpacity={hoveredDot ? 1 : 0.4}
        stroke={color}
        strokeWidth={strokeWidth}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
  );
};

const CustomScatterChart = ({
  data,
  axisStyle,
  grid,
  graph,
  tooltip,
  xAxis,
  yAxis,
  zAxis,
}) => {
  const { palette } = useTheme();

  const keys = Object.keys(data[0]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <ScatterChart height={"100%"}>
        <CartesianGrid strokeDasharray="3 3" {...grid} />
        <XAxis
          dataKey={keys?.[0]}
          type="number"
          stroke={axisStyle?.stroke || palette.text.light}
          tick={{
            fill: axisStyle?.fontColor || palette.text.disabled,
            fontSize: axisStyle?.fontSize || 12,
          }}
          {...xAxis}
        />
        <YAxis
          dataKey={keys?.[1]}
          type="number"
          stroke={axisStyle?.stroke || palette.text.light}
          tick={{
            fill: axisStyle?.fontColor || palette.text.disabled,
            fontSize: axisStyle?.fontSize || 12,
          }}
          {...yAxis}
        />
        <ZAxis 
            dataKey={keys?.[3]} 
            type="number" 
            {...zAxis}
        />
        {tooltip?.show !== false && (
          <Tooltip
            itemStyle={{
              fontSize: axisStyle?.fontSize || 12,
            }}
            contentStyle={tooltip?.contentStyle}
          />
        )}
        <Scatter
          name="Scatter Plot"
          data={data}
          shape={(props) => (
            <CustomDot
              {...props}
              color={graph?.color || COLORS_2[0]}
              strokeWidth={graph?.strokeWidth || 2}
            />
          )}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

CustomScatterChart.propTypes = {
  axisStyle: PropTypes.shape({
    stroke: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.number,
  }),
  grid: PropTypes.shape({
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool,
  }),
  graph: PropTypes.shape({
    hoverFill: PropTypes.string,
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
    barSize: PropTypes.number,
  }),
  tooltip: PropTypes.shape({
    contentStyle: PropTypes.object,
    show: PropTypes.bool,
  }),
  xAxis: PropTypes.shape({
    name: PropTypes.string,
    dataKey: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.oneOf["number", "category"],
    hide: PropTypes.bool,
  }),
  yAxis: PropTypes.shape({
    name: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.oneOf["number", "category"],
    hide: PropTypes.bool,
    dataKey: PropTypes.string,
  }),
  zAxis: PropTypes.shape({
    name: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.oneOf["number", "category"],
    hide: PropTypes.bool,
    dataKey: PropTypes.string,
  }),
};

export default CustomScatterChart;
