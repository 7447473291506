// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import { Dialog } from "@mui/material";

import { Box, Paper, Typography } from "@mui/material";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import TableDisplay from "./tableDisplay";
import "./tableBanner.css"

function TableBanner(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose(!props.open);
      }}
      sx={{ minWidth: "var(--w-100vw) !important" }}
      className="dialog-table"
    >
      <TableDisplay data={props.data} {...props}/>
    </Dialog>
  );
}

export default TableBanner;
