import { useEffect, useState } from 'react'
import * as reactRouterDom from 'react-router-dom';
import { basicAPI } from 'api';
import { setGlobalState } from 'Global';

export default function Logout () {
  const navigation = reactRouterDom.useNavigate()
  useEffect(() => {
    basicAPI.logout().then((res) => {
      if (res.status == 200) {
        setGlobalState("userDetails", null);
        navigation("/authentication/sign-in");
        setGlobalState("error", {
          open: true,
          message: "You have been successfully logged out!",
          type: "success",
          code: 200,
        });
      }
    }).catch((error)=>{
      setGlobalState("error", {
        open: true,
        message: "Error while logging out",
        type: "error",
        code: 500
      });
    });
  }, [])
  return <></>;
}
