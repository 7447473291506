import * as React from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import { Box, InputAdornment, Typography } from "@mui/material";
import { toDecimalMark } from "utils/conversion";
import CustomInput from "./CustomInput";

export default function Integer(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled = false,
    unit = false,
  } = props
  return (
    <CustomInput
      type="number"
      name={name}
      value={toDecimalMark(value)}
      InputProps={{
        startAdornment: <InputAdornment 
          position='start'
          sx={{
            '& .MuiTypography-root':{
              fontSize:'12px',
              color:'inherit',
              marginTop: '2px'
            }
          }}
        >ZAR</InputAdornment>
      }}
      onChange={onChange}
      disabled={disabled}
      {...props}
    />
  );
}


