import MDBox from "components/MDBox";

export const nodes = [
  {
    id: '1',
    data: {
      label: (
        <MDBox sx={{ backgroundColor: 'pink', padding: 2 }}>
          Node 1
        </MDBox>
      ), // JSX inside the node label
    },
    position: { x: 0, y: 0 },
    style:{
      padding: '0'
    }
  },
    {
      id: '2',
      data: { label: 'Node 2' },
      position: { x: 0, y: 100 },
      // type: 'custom',
    },
    {
      id: '3',
      data: { label: 'Node 3' },
      position: { x: 300, y: 0 },
      // type: 'custom',
    },
    {
      id: '4',
      data: { label: 'Node 4' },
      position: { x: 0, y: 0 },
    },
    {
      id: '5',
      data: { label: 'Node 5' },
      position: { x: 0, y: 0 },
    },
  ];
  
  export const edges = [
    {
      id: '1->2',
      source: '1',
      target: '2',
      label: 'Custom Label',
      // labelStyle: { fill: 'red', fontWeight: 700 },  // Custom label style
      // labelBgStyle: { fill: 'yellow' },  // Label background style
      // labelBgPadding: [8, 4],  // Padding around label
      // labelBgBorderRadius: 4,  // Rounded background corners
    },
    {
      id: '1->3',
      source: '1',
      target: '3',
    },
    {
      id: '4->5',
      source: '4',
      target: '5',
    },
  ];