import { useEffect, useState } from 'react';
import { messageService } from '../service/messageService';
import { useGlobalState } from 'Global';

const useMassage = (caseId) => {
    const [message, setMessage] = useState();
    const [agents, setAgents] = useState();
    const [filterdMessage, setFilterdMessage] = useState();
    const [userDetails] = useGlobalState("userDetails");

    useEffect(() => {
        Promise.all([
            messageService.getCaseTransferHistory(caseId),
            messageService.getNotificationHistory(caseId)
        ]).then(([caseTransferData, notificationData]) => {
            const messages = [...caseTransferData, ...notificationData].sort((a, b) => new Date(a?.timestamp) - new Date(b?.timestamp))
            setFilterdMessage(messages)
            setMessage(messages)
        }).catch((error) => { })

        messageService.getAgents().then((data) => {
            setAgents(data)
        })
    }, [caseId])

    return {
        message: message,
        agents: agents,
        setMessage: setMessage,
        filterdMessage: filterdMessage,
        setFilterdMessage: setFilterdMessage,
        myEmail: userDetails?.email
    }
}

export default useMassage