// @mui material components
import { useGlobalState } from "Global";
import { caseAPI } from "api";

//  React components
import MDBox from "components/MDBox";
import CustomAutoComplete from "components/controls/AutoComplete";
import { useNavigate, useLocation } from "react-router-dom";

//  React example components
import Controls from "components/controls/Controls";
import CustomInput from "components/controls/CustomInput";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MDChip from "components/MDChip";
import { useTheme } from "@emotion/react";
import WorkFlowChange from "layouts/forms/dialogs/workFlowChange";
import { CircularProgress } from "@mui/material";
import NextStepWorkFlow from "./NextStepWorkFlow";
import Confirmation from "examples/dialogs/Confirmation";

const showDetails = [
  "Current WorkFlow",
  "Claim Status",
  "Current Assigned Agent",
];

function Proceed({ caseDetails, reloadComponent }) {
  const params = useParams();
  const [state, setState] = useState({
    caseId: params.case_id,
    workflowId: caseDetails["Current WorkFlow"]?.name,
    claimStatus: caseDetails["Claim Status"]?.name,
    assignedAgent: caseDetails["Current Assigned Agent"]?.name,
  });
  
  const [caseState, setCaseState] = useState(caseDetails["Current Case State"]?.name)
  const [caseStateLoding, setCaseStateLoding] = useState(false)
  const [workflows, setWorkflows] = useState([]);
  const [nextSteps, setNextSteps] = useState({});
  const [agents, setAgents] = useState([]);
  const [showAgentChangeMessage, setShowAgentChangeMessage] = useState(false);
  const [userDetails] = useGlobalState("userDetails");
  
  const [showNextStep, setShowNextStep] = useState(false);
  const [nextStep, setNextStep] = useState(null);
  const [workFlowId, setWorkFlowId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    setState({
      caseId: params.case_id,
      workflowId: caseDetails["Current WorkFlow"]?.name,
      claimStatus: caseDetails["Claim Status"]?.name,
      assignedAgent: caseDetails["Current Assigned Agent"]?.name,
    })
    setCaseState(caseDetails["Current Case State"]?.name)
  }, [caseDetails])
  
  const handleStateChange = (key, value) => {
    setState({ ...state, [key]: value });
  };
  
  const reset = () => {
    setState({
      caseId: params.case_id,
      workflowId: caseDetails["Current WorkFlow"]?.name,
      claimStatus: caseDetails["Claim Status"]?.name,
      assignedAgent: caseDetails["Current Assigned Agent"]?.name,
    });
    setShowAgentChangeMessage(false);
    setShowNextStep(false);
  };
  const getStepMapping = (data) => {
    let tempMapping = {};
    data.forEach((item) => {
      tempMapping[item._id] = item.steps_in_workflow;
    });
    return tempMapping;
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
          setNextSteps(getStepMapping(res.data));
        }
      })
      .catch((error) => {});
  };

  const handleWorkFlowChange = () => {
    setIsLoading(true)
    caseAPI
      .changeWorkflow({
        caseId: state.caseId,
        workflowId: workFlowId,
        nextStep: nextStep,
      })
      .then((res) => {
        navigation(`/${userDetails.id}/cases`);
        showNextStep(false);
        setIsLoading(false)
      })
      .catch((error) => {});
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  const submitAgentChange = () => {
    setIsLoading(true)
    caseAPI
      .changeAgent(state.caseId, state.assignedAgent, state.message || "")
      .then((res) => {
        if (res.status == 200) {
          if (!["admin", "superuser"].includes(userDetails.permission)) {
            navigation(`/${userDetails.id}/cases`);
          } else {
            window.location.reload(false);
          }
          handleStateChange("message", "");
          setShowAgentChangeMessage(false);
          setIsLoading(false)
        }
      })
      .catch((error) => {});
  };

  const [stateChangeConfirmation, setStateChangeConfirmation] = useState(false)
  const handleChangeState = () => {
    let changeTo = caseState === "Resolved" ? "In-Progress" : "Resolved"
    updateClaimState(changeTo)
  }
  const updateClaimState = (state) => {
    setCaseStateLoding(true)
    caseAPI
      .changeState(params.case_id, state)
      .then((res) => {
        setCaseStateLoding(false)
        reloadComponent()
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getWorkflows();
    getAgents();
  }, []);

  const { palette } = useTheme();
  const { white, secondary, primary } = palette;

  return (
    <MDBox sx={{ padding: "1rem", width: "100%", height: "100%", paddingTop: '.2rem'}}>
      <MDBox
        sx={{
          gap: "1rem",
          position: "relative",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "end",
          }}>
        <MDChip
            sx={{
              backgroundColor: primary.main,
              padding: "5px 10px",
              color: white.main,
              fontWeight: "bold",
              cursor: "pointer",
              width: "230px",
            }}
            label={
              caseStateLoding
                ? "Updating..."
                : caseState === "Resolved"
                  ? "Revert back to In-Progress"
                  : "Make this Claim Resolved"
            }
            avatar={
              caseStateLoding ? (
                <CircularProgress
                  color="white"
                  sx={{
                    height: "18px !important",
                    width: "18px !important",
                    backgroundColor: "#00000000 !important",
                  }}
                />
              ) : (
                <></>
              )
            }
            onClick={() => {
              caseStateLoding ? {} : setStateChangeConfirmation(true);
            }}
          />
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <CustomAutoComplete
            label="Current WorkFlow"
            name="workflowId"
            value={state.workflowId}
            onChange={(e, value) => {
              handleStateChange("workflowId", value.name);
              setWorkFlowId(value["_id"]);
              setShowNextStep(true);
            }}
            onFocus={reset}
            options={workflows}
            disableClearable
          />
          <CustomAutoComplete
            label="Current Assigned Agent"
            name="assignedAgent"
            value={state.assignedAgent}
            onChange={(e, value) => {
              handleStateChange("assignedAgent", value?.email_id);
              setShowAgentChangeMessage(true);
            }}
            onFocus={reset}
            options={agents}
          />
        </MDBox>
        <MDBox>
          {showAgentChangeMessage && (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <CustomInput
                label="Message"
                name="message"
                value={state.message}
                onChange={(e) => handleStateChange("message", e.target.value)}
              />
              <MDChip
                sx={{
                  backgroundColor: primary.main,
                  padding: "5px 10px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "140px",
                }}
                label={isLoading ? "Updating..." : "Change Agent"}
                avatar={
                  isLoading ? (
                    <CircularProgress
                      color="white"
                      sx={{
                        height: "18px !important",
                        width: "18px !important",
                        backgroundColor: "#00000000 !important",
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
                onClick={() => {
                  isLoading ? {} : submitAgentChange();
                }}
              />
            </MDBox>
          )}
          {showNextStep && (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <CustomAutoComplete
                label="Available Steps"
                name="nextStep"
                onChange={(e, value) => setNextStep(value)}
                options={nextSteps[workFlowId]}
              />
              <MDChip
                sx={{
                  backgroundColor: primary.main,
                  padding: "5px 10px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                label={isLoading ? "Updating..." : "Change Workflow"}
                avatar={
                  isLoading ? (
                    <CircularProgress
                      color="white"
                      sx={{
                        height: "18px !important",
                        width: "18px !important",
                        backgroundColor: "#00000000 !important",
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
                onClick={() => {
                  isLoading ? {} : handleWorkFlowChange();
                }}
              />
            </MDBox>
          )}
        </MDBox>
      </MDBox>

      <MDBox pb={"5px"} sx={{ height: "calc(100% - 80px)" }}>
        <NextStepWorkFlow
          currentStep={caseDetails["Current Step"]}
          caseId={params.case_id}
        />
      </MDBox>
      <Confirmation
        open={stateChangeConfirmation}
        onClose={() => {
          setStateChangeConfirmation(false);
        }}
        onAccept={() => {
          setStateChangeConfirmation(false);
          handleChangeState();
        }}
        title={
          caseState === "Resolved"
            ? "Do you really want to Revert back this Case State to In-Progress?"
            : "Do you really want to Make this Case State Resolved?"
        }
        acceptLable={"Update"}
      />
    </MDBox>
  );
}

export default Proceed;
