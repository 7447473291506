import { FormControl, Input } from "@material-ui/core";
import { Card, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { toFloatMark } from "utils/conversion";
import { PreValidations } from "./Validations";
import { useTheme } from "@emotion/react";
import CustomInput from "./CustomInput";

export default function KeyValueConst({
  name,
  label,
  value,
  onChange,
  keyscopes,
  disabled,
  stepDetails,
  amount,
  preValidationList = [],
  ...props
}) {
  const [categories, setCategories] = useState({});
  const [error, setError] = useState(null);
  const theme = useTheme();

  const handleValueChange = (event) => {
    let { value, name } = event.target
    
    if (value) {
      let newVal = toFloatMark(value);

      if (value.slice(-1) == ".") {
        value = newVal + ".";
      } else if (value.slice(-2) == ".0") {
        value = newVal + ".0";
      } else {
        value = String(newVal);
      }
    }
    if(name === "Valid" || name === "Invalid"){
      let remainingAmount = stepDetails["Claim Amount (ZAR)"]?.value * 1 - (value * 1 + (name === "Valid" ? categories["Invalid"] * 1 : categories["Valid"] * 1))
      setCategories({
        ...categories,
        [name]: value || 0,
        "Awaiting Decision" : (remainingAmount < 0) ? 0 : remainingAmount
      });
    } else {
      setCategories({
        ...categories,
        [name]: value || 0,
      });
    }
  };

  const preUpdateValidations = () => {
    preValidationList.forEach((validation) => {
      let dynamicValidation = PreValidations[validation.name];
      let result = true;
      result = dynamicValidation(
        stepDetails[validation.parameters["feature_name1"]].value,
        categories
      );

      if (result == true) result = 0;
      setError(result.toFixed(2));
    });
  };

  useEffect(() => {
    onChange({ target: { name: name, value: categories } });

    preUpdateValidations();
  }, [categories]);

  const initializeCategories = () => {
    let tempCategories = {};
    let tempAmount = parseFloat("0");
    if (value)
      Object.keys(value).forEach((category) => {
        tempAmount += parseFloat(value[category]);
        tempCategories[category] = value[category];
      });
    setCategories({
      ...tempCategories,
    });
  };

  useEffect(() => {
    initializeCategories();
  }, []);

  return (
    <FormControl fullWidth={true}>
      <MDBox
        sx={{
          border: `1px solid ${theme.palette.grey[300]} !important`,
          padding: "0.5rem",
          borderRadius: "0.75rem",
        }}
        {...props}
      >
        <MDBox
          sx={{
            display: "flex",
            width: "100%",

            justifyContent: "space-between",
          }}
        >
          {label && (
            <Typography
              variant="headline"
              sx={{ fontSize: "1rem !important", fontWeight: "400" }}
            >
              {" "}
              {name}{" "}
            </Typography>
          )}
        </MDBox>
        <MDBox
          {...(props.componentSize == "small"
            ? {}
            : {
                sx: {
                  padding: "1rem",
                  marginTop: "1rem",
                  paddingTop: "0",
                },
              })}
        >
          {categories.length != 0 &&
            Object.keys(categories).map((category, index) => {
              return (
                <MDBox sx={{ display: "flex" }} key={index}>
                  <MDBox
                    sx={{
                      display: "flex",
                      width: "100%",
                      textAlign: "right",
                      float: "right",
                      padding: "0.5rem",
                      marginBottom: "0.5rem",
                      justifyContent: "space-between",
                      border: `0.1px solid ${theme.palette.grey[300]}`,
                      borderRadius: "5px",
                    }}
                  >
                    <MDTypography
                      sx={{
                        textAlign: "left",
                        fontSize:
                          props.componentSize == "small"
                            ? `${theme.typography.size["xs"]} !important`
                            : theme.typography.size["sm"],
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {category}
                    </MDTypography>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        maxWidth: "40%",
                      }}
                    >
                      <CustomInput
                        value={categories[category]}
                        name={category}
                        style={{
                          textAlign: "center",
                          backgroundColor: theme.palette.primary.light,
                        }}
                        size={props.componentSize}
                        onChange={handleValueChange}
                        disabled={disabled ? true : false}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              );
            })}
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems:'center',
              width: "100%",
              padding: "0 9px",
            }}
          >
            {error && (
              <>
                <MDTypography
                  sx={{
                    fontSize: theme.typography.size['md'],
                    color:
                      error == 0
                        ? '#00CC9B'
                        : theme.palette.error.main,
                  }}
                >
                  Balance Amount:{" "}
                </MDTypography>
                <MDTypography
                  sx={{
                    width: "40%",
                    padding:'12px',
                    maxWidth: "124px",
                    lineHeight:'1em',
                    fontSize: theme.typography.size['md'],
                    color:
                      error == 0
                        ? '#00CC9B'
                        : theme.palette.error.main,
                    textAlign: "center",
                    backgroundColor:'#DEFFF7',
                    borderRadius:'4px'
                  }}
                >
                  {error}
                </MDTypography>
              </>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </FormControl>
  );
}
