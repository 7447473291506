import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function FilesSkeleton() {
  return (
    <SkeletonTheme highlightColor="white">
      {Array(5)
        .fill(0)
        .map((item, index) => {
          return (
            <MDBox
              sx={{
                marginTop: '16px',
                padding: '8px',
                border: '1px solid #E7E7E7',
                borderRadius: '10px'
              }}
            >
              <Skeleton style={{ height: "1rem" }} />
            </MDBox>
          );
        })}
    </SkeletonTheme>
  );
}

export default FilesSkeleton;
