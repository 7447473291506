import { useEffect, useState } from 'react';
import { notesService } from '../service/notesService';
import { useGlobalState } from 'Global';

const useNotes = (caseId) => {
    const [notes, setNotes] = useState();
    const [filterdNotes, setFilterdNotes] = useState();
    const [userDetails] = useGlobalState("userDetails");

    useEffect(() => {
        notesService.getNotes(caseId).then((notes) => {
            setFilterdNotes(notes)
            setNotes(notes)
        })
    }, [caseId])

    return {
        notes: notes,
        setNotes: setNotes,
        filterdNotes: filterdNotes,
        setFilterdNotes: setFilterdNotes,
        myEmail: userDetails?.email
    }
}

export default useNotes