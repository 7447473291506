import { useTheme } from "@emotion/react";
import { userAPI } from "api";
import CustomAutoComplete from "components/controls/AutoComplete";
import CustomInput from "components/controls/CustomInput";
import MDBox from "components/MDBox";
import { setGlobalState } from "Global";
import { Grid } from "@mui/material";
import MDChip from "components/MDChip";
import { useState } from "react";

const userDetailsMapping = {
  "Full Name": "name",
  Email: "email_id",
  Title: "title",
  City: "city",
  Permission: "permission",
  "Business Function": "business_func",
  "Secondary Contact": "secondary_contact",
};

const userDetailsEditable = {
  "Full Name": false,
  Email: false,
  Title: true,
  City: true,
  Permission: false,
  "Business Function": true,
  "Seondary Contact": true,
};

const AccountSettings = ({ userDetails, setUserDetials, agents }) => {
  const { palette, functions } = useTheme();
  const { primary, white } = palette;

  const handleSubmit = () => {
    userAPI
      .updateUserDetails(userDetails)
      .then((res) => {
        setUserDetials(userDetails);
        setGlobalState("error", {
          open: true,
          message: "Successfully updated user data.",
          type: "success",
        });
      })
      .catch((error) => {});
  };

  const handleChange = (key, value) => {
    setUserDetials({ ...userDetails, [key]: value });
  };
  const [focusedFields, setFocusedFields] = useState({});

  const handleFocus = (key) => {
    setFocusedFields((prev) => ({ ...prev, [key]: true }));
  };

  const handleBlur = (key) => {
    setFocusedFields((prev) => ({ ...prev, [key]: false }));
  };

  return (
    <MDBox sx={{ padding: "20px", maxWidth: "750px" }}>
      <Grid container rowGap={1} columnSpacing={5}>
        {Object.keys(userDetailsMapping).map((detail) => {
          if (detail != "Secondary Contact") {
            return (
              <Grid
                item
                xs={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CustomInput
                  label={detail}
                  name={userDetailsMapping[detail]}
                  InputLabelProps={{
                    shrink:
                      !!userDetails?.[userDetailsMapping[detail]] ||
                      focusedFields[userDetailsMapping[detail]],
                  }}
                  value={userDetails?.[userDetailsMapping[detail]]}
                  onChange={(e) =>
                    handleChange(userDetailsMapping[detail], e.target.value)
                  }
                  disabled={!userDetailsEditable[detail]}
                  onFocus={() => handleFocus(userDetailsMapping[detail])}
                  onBlur={() => handleBlur(userDetailsMapping[detail])}
                />
              </Grid>
            );
          }
        })}
        {userDetails && Object.keys(userDetails).length > 0 && (
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
            <CustomAutoComplete
              value={userDetails?.[userDetailsMapping["Secondary Contact"]]}
              name={"secondary_contact"}
              label={"Secondary Contact"}
              options={agents}
              onChange={(e, value) =>
                handleChange("secondary_contact", value?.email_id)
              }
            />
          </Grid>
        )}
      </Grid>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <MDChip
          sx={{
            backgroundColor: primary.main,
            padding: "5px 10px",
            color: white.main,
            fontWeight: "bold",
            cursor: "pointer",
            width: "140px",
          }}
          label={"Update"}
          onClick={handleSubmit}
        />
      </MDBox>
    </MDBox>
  );
};

export default AccountSettings;
