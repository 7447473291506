/*
 * This file serves as a base file for any API interactions with other applications.
 * All API calls, declarations, definitions are included here.
 */
import axios from "axios";
import useWebSocket from "react-use-websocket";

const url = "";

export let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL_MAIN,
  withCredentials: true,
});

export let docstoreClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL_DOCSTORE,
  withCredentials: true,
})

// export let client = axios.create({
//   baseURL: process.env.REACT_APP_API_URL_PM,
//   withCredentials: true,
// });

const webSocketUrl = process.env.REACT_APP_API_URL_WS;
// const url = "";

export const basicAPI = {
  signin(username, password) {
    const data = { username: username, password: password };
    return client.post(`${url}/signin`, data);
  },

  signinNew(username, password) {
    const data = { username: username, password: password };
    return client.post(`${url}/signin_new`, data);
  },


  emailVerify(data) {
    return client.post(`${url}/signin_new/otp`, data);
  },

  signinKey() {
    return client.get(`${url}/signin_new/public_key`);
  },

  login() {
    return client.get(`${url}/login`);
  },

  logout() {
    return client.get(`${url}/logout`);
  },

  // userData() {
  //   return client.get(`${url}/me`);
  // },

  refreshToken() {
    return client.get(`${url}/me`);
  },

  loginCallback(code) {
    const params = { code: code };
    return client.get(`${url}/login/callback`, { params: params });
  },
};

export const agentAPI = {
  // New api
  getCasesAsignedToMe(data) {
    return client.post(`${url}/get_cases`, data);
  },
  getAllCases(data) {
    return client.post(`${url}/get_all_cases`, data);
  },
  getAllCasesGrouped(data) {
    return client.post(`${url}/get_cases`, data);
  },
  getAllCasesAI(data) {
    return client.post(`${url}/get_all_cases_AI_assisted`, data);
  },
  getAllCasesAIAdmin(data) {
    return client.post(`${url}/get_all_cases_admin_AI_assisted`, data);
  },

  downloadCasesAdmin(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/download_cases_admin`, data, {
      params: params,
      responseType: "blob",
    });
  },

  getAllCasesAdminGrouped(paramsData, data) {
    const params = paramsData;
    return client.post(
      `${url}/get_all_admin_grouped_based_on_parameters`,
      data,
      { params: params }
    );
  },

  getGroupedCasesList(params, data) {
    return client.post(`${url}/get_all_cases_in_grouped_filter`, data, {
      params: params,
    });
  },

  getAgentDetails(agentId) {
    const params = { agent_id: agentId };
    return client.get(`${url}/get_single_agent_details`, { params: params });
  },
};

export const featureAPI = {
  getFeatures(case_id, step_name) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client.get(`${url}/get_features`, { params: params });
  },

  getGroupedCases(params, data) {
    return client.post(`${url}/get_all_parents_for_case_id`, data, {
      params: params,
    });
  },

  getFiles(case_id, step_name) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client.get(`${url}/get_files`, { params: params });
  },

  updateFeatures(case_id, step_name, data) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client({
      url: `${url}/update_features`,
      method: "POST",
      data,
      params: params,
      // headers: { "content-type": "multipart/form-data" },
    });
  },

  updateFiles(case_id, step_name, data) {
    const params = {
      case_id: case_id,
      step_name: step_name,
    };
    return client({
      url: `${url}/update_files`,
      method: "POST",
      data: data,
      params: params,
      headers: { "content-type": "multipart/form-data" },
    });
  },

  downloadFile(params) {
    return client({
      method: "POST",
      url: `${url}/download_file`,
      responseType: "blob",
      params: params,
    });
  },

  deleteFile(params) {
    return client.get(`${url}/delete_individual_file`, { params: params });
  },
};

export const userAPI = {
  getUserDetails() {
    return client.get(`${url}/get_current_user_details`);
  },

  getNotifications(params) {
    return client.get(`${url}/get_notifications_recieved`, {
      params: params,
    });
  },

  updateNotificationStatus(id, status){
    return client.post(`${url}/update_notification_status?`, {
      notification_id: id,
      status: status,
    });
  },

  updateUserDetails(userDetails) {
    return client.post(`${url}/update_user_details`, userDetails);
  },
};

export const workflowAPI = {
  createWorkflow(data){
    return client.post(`${url}/workflow/create_workflow`, data);
  },
  updateWorkflowStep(data){
    return client.post(`${url}/workflow/update_step`, data);
  },
  updateWorkflow(data){
    return client.post(`${url}/workflow/update_workflow`, data);
  },

  getCustomerCodeMaps() {
    return client.post(`${url}/get_all_customer_code_maps`, null, {});
  },
  updateCustomerCodeMaps(data) {
    return client.post(`${url}/update_customer_code_map_features`, data);
  },
};

export const caseAPI = {
  createCase(caseDetails) {
    const data = {
      assigned_agent_email: caseDetails.assigned_agent_email,
      workflow_id: caseDetails.workflow_id,
    };
    return client.post(`${url}/create_case`, data);
  },

  getWorkflowById(workflowId) {
    const params = {
      workflow_id: workflowId,
    };
    return client.get(`${url}/get_workflow_by_id`, { params: params });
  },

  getNextPaths(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_available_paths`, { params: params });
  },

  getTags(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_tags_for_case_id`, { params: params });
  },

  getAllPaths(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_all_paths`, { params: params });
  },

  changeStatus(caseId, nextStep, checked, email_id, message) {
    let params = {
      case_id: caseId,
      next_step: nextStep,
      auto_assign: false,
      message: message,
    };
    if (checked) {
      params = {
        ...params,
        auto_assign: checked,
        auto_assignee_email: email_id,
      };
    }
    return client.get(`${url}/change_case_status`, { params: params });
  },

  changeState(caseId, caseState) {
    const params = { case_id: caseId, case_state: caseState };
    return client.get(`${url}/change_case_state`, { params: params });
  },

  changeWorkflow({ caseId, nextStep, workflowId }) {
    const params = {
      case_id: caseId,
      next_step: nextStep,
      workflow_id: workflowId,
    };
    return client.get(`${url}/change_case_workflow_and_status`, {
      params: params,
    });
  },

  updateWorflowAutoTransfer(workflowId, StepName, agent_email = null) {
    const data = {
      agent_email: agent_email,
      step_name: StepName,
      workflow_id: workflowId,
    };
    return client.post(`${url}/update_workflow_auto_transfer_email`, data);
  },

  updateWorflowInitAgent(workflowId, agent_email) {
    const data = {
      agent_email: agent_email,
      workflow_id: workflowId,
    };
    return client.post(`${url}/update_workflow_init_agent`, data);
  },

  changeAgent(caseId, emailTo, message) {
    const params = { case_id: caseId, message: message };
    const data = { value: emailTo };
    return client({
      url: `${url}/change_case_agent`,
      method: "POST",
      data,
      params: params,
    });
  },

  getCase(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/get_case_details`, { params: params });
  },

  getHistory(params) {
    return client.get(`${url}/get_history_for_case_id`, { params: params });
  },

  getAgents() {
    return client.get(`${url}/get_all_agents`);
  },

  getOwners() {
    return client.get(`${url}/get_all_owners`);
  },

  getWorkflows() {
    return client.get(`${url}/get_all_workflows`);
  },

  getAutoAssignedPerson(case_id, step_name) {
    const params = { case_id: case_id, next_step_chosen: step_name };
    return client.get(`${url}/get_auto_assigned_person`, { params: params });
  },

  updateCase(caseDetails) {
    const date = {
      case_id: caseDetails.case_id,
      agent_id: caseDetails.agent_id,
      current_status: caseDetails.current_status,
      claim: caseDetails.claim,
    };
    return client.post(`${url}/create_case`, date);
  },

  getNotificationHistory(params) {
    return client.get(`${url}/read_notification_history`, {
      params: params,
    });
  },

  getCaseTransferHistory(params) {
    return client.get(`${url}/read_transfer_notes_history`, {
      params: params,
    });
  },

  sendMail({ case_id, emailTo, emailCC, subject, body, case_details }) {
    const params = {
      case_id: case_id,
      header: subject,
      body: body,
      cc_email: emailCC,
      case_details: case_details,
    };
    const data = { value: emailTo };
    return client({
      url: `${url}/send_notification`,
      method: "POST",
      data,
      params: params,
    });
  },
};

export const chatAPI = {
  getComments(caseId) {
    const params = { case_id: caseId };
    return client.get(`${url}/read_comment_for_case`, {
      params: params,
    });
  },

  sendComments(caseId, message) {
    const data = { case_id: caseId, message: message };
    return client.post(`${url}/add_comment_for_case`, data);
  },
};

export const caseDocumentAPI = {
  //bulk upload

  uploadBulkCases(data, type) {
    if (type == "base") {
      return client({
        url: `${url}/excel_upload_base`,
        method: "POST",
        data,
        headers: { "content-type": "multipart/form-data" },
      });
    } else
      return client({
        url: `${url}/excel_upload`,
        method: "POST",
        data,
        headers: { "content-type": "multipart/form-data" },
      });
  },
};

export const bulkStatAPI = {
  getBulkUploadFiles() {
    return client.get(`${url}/list_bulk_upload_files`);
  },
  downloadFile(filename) {
    const params = { file_name: filename };
    return client.get(`${url}/download_bulk_upload_file`, { params: params, responseType: "blob"});
  },
  getNewInsert() {
    return client.get(`${url}/get_newCasesBulkInsert`);
  },
  getResolve() {
    return client.get(`${url}/get_resolveCasesBulkInsert`);
  },
  getResolveConflict() {
    return client.get(`${url}/get_resolvedPresentBulkInsert`);
  },
  getUpdate() {
    return client.get(`${url}/get_updateCasesBulkInsert`);
  },
  getExcelData() {
    return client.get(`${url}/get_excelDataBulk`);
  },
  getRejectedData() {
    return client.get(`${url}/get_rejectedDataBulk`);
  },

  getInsertingStatus() {
    return client.get(`${url}/get_inserting_status`);
  },
  getUpdatingStatus() {
    return client.get(`${url}/get_update_status`);
  },
  getResolveStatus() {
    return client.get(`${url}/get_resolve_status`);
  },
  getOverallStatus() {
    return client.get(`${url}/get_overall_status`);
  },

  postInsert() {
    return client.get(`${url}/init_background_inserting`);
  },
  postResolve(data) {
    return client.get(`${url}/init_background_resolving`);
  },
  postUpdate(data) {
    return client.get(`${url}/init_background_updating`);
  },
};

//signup-auth

export const SignUpAPI = {
  signUpEmail(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify`,
      method: "POST",
      data,
    });
  },
  signUpEmailResend(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/resend`,
      method: "POST",
      data,
    });
  },
  signUpEmailOTP(params, email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/otp`,
      method: "POST",
      data,
      params: params,
    });
  },
  signUpEmailPasswd(params, email) {
    const data = { value: email };

    return client({
      url: `${url}/sign-up/emailverify/otp/setpasswd`,
      method: "POST",
      data,
      params: params,
    });
  },
};

export const AnalyticsApi = {
  getCaseStateCounts(filters){
    return client.post(`${url}/get_cases_states_analytics`, filters)
  },
  getClaimsCountByAgeBucket(filters){
    return client.post(`${url}/get_claim_count_by_age_bucket`, filters)
  },
  getClaimCostByCustomer(filters){
    return client.post(`${url}/get_claim_cost_by_customer`, filters)
  },
  getClaimCountByReasonCodes(filters){
    return client.post(`${url}/get_claim_count_by_reason_codes`, filters)
  },
  getInvalidClaimsByCustomer(filters){
    return client.post(`${url}/invalid_claims_by_customer`, filters)
  },
  getClaimsByAgent(filters){
    return client.post(`${url}/get_claims_by_agent`, filters)
  },
  getClaimsByTime(filters){
    return client.post(`${url}/cases_per_state_vs_time`, filters)
  },
  getClaimsByCurrentStatus(filters){
    return client.post(`${url}/get_claims_by_current_status`, filters)
  },
  getClaimsByWorkflow(filters){
    return client.post(`${url}/get_claims_by_workflow`, filters)
  },  
  getClaimsByRetailer(filters){
    return client.post(`${url}/claims_by_retailer`, filters)
  },
}

export const analyticsAPI = {
  // Basic Analytics

  getCaseOldnessSummary(resolved) {
    const params = { resolved: resolved };
    return client.get(`${url}/get_case_oldness_summary`, { params: params });
  },

  getWorkflowWiseClaimCount(resolved) {
    const params = { resolved: resolved };
    return client.get(`${url}/get_workflow_wise_number_of_claims`, {
      params: params,
    });
  },

  getWorkflowWiseClaimAmount(resolved) {
    const params = { resolved: resolved };
    return client.get(`${url}/get_workflow_wise_total_claim_amount`, {
      params: params,
    });
  },

  getStatusWiseCount() {
    const params = {};
    return client.get(`${url}/get_number_of_cases_in_statuses`, {
      params: params,
    });
  },

  getClaimsByCustomer(resolved) {
    const params = { resolved: resolved };
    return client.get(`${url}/get_total_claims_by_customer`, {
      params: params,
    });
  },

  // Info Cards - Total Claims
  getCasesStatesAnalytics(filters) {
    return client.post(`${url}/get_cases_states_analytics`, filters);
  },


  // Pie Chart Filters

  getSummaryFilterValues() {
    return client.get(`${url}/get_all_filters`);
  },

  getGroupedFilterValues() {
    return client.get(`${url}/get_all_filters_grouped_cases`);
  },
};

export const docstoreAPI = {

  // Create Folder
  createFolder(folderData) {
    return docstoreClient.post(`${url}/folders/create_folder`, folderData);
  },

  // List Folder Contents
  listFolderContents(folderData) {
    return docstoreClient.post(`${url}/folders/list_contents`, folderData);
  },

  // Delete Folder
  deleteFolder(folderData) {
    return docstoreClient.post(`${url}/folders/delete_folder`, folderData);
  },

  // Share Folder
  shareFolder(folderData) {
    return docstoreClient.post(`${url}/folders/share_folder`, folderData);
  },

  // Upload File to Folder
  uploadFileToFolder(folderId, file, metadata = "") {
    const formData = new FormData();
    formData.append("folder_id", folderId);
    formData.append("file", file);
    formData.append("metadata", metadata);

    return docstoreClient.post(`${url}/folders/upload_file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // Download File
  downloadFile(folderData) {
    return docstoreClient.post(`${url}/files/download_file`, folderData, {
      responseType: "blob",
    });
  },

  // Get File Details
  getFileDetails(folderData) {
    return docstoreClient.post(`${url}/files/get_file`, folderData);
  },

  // Delete File
  deleteFile(folderData) {
    return docstoreClient.post(`${url}/files/delete_file`, folderData);
  },

  // Share File
  shareFile(folderData) {
    return docstoreClient.post(`${url}/files/share_file`, folderData);
  },

  // Search Files
  searchFiles(folderData) {
    return docstoreClient.post(`${url}/files/search`, folderData);
  },
};


export const OCRAPI = {
  getFileFeatures(params) {
    return client.get(`${url}/get_match_of_features_for_file`, {
      params: params,
    });
  },

  getFeaturesVerified(params) {
    return client.get(`${url}/get_all_feature_ocr_verified`, {
      params: params,
    });
  },
};

export const AIAgentAPI = {
  getCaseSuggestions(params) {
    return client.get(`${url}/get_next_step_suggestions`, {
      params: params,
    });
  },

  getCaseSuggestionsTemp(params) {
    return client.get(`${url}/get_final_wrapper_ai_agent`, {
      params: params,
    });
  },

  getCaseSuggestionsWS(params) {
    return new useWebSocket(`${webSocketUrl}/get_final_wrapper_ai_agent/ws`, {
      queryParams: params,
    });
  },
  getDetailsAvailability(params) {
    return client.get(`${url}/get_all_suggestions_for_step`, {
      params: params,
    });
  },

  getCaseSummary(params) {
    return client.get(`${url}/get_summary_of_case`, {
      params: params,
    });
  },
  getCaseSummaryTemp(params) {
    return client.get(`${url}/get_situation_of_case`, {
      params: params,
    });
  },

  performCaseAction(params, data) {
    return client.post(`${url}/do_action_AI_agent`, data, { params: params });
  },
};

export const BannerAPI = {
  getBannerStatus() {
    return client.get(`${url}/get_maintenance_status`);
  },
};

export const AdminAPI = {
  getAdminUserData() {
    return client.get(`${url}/admin/get_users_with_access`);
  },

  updateAdminUserData(data) {
    return client.post(`${url}/admin/update_user_access`, data);
  },

  addAdminUser(data) {
    return client.post(`${url}/admin/add_user_access`, data);
  },
};
