import * as React from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import { Box, InputAdornment, Typography } from "@mui/material";

const Input = React.forwardRef(function CustomInput(props, ref) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

export default function CustomInput({
  name,
  label,
  value,
  error = null,
  onChange,
  disabled = false,
  unit = false,
  ...props
}) {
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Typography
        variant="headline"
        sx={{ fontSize: "1rem !important", fontWeight: "500" }}
        className={"font-unset"}
      >
        {" "}
        {label}{" "}
      </Typography>
      <Input
        name={name}
        value={value}
        InputProps={
          unit
            ? {
                startAdornment: <InputAdornment>ZAR</InputAdornment>,
              }
            : {}
        }
        onChange={onChange}
        disabled={disabled}
      />
    </Box>
  );
}


const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const InputElement = styled("input")(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };

  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
