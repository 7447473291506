import { agentAPI } from "api";
import { useGlobalState } from "Global";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const columnData = [
  {
    accessor: 'case_state',
    type: 'state'
  },
  {
    accessor: 'case_id',
    type: 'id'
  },
  {
    accessor: 'Reference Key',
    type: 'text'
  },
  {
    accessor: 'Claim Amount (ZAR)',
    type: 'text'
  },
  {
    accessor: 'workflow_name',
    type: 'chip'
  },
  {
    accessor: 'Customer',
    type: 'text'
  },
  {
    accessor: 'Claim Status',
    type: 'object'
  },
  {
    accessor: 'assigned_agent_email',
    type: 'text'
  },
  {
    accessor: 'current_status',
    type: 'text'
  },
  {
    accessor: 'case_start_timestamp',
    type: 'timestamp'
  },
];


const useClaimsData = (props) => {
  const [rows, setRows] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [userDetails] = useGlobalState("userDetails");

  // should use same name
  const chartToFilterMapping = {
    AgeBucket: "age_bucket",
    Workflow: "workflow_id",
    Customer: "customer_name",
    ReasonCodes: "reason_codes",
    SKU: "sku",
    AssignedAgent: "assigned_agent",
    Category: "category",
    CaseState: "case_state",
    ClaimType: "claim_type",
    ClaimStatus: "claim_status",
    days: "days",
    search: "search",
    limit: "limit",
    offset: "offset",
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        tempParams[chartToFilterMapping[param]] = params[param];
      });
    }
    return tempParams;
  };

  const getCaseData = () => {
    if (props.caseListType == "default") {
      props.setIsLoading(true);
      const adminCases = userDetails?.permission ==='superuser'
        ? "Admin"
        : "";
      const dynamicFunction =
        agentAPI[`getAllCases`];

      dynamicFunction(
        props.filters,
        props.filters,
        // convertToParams(props.filters),
        // convertToParams(props.filters)
      )
        .then((res) => {
          // setRows(setUserCases(res.data));
          setPageInfo({ count: res.data.count, pages: res.data.pages });
          setRows(res.data.data);
          props.setIsLoading(false);
        })
        .catch((error) => {
          props.setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if(userDetails?.permission) getCaseData();
  }, [userDetails, props.applyFilterFlag, props.caseListType]);

  return {
    columns: columnData,
    rows,
    pages: pageInfo,
  };
};

export default useClaimsData;
