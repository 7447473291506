import { useEffect, useCallback, useState, useRef } from "react";
import ReactFlow, {
  MarkerType,
  ReactFlowProvider,
  useReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  ConnectionLineType,
  Background,
  Handle,
  Controls,
  Position,
  MiniMap,
} from "reactflow";
// import { useControls, button } from "leva";

import useAutoLayout from "./hooks/useAutoLayout";
import {
  nodes as initialNodes,
  edges as initialEdges,
} from "./initialElements";
import { getId } from "./utils";
import "reactflow/dist/style.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { useTheme } from "@emotion/react";

const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};

const defaultEdgeOptions = {
  type: "smoothstep",
  markerEnd: { type: MarkerType.ArrowClosed },
  pathOptions: { offset: 5 },
};

const defaultlayoutOptions = {
  about: false,
  algorithm: "dagre",
  direction: "TB",
  spacing: [50, 50],
};

const fitViewOptions = {
  padding: 0.95,
};

function ReactFlowPro({
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,

  nodesDraggable,
  panOnDrag,

  isAutoLayout,
  showBackground,
  showControls,
  showMinimap,
  fitView,
  controlsPosition,
  layoutOptions,
  edgeOptions,
  zoomOnScroll,

  nodeStyle,
  edgeStyle,
  nodeDotStyle,
  edgeTextStyle,

  ...props
}) {
  const { addNodes, setViewport } = useReactFlow();
  const nodeClickedRef = useRef(false); // Use a ref to track node clicks
  // const [nodes, setNodes, onNodesChange] = useNodesState(nodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(edges);

  if(isAutoLayout) useAutoLayout(layoutOptions || defaultlayoutOptions);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const theme = useTheme();

  return (
    <MDBox sx={{ height: "100%", width: "100%" }}>
      <ReactFlow
        fitView={fitView}
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
        setEdges={setEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}

        zoomOnDoubleClick={false}
        zoomOnScroll={zoomOnScroll}
        nodesDraggable={nodesDraggable}
        panOnDrag={panOnDrag}
        defaultEdgeOptions={edgeOptions || defaultEdgeOptions}
        proOptions={proOptions}
        fitViewOptions={fitViewOptions}
        // onConnect={onConnect}
        // onNodeClick={onNodeClick}

        {...props}
      >
        {showBackground && <Background
          variant="dots"
          color={theme.palette.text.disabled}
          style={{ zIndex: -1 }}
        />}
        {showControls && <Controls position={controlsPosition || "top-right"} style={{ size: "2rem" }} />}
        {showMinimap && <MiniMap />}
      </ReactFlow>
      <style jsx>
        {`
          .react-flow__node{${nodeStyle}}
          .react-flow__handle{${nodeDotStyle}}
          .react-flow__edge-path{${edgeStyle}}
          .react-flow__edge-textbg, .react-flow__edge-text{${edgeTextStyle}}
        `}
      </style>
    </MDBox>
  );
}

const WorkFlow = ({
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,

  nodesDraggable,
  panOnDrag,
  onNodeClick,

  isAutoLayout,
  showBackground,
  showControls,
  controlsPosition,
  layoutOptions,
  edgeOptions,
  zoomOnScroll,

  nodeStyle,
  edgeStyle,
  nodeDotStyle,
  edgeTextStyle,

  ...props
}) => {
  return (
    <ReactFlowProvider>
      <ReactFlowPro
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
        setEdges={setEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}

        isAutoLayout={isAutoLayout}
        showBackground={showBackground}
        showControls={showControls}
        controlsPosition={controlsPosition}
        layoutOptions={layoutOptions}
        edgeOptions={edgeOptions}
        nodesDraggable={nodesDraggable}
        panOnDrag={panOnDrag}
        zoomOnScroll={zoomOnScroll}
        onNodeClick={onNodeClick}

        nodeStyle={nodeStyle}
        edgeStyle={edgeStyle}
        nodeDotStyle={nodeDotStyle}
        edgeTextStyle={edgeTextStyle}

        key={nodes}
        {...props}
      />
    </ReactFlowProvider>
  );
};

export default WorkFlow;
