import { useTheme } from '@emotion/react';
import { CircularProgress, InputBase } from '@mui/material';
import MDBox from 'components/MDBox';
import { useEffect } from 'react';

const BottomInput = ({ placeHolder, icon, handleClick, inputRef, style, isLoading }) => {
    const { palette, typography, functions } = useTheme();
    const { primary, white, text } = palette;
    const { size } = typography;
    const { pxToRem } = functions;

    return (
      <MDBox
        sx={{
          display: "flex",
          gap: "8px",
          ...style,
        }}
      >
        <style jsx>{`
          [contenteditable="plaintext-only"]:empty:before {
            content: attr(placeholder);
            pointer-events: none;
            color: ${text.disabled};
            opacity: 1;
            display: block; /* For Firefox */
          }
          [contenteditable="plaintext-only"]:focus {
            outline: none;
          }
        `}</style>

        <MDBox
          sx={{
            width: "100%",
            borderRadius: "8px",
            maxHeight: "100px",
            overflow: "auto",
          }}
        >
          <div
            contenteditable="plaintext-only"
            ref={inputRef}
            placeholder={placeHolder}
            style={{
              backgroundColor: primary.light,
              borderRadius: "8px",
              padding: "9px",
              paddingLeft: "12px",
              fontSize: size["sm"],
              color: text.secondary,
            }}
          />
        </MDBox>
        {(icon || handleClick) && (
          <MDBox
            sx={{
              backgroundColor: primary.main,
              color: white.main,
              height: "40px",
              width: "50px",
              fontSize: pxToRem(22),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={isLoading ? () => {} : () => handleClick()}
          >
            {isLoading ? (
              <CircularProgress
                sx={{ height: "1.2em !important", width: "1.2em !important" }}
                color={"white"}
              />
            ) : (
              icon
            )}
          </MDBox>
        )}
      </MDBox>
    );
}

export default BottomInput;