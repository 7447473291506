import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import background from "assets/images/newIcons/profileBackgound.svg";
import useDetails from "./hooks/useDetails";
import { setGlobalState } from "Global";
import { userAPI } from "api";
import { useState } from "react";
import AccountSettings from "./components/AccountSettings";
import RenderNotifications from "examples/Lists/NotificationsList/renderNotifications";
import { position } from "stylis";
import { useLocation } from "react-router-dom";

const Profile = () => {
  const styles = useStyles();
  const location = useLocation();
  const tab = location.state?.tab;
  const { palette, functions, typography } = useTheme();
  const { primary, white, text } = palette;
  const { pxToRem } = functions;

  const { userDetails, setUserDetials, agents } = useDetails();

  const statusTabs = [
    { name: "Busy", value: "Busy" },
    { name: "Available", value: "Available" },
    { name: "Out of Office", value: "OOO" },
  ];
  const tabs = ["Account Settings", "Notifications"];
  const [activeTab, setActiveTab] = useState(tab || tabs[0]);

  const handleStatusChange = (newValue) => {
    userAPI
      .updateUserDetails({ active_status: newValue })
      .then((res) => {
        setUserDetials({ ...userDetails, active_status: newValue });
        setGlobalState("error", {
          open: true,
          message: "Successfully updated user status.",
          type: "success",
        });
      })
      .catch((error) => {});
  };

  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={{ ...styles.iconHolder, fontSize: "1.2em" }}>
            <span class="material-symbols-outlined">account_box</span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Profile</MDBox>
        }
        rightContent={
          <MDBox color={text.disabled}>Resolved cases: {userDetails?.cases_count?.Resolved}</MDBox>
        }
      />

      <MDBox
        sx={{
          position: "relative",
          marginTop: "16px",
        }}
      >
        <MDBox sx={{ height: "209px", position: "relative", overflow: 'hidden' }}>
          <img
            src={background}
            style={{
              width: "100%",
              minHeight: "70%",
              transform: "rotateY(180deg)",
              position: "absolute",
              objectFit: "cover",
              zIndex: 0,
            }}
          />
          <MDBox
            sx={{
              height: "100%",
              position: "relative",
              zIndex: 1,
              borderRadius: "10px 10px 0px 0px",
              opacity: "0.4",
              background:
                "linear-gradient(90deg, rgba(0, 204, 155, 0.7) 0%, rgba(102, 108, 255, 0.7) 100%)",
            }}
          ></MDBox>
        </MDBox>
        <MDBox
          sx={{
            position: "relative",
            zIndex: 2,
            top: "-100px",
            padding: "0 2em",
            display: "flex",
            gap: "1.5em",
          }}
        >
          <MDBox
            sx={{
              width: "25%",
            }}
          >
            <MDBox
              sx={{
                background: white.main,
                borderRadius: "14px",
                padding: "25px 15px",
                boxShadow: "0px 0px 8px 1px #0000000D",
              }}
            >
              <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                <MDBox
                  sx={{
                    height: "100px",
                    width: "100px",
                    fontSize: typography.size["3xl"],
                    fontWeight: "bold",
                    background: primary.light,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                    position: "relative",
                    border: `1px solid ${palette.primary.main}`,
                    textTransform: "uppercase",
                  }}
                  title={userDetails?.name}
                >
                  {userDetails?.name[0]}
                  <MDBox sx={{ position: "absolute", right: 0, bottom: 0 }}>
                    <span
                      style={{
                        color: white.main,
                        fontSize: "1.3em",
                        backgroundColor: primary.main,
                        borderRadius: "100%",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      class="material-symbols-outlined"
                    >
                      photo_camera
                    </span>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                sx={{
                  fontSize: typography.size["md"],
                  textAlign: "center",
                  fontWeight: typography.fontWeightMedium,
                  marginTop: "15px",
                }}
                color={text.main}
              >
                {userDetails?.name}
              </MDBox>
              <MDBox
                sx={{ fontSize: typography.size["sm"], textAlign: "center" }}
                color={text.disabled}
              >
                {userDetails?.title}
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  marginTop: "15px",
                  flexDirection: "column",
                  backgroundColor: primary.light,
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                {statusTabs.map((tab) => (
                  <MDBox
                    sx={{
                      padding: "8px",
                      cursor: "pointer",
                      borderRadius: "10px",
                      textAlign: "center",
                      fontWeight: typography.fontWeightMedium,
                      backgroundColor:
                        tab.value === userDetails?.active_status
                          ? white.main
                          : "",
                    }}
                    color={
                      tab.value === userDetails?.active_status
                        ? primary.main
                        : text.main
                    }
                    onClick={() => handleStatusChange(tab.value)}
                  >
                    {tab.name}
                  </MDBox>
                ))}
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox
            sx={{
              borderRadius: "14px",
              background: white.main,
              padding: "15px 20px",
              width: "75%",
              boxShadow: "0px 0px 8px 1px #0000000D",
              // height: "540px",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                fontSize: typography.size["sm"],
                fontWeight: typography.fontWeightMedium,
                borderBottom: `1px solid ${palette.grey[300]}`,
                gap: "1em",
              }}
            >
              {tabs.map((tab, index) => (
                <MDBox
                  key={index}
                  sx={{
                    padding: pxToRem(8),
                    cursor: "pointer",
                    gap: "5px",
                    display: "flex",
                    paddingBottom: pxToRem(5),
                    borderBottom:
                      activeTab === tab
                        ? `2px solid ${palette.primary.main}`
                        : ``,
                  }}
                  color={palette.text.header}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </MDBox>
              ))}
            </MDBox>
            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
              {activeTab === tabs[0] ? (
                <AccountSettings
                  userDetails={userDetails}
                  setUserDetials={setUserDetials}
                  agents={agents}
                />
              ) : (
                <MDBox sx={{ position: "relative", minHeight: '280px', maxHeight: 'calc(var(--h-100vh) - 300px)', overflowY:'auto', width: '100%', maxWidth: "750px"  }} >
                  <RenderNotifications/>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default Profile;
