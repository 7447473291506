import { caseAPI } from "api";
import { setGlobalState } from "Global";

export const messageService = {
  getCaseTransferHistory: (caseId) =>
    caseAPI
      .getCaseTransferHistory({ case_id: caseId })
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
      })
      .catch((error) => {
        throw error;
      }),

  getNotificationHistory: (caseId) =>
    caseAPI
      .getNotificationHistory({ case_id: caseId })
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
      })
      .catch((error) => {
        throw error;
      }),

  sendMessage: (data) =>
    caseAPI
      .sendMail(data)
      .then((res) => {
        setGlobalState("error", {
          open: true,
          message: "Successfully sent the notification",
          type: "success",
        });
        return res;
      })
      .catch((error) => {
        if ([422].includes(error.response?.status)) {
          throw error;
        }
      }),

  getAgents: () =>
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
      })
      .catch((error) => {}),
};
