import { useTheme } from '@emotion/react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { InputBase } from '@mui/material';
import MDBox from 'components/MDBox';

const SearchBar = ({ placeHolder, handleKeyUp, inputRef }) => {
    const { palette, functions } = useTheme();
    const { primary, text } = palette;
    const { pxToRem } = functions;

    return (
        <MDBox sx={{
            display: 'flex',
            gap: '5px',
            padding: '2px 10px',
            alignItems: 'center',
            border: '1px solid ' + primary.main,
            borderRadius: '100px',
        }}>
            <MDBox
                sx={{
                    fontSize: pxToRem(22),
                    color: primary.main,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <SearchOutlinedIcon />
            </MDBox>
            <InputBase
                sx={{
                    fontSize: pxToRem(13),
                    color: text.disabled,
                    '& .MuiInputBase-input::placeholder': {
                        color: text.disabled,
                        opacity: 1
                    },
                }}
                inputRef={inputRef}
                placeholder={placeHolder}
                onKeyUp={handleKeyUp}
            />
        </MDBox>
    );
}

export default SearchBar;