import { useTheme } from '@emotion/react';
import MDBox from 'components/MDBox';

const ContentBox = ({ children, height, contentBoxRef, sx }) => {

    const { palette } = useTheme();
    const { white } = palette;

    return (
        <MDBox
            ref={contentBoxRef}
            sx={{
                overflowY: 'auto',
                scrollBehavior: 'smooth',
                position: 'relative',
                height: height || "calc(100% - 60px)",
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: white.main,
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#D3D3D3',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#D3D3D3',
                },
                ...sx,
            }}
        >
            {children}
        </MDBox>
    );
}

export default ContentBox;