import { analyticsAPI, AnalyticsApi } from "api"
import { useEffect, useState } from "react"
import { analyticsService } from "../service/analyticsService"

const initialFilters = {
    "case_state": [],
    "search": "",
    "age_bucket": [],
    "category": [],
    "sku": [],
    "reason_codes": [],
    "workflow_id": [],
    "customer_name": [],
    "claim_type": [],
    "assigned_agent": [],
    "claim_status": [],
    "from_date": "",
    "to_date": "",
    "limit": 50,
    "offset": 0
  }

export const useAnalytics = () => {
    const [caseStateCount, setCaseStateCount] = useState(null)
    const [filterOptions, setFilterOptions] = useState(null)
    const [filters, setFilters] = useState(null)
    useEffect(() => {
        AnalyticsApi.getCaseStateCounts(initialFilters).then((res) => setCaseStateCount(res.data))
        getFilterValues()
    }, [])
    
    const getFilterValues = () => {
      analyticsAPI.getSummaryFilterValues().then((res) => {
        let tempData = res.data;
        let tempFilters = {}
        Object.keys(tempData).forEach((filter) => {
          if (typeof tempData[filter] == typeof []) {
            tempFilters[filter]= [] 
          } else {
            delete tempData[filter];
          }
        });
        setFilterOptions((prevFilterOptions) => {
          return { ...prevFilterOptions, ...tempData };
        });
        setFilters(tempFilters)
      });
    }

    return {
        caseStateCount,

        filterOptions,
        filters,
    }
}