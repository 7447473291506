import { useTheme } from "@emotion/react";
import { FormControlLabel, Icon, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import { color } from "d3";
import Chart from "examples/chart/Chart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import { useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useGridLayout } from "./hooks/useGridLayout";
import ChartHolder from "./components/ChartHolder";
import InfoCard from "./components/InfoCard";
import ChartHolderPopup from "./components/ChartHolderPopup";
import { useAnalytics } from "./hooks/useAnalytics";
import { analyticsService } from "./service/analyticsService";
import { calculateCombinedPercentage, downloadImage, getDateRange } from "./utils";
import DateRangePicker from "./components/DateRangePicker";
import dayjs from "dayjs";
const ResponsiveGridLayout = WidthProvider(Responsive);

const initialLayout = [
  { i: "claimsbyTime", x: 0, y: 0, w: 8, h: 6 },
  { i: "totalClaims", x: 8, y: 0, w: 2, h: 3 },
  { i: "new", x: 10, y: 0, w: 2, h: 3 },
  { i: "inProgress", x: 8, y: 3, w: 2, h: 3 },
  { i: "resolved", x: 10, y: 3, w: 2, h: 3 },

  { i: "claimStatus", x: 0, y: 6, w: 4, h: 5 },
  { i: "averageProcessingTime", x: 4, y: 6, w: 8, h: 5 },
  
  { i: "agentLeaderboard", x: 0, y: 11, w: 6, h: 7 },
  { i: "invalidClaimsByCustomer", x: 6, y: 11, w: 6, h: 7 },
  
  { i: "claimsByReasonCodes", x: 0, y: 18, w: 4, h: 6 },
  { i: "claimByAgeBucket", x: 4, y: 18, w: 4, h: 6 },
  { i: "claimCostByCustomer", x: 8, y: 18, w: 4, h: 6 },
  
  { i: "claimsByRetailer", x: 0, y: 24, w: 8, h: 6 },
  { i: "resolutionRatebyTime", x: 8, y: 24, w: 4, h: 6 },
];

const Analytics = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const styles = useStyles();
  // const { layout, onLayoutChange } = useGridLayout(initialLayout);
  const { caseStateCount, filterOptions, filters } = useAnalytics();

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const [globalDateRange, setGlobalDateRange] = useState({});
  const [enableDrag, setEnableDrag] = useState(false)

  const cardStyle = {
    borderRadius: "10px",
    backgroundColor: white.main,
    boxShadow: "0px 0px 8px 1px #0000000D",
  };

  const handleSelectTab = (tab) => {
    if (tab === "Clear") {
      setActiveTab("");
      setGlobalDateRange({})
    } else {
      setActiveTab(tab);
      setGlobalDateRange(getDateRange(tab))
    }
  };
  const handleDateRangeChange = (type, date) => {
    const tDate = new Date(date)
    setActiveTab("Custom")
    if (type === "from"){
      setGlobalDateRange(pre => ({...pre, from: tDate?.toISOString()}))
    }else{
      setGlobalDateRange(pre => ({...pre, to: tDate?.toISOString()}))
    }
  }
  const [ActivePopupComponent, setActivePopupComponent] = useState(null);
  const imageRef = useRef(null);

  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={{ ...styles.iconHolder, fontSize: "1.2em" }}>
            <span class="material-symbols-outlined">bar_chart_4_bars</span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Analytics</MDBox>
        }
        rightContent={
          <OutlinedButton
            name={"Download"}
            onClick={() => downloadImage(imageRef.current, "Analytics")}
            icon={
              <Icon className={"font-unset"} fontSize="1em">
                download
              </Icon>
            }
          />
        }
      />

      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1.5em 0",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {tabs.map((tab) => (
            <MDBox
              sx={{
                padding: "0.5em 1em",
                textAlign: "center",
                borderRadius: "1em",
                cursor: "pointer",
                backgroundColor: activeTab === tab ? primary.main : white.main,
              }}
              color={activeTab === tab ? white.main : text.disabled}
              onClick={() => handleSelectTab(tab)}
            >
              {tab}
            </MDBox>
          ))}
          <FormControlLabel
            sx={{ "& .MuiTypography-root": { color: text.disabled } }}
            control={
              <Switch
                onChange={(e, value) => {setEnableDrag(value)}}
                value={enableDrag}
                sx={{
                  "& .Mui-checked .MuiSwitch-thumb": {
                    borderColor: primary.main + " !important"
                  },
                  "& .Mui-checked+.MuiSwitch-track": {
                    borderColor: primary.main + " !important",
                    backgroundColor: primary.main +" !important",
                  },
                }}
              />
            }
            label="Customize Layout"
          />
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1em",
          }}
        >
          {/* <span
            class="material-symbols-outlined"
            style={{
              color: primary.main,
              backgroundColor: primary.light,
              borderRadius: "100%",
            }}
          >
            chevron_left
          </span>

          <MDBox color={text.main}>Oct ‘23 – Sep ‘24</MDBox>
          <span
            class="material-symbols-outlined"
            style={{
              color: primary.main,
              backgroundColor: primary.light,
              borderRadius: "100%",
            }}
          >
            chevron_right
          </span> */}
          <DateRangePicker
            from={globalDateRange?.from}
            to={globalDateRange?.to}
            onChangeFrom={(date) => handleDateRangeChange("from", date)}
            onChangeTo={(date) => handleDateRangeChange("to", date)}
          />
        </MDBox>
      </MDBox>
      <MDBox ref={imageRef}>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: initialLayout }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
          rowHeight={50}
          margin={[15, 15]}
          // onLayoutChange={onLayoutChange}
        >
          <MDBox sx={cardStyle} key="claimsbyTime">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"No. of Claims by Time"}
              setActivePopupComponent={setActivePopupComponent}
              id={"claimsbyTime"}
              getData={analyticsService.getClaimsByTime}
              // showFilter={false}
              chartProps={{
                name: "month",
                grid: { vertical: false },
                type: "area",
                graph: {
                  total: { fillOpacity: 0.3, type: "monotone", strokeWidth: 2 },
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="totalClaims">
            <InfoCard
              enableDrag={enableDrag}
              title={"Total Claims"}
              iconName={"folder"}
              iconColor={"#676DFF"}
              count={
                caseStateCount?.[0]?.total_cases +
                caseStateCount?.[1]?.total_cases +
                caseStateCount?.[2]?.total_cases
              }
              totalClaimAmount={
                caseStateCount?.[0]?.total_claim_amount +
                caseStateCount?.[1]?.total_claim_amount +
                caseStateCount?.[2]?.total_claim_amount
              }
              pastWeekPercentincrease={calculateCombinedPercentage(
                caseStateCount?.[0]?.percentage,
                caseStateCount?.[1]?.percentage,
                caseStateCount?.[2]?.percentage
              )}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="new">
            <InfoCard
              enableDrag={enableDrag}
              title={"New"}
              iconName={"rocket_launch"}
              iconColor={"#EF5350"}
              count={caseStateCount?.[1]?.total_cases}
              totalClaimAmount={caseStateCount?.[1]?.total_claim_amount}
              pastWeekPercentincrease={caseStateCount?.[1]?.percentage}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="inProgress">
            <InfoCard
              enableDrag={enableDrag}
              title={"In-Progress"}
              iconName={"av_timer"}
              iconColor={"#EF6C00"}
              count={caseStateCount?.[0]?.total_cases}
              totalClaimAmount={caseStateCount?.[0]?.total_claim_amount}
              pastWeekPercentincrease={caseStateCount?.[0]?.percentage}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="resolved">
            <InfoCard
              enableDrag={enableDrag}
              title={"Resolved"}
              iconName={"assignment_turned_in"}
              iconColor={"#33C23A"}
              count={caseStateCount?.[2]?.total_cases}
              totalClaimAmount={caseStateCount?.[2]?.total_claim_amount}
              pastWeekPercentincrease={caseStateCount?.[2]?.percentage}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="claimStatus">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              setActivePopupComponent={setActivePopupComponent}
              title={"Claim Status"}
              getData={analyticsService.getCaseStateCounts}
              id={"claimStatus"}
              // showFilter={false}
              chartProps={{
                name: "claim_type",
                type: "pie",
                dataKey: "total_cases",
                graph: {
                  innerRadius: 30,
                  outerRadius: 70,
                  paddingAngle: 3,
                  showPercentage: false,
                },
                legend: {
                  show: true,
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="averageProcessingTime">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              setActivePopupComponent={setActivePopupComponent}
              title={"Claims By Current Status"}
              getData={analyticsService.getClaimsByCurrentStatus}
              id={"averageProcessingTime"}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                name: "current_status",
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="resolutionRatebyTime">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"No. of Claims By Workflow"}
              setActivePopupComponent={setActivePopupComponent}
              chartProps={{
                name: "workflow_name",
                type: "bar",
                grid: { vertical: false },
              }}
              id={"resolutionRatebyTime"}
              getData={analyticsService.getClaimsByWorkflow}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="agentLeaderboard">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Agent Leaderboard"}
              setActivePopupComponent={setActivePopupComponent}
              getData={analyticsService.getAgentLeaderboard}
              id={"agentLeaderboard"}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="invalidClaimsByCustomer">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"No.of Invalid Claims by Customer"}
              setActivePopupComponent={setActivePopupComponent}
              id={"invalidClaimsByCustomer"}
              getData={analyticsService.getInvalidClaimsByCustomer}
              chartProps={{
                type: "scatter",
                grid: { vertical: false },
                xAxis: { type: "category", dataKey: "customer", hide: true },
                yAxis: { dataKey: "count" },
                zAxis: { dataKey: "amount" },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="claimsByReasonCodes">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"No.of Claims by Reason Codes"}
              setActivePopupComponent={setActivePopupComponent}
              id={"claimsByReasonCodes"}
              getData={analyticsService.getClaimCountByReasonCodes}
              chartProps={{
                type: "pie",
                grid: { vertical: false },
                graph: {
                  innerRadius: 30,
                  outerRadius: 70,
                  paddingAngle: 5,
                  showPercentage: false,
                },
                legend: {
                  show: true,
                },
                name: "reason_codes",
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="claimByAgeBucket">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              id={"claimByAgeBucket"}
              filterOptions={filterOptions}
              handleFilterChange={() => {}}
              title={"No.of Claims by Age Bucket"}
              setActivePopupComponent={setActivePopupComponent}
              getData={analyticsService.getClaimsCountByAgeBucket}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                name: "age_bucket",
                variant: "horizontal",
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="claimCostByCustomer">
            <ChartHolder
              enableDrag={enableDrag}
              id={"claimCostByCustomer"}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Claim amount by customer"}
              setActivePopupComponent={setActivePopupComponent}
              getData={analyticsService.getClaimCostByCustomer}
              chartProps={{
                type: "pie",
                grid: { vertical: false },
                graph: {
                  outerRadius: 100,
                  showPercentage: false,
                  paddingAngle: 0,
                },
                legend: {
                  show: false,
                },
                name: "customer_name",
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="claimsByRetailer">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"No.of Claims By Retailer"}
              setActivePopupComponent={setActivePopupComponent}
              id={"claimsByRetailer"}
              getData={analyticsService.getClaimsByRetailer}
              chartProps={{
                type: "scatter",
                grid: { vertical: false },
                xAxis: { type: "category", dataKey: "retailer", hide: true },
                yAxis: { dataKey: "count" },
                zAxis: { dataKey: "amount" },
              }}
            ></ChartHolder>
          </MDBox>
        </ResponsiveGridLayout>
      </MDBox>
      {ActivePopupComponent}
    </DashboardLayout>
  );
};

export default Analytics;
