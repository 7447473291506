// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { setGlobalState } from "Global";
import DataTable from "examples/Tables/DataTable";
// Data
import { useTheme } from "@emotion/react";
import { TabContext, TabPanel } from "@mui/lab";
import { useGlobalState } from "Global";
import { agentAPI, AIAgentAPI, analyticsAPI } from "api";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import TableBanner from "layouts/tableDisplay/tableBanner";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useClaimsData from "./hooks/useClaimsData";
import useClaimsGroupedData from "./hooks/useClaimsGroupedData";

const initialFilters = {
  case_state: ["In-Progress", "New"],
  age_bucket: [],
  category: [],
  sku: [],
  reason_codes: [],
  workflow_id: [],
  customer_name: [],
  claim_type: [],
  assigned_agent: [],
  claim_status: [],
  from_date: "",
  to_date: "",
  search: "",
  limit: 50,
  offset: 0,
};
const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];
const initialGroupedFilters = {
  ReferenceKey: [],
  InvoiceNumber: [],
  CustomerCode: [],
  ReasonCodes: [],
  Customer: [],
  limit: 50,
  offset: 0,
};

function Tables(props) {
  const [userDetails] = useGlobalState("userDetails");
  const [state, setState] = useState({ caseType: "default" });
  const [agent, setAgent] = useState({});
  const location = useLocation();
  const [user, setUser] = useState("User");
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState("default");
  const [selectedGroup, setSelectedGroup] = useState({
    offset: 0,
    limit: 50,
    details: null,
  });
  const [showSendMail, setShowSendMail] = useState(false);
  const [groupedCasesList, setGroupedCasesList] = useState([]);

  const navigation = useNavigate();
  const [caseList] = useGlobalState("caseList");

  const [filters, setFilters] = useState(caseList.filters || initialFilters);

  const [groupedFilters, setGroupedFilters] = useState(
    caseList.groupedFilters || initialGroupedFilters
  );

  const handlePerformAction = (caseId, action) => {
    setIsLoading(true);
    AIAgentAPI.performCaseAction(
      { case_id: caseId, data: JSON.stringify(action) },
      {}
    )
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setFilters({
            ...filters,
            substitute: caseId + JSON.stringify(action),
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // const {
  //   columns: recommendedColumns,
  //   rows: recommendedRows,
  //   pages: recommededPageInfo,
  // } = recommendedCases({
  //   setIsLoading: setIsLoading,
  //   filters: filters,
  //   handlePerformAction: handlePerformAction,
  // });

  const [filterOptions, setFilterOptions] = useState({});
  const [applyFilterFlag, setApplyFilterFlag] = useState(false);
  const [newClaimsCount, setNewClaimsCount] = useState(0);

  useEffect(() => {
    function countClaimsInLast7Days(claims) {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      const todayStr = today.toISOString().split("T")[0];
      const sevenDaysAgoStr = sevenDaysAgo.toISOString().split("T")[0];

      let count = 0;

      claims.forEach((claim) => {
        const date = new Date(claim["Posting Date"]);
        const dateStrFormatted = date.toISOString().split("T")[0];
        if (
          dateStrFormatted >= sevenDaysAgoStr &&
          dateStrFormatted <= todayStr
        ) {
          count++;
        }
      });

      return count;
    }
    const adminCases = user === "All" ? "Admin" : "";
    const dynamicFunction = agentAPI[`getAllCases`];
    dynamicFunction(filters, filters)
      .then((res) => {
        setNewClaimsCount(countClaimsInLast7Days(res?.data?.data));
      })
      .catch((error) => {});
  }, [user]);

  const {
    columns: pColumns,
    rows: pRows,
    pages: pageInfo,
  } = useClaimsData({
    setIsLoading: setIsLoading,
    filters: filters,
    caseListType: tabValue,
    applyFilterFlag: applyFilterFlag,
  });

  // const {
  //   columns: groupedColumns,
  //   rows: groupedRows,
  //   pages: groupedPageInfo,
  // } = useClaimsGroupedData({
  //   setIsLoading: setIsLoading,
  //   filters: groupedFilters,
  //   caseListType: tabValue,
  // });

  const getFilterValues = () => {
    analyticsAPI.getSummaryFilterValues().then((res) => {
      let tempData = res.data;
      // let tempFilters = {}
      Object.keys(tempData).forEach((filter) => {
        if (typeof tempData[filter] == typeof []) {
          tempData[filter]?.push({ name: "All", _id: "All" });
          // if (filter === 'case_state') {tempFilters[filter] = ["In-Progress", "New"]}
          // else { tempFilters[filter]= [] }
        } else {
          delete tempData[filter];
        }
      });
      setFilterOptions((prevFilterOptions) => {
        return { ...prevFilterOptions, ...tempData };
      });
      // setFilters(tempFilters)
    });
    analyticsAPI.getGroupedFilterValues().then((res) => {
      let tempData = res.data;
      Object.keys(tempData).forEach((filter) => {
        tempData[filter].push({ name: "All", _id: "All" });
      });
      setFilterOptions((prevFilterOptions) => {
        return { ...prevFilterOptions, ...res.data };
      });
    });
  };

  const getGroupedCasesList = () => {
    agentAPI
      .getGroupedCasesList(selectedGroup, selectedGroup.details)
      .then((res) => {
        if (res.status == 200) {
          setGroupedCasesList(res.data.data);
        }
      });
  };

  useEffect(() => {
    if (selectedGroup.details) {
      getGroupedCasesList();
    }
  }, [selectedGroup.details]);

  useEffect(() => {
    if (userDetails?.permission === "superuser") {
      setUser("All");
    } else {
      setUser("User");
    }
    getFilterValues();
  }, [userDetails]);

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleRowClick = (row) => {
    const columnId = pColumns.filter((data) => data.type === "id")[0]?.accessor;
    const caseId = row.original[columnId].props.data;
    if (caseId) navigation(`/cases/${caseId}`);
  };

  const handleCasesListRowClick = (event) => {
    const case_id = event.row.values.case_id;
    window.open(`/cases/${case_id}`, "_blank");
  };

  const handleGroupedRowClick = (event) => {
    const { count, ...groupDetails } = event.row.values;
    setSelectedGroup({ ...selectedGroup, details: { ...groupDetails } });
  };

  const handleFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
            ? value._id
            : "";
      }
    }

    if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = { ...filters, [event.target.name]: event.target.value };

    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }
    setFilters(tempFilters);
    setGlobalState("caseList", {
      ...caseList,
      filters: tempFilters,
      caseListType: tabValue,
    });
  };

  const handleGroupedFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
            ? value._id
            : "";
      }
    }

    if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = {
      ...groupedFilters,
      [event.target.name]: event.target.value,
    };
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }
    setGroupedFilters(tempFilters);
    setGlobalState("caseList", {
      ...caseList,
      groupedFilters: tempFilters,
      caseListType: tabValue,
    });
  };

  function removeOrAddByValue(value) {
    const arr = filters?.["case_state"];
    const index = arr.indexOf(value);

    if (index !== -1) {
      arr.splice(index, 1)[0];
      return arr;
    }
    arr.push(value);
    return arr;
  }

  const handleSetActiveTab = (value, filterType) => {
    handleFilterChange({
      target: { value: value, name: filterType },
    });
    setApplyFilterFlag(Math.random());
  };

  const handleDownloadBulk = (filters, data, email = false) => {
    setIsLoading(true);
    agentAPI
      .downloadCasesAdmin({ ...filters, email: email }, filters)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          if (email == false) {
            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = "CaseList.xlsx"; // Set the desired file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } else {
            setGlobalState("error", {
              open: true,
              message: "The data has been sent. Please check your email.",
              type: "success",
            });
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if ([453].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message:
              "Row Count Exceeded, sending you a mail with attached file",
            type: "error",
            code: error.response.status,
          });
          handleDownloadBulk(filters, data, true);
        } else if ([454].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message:
              "File too large to send, please contact the admin team to get file.",
            type: "error",
            code: error.response.status,
          });
        } else if (![422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Error while fetching data, please try after sometime.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const handleSearchChange = (text, type) => {
    let dynamicFunction = type == "Filters" ? setFilters : setGroupedFilters;
    dynamicFunction((prevValue) => {
      return { ...prevValue, search: text };
    });
    setApplyFilterFlag(Math.random());
  };

  //
  const tabs = ["All", "New", "In-Progress", "Resolved"];
  const userTabs = ["All", "Assigned To Me"];

  const { palette, functions, typography } = useTheme();
  const { pxToRem } = functions;

  const [activeTab, setActiveTab] = useState(tabs[0]);
  //
  const iconStyleInner = {
    color: palette.text.main,
    fontSize: "1.3em",
    cursor: "pointer",
  };
  const iconStyleOuter = {
    color: palette.text.disabled,
    fontSize: "1.3em",
    cursor: "pointer",
  };

  const styles = useStyles();

  return (
    <DashboardLayout>
      <>
        <ClaimNavbar
          icon={
            <MDBox sx={styles.iconHolder}>
              <span
                style={{ fontSize: "1.3em" }}
                class="material-symbols-outlined"
              >
                folder_open
              </span>
            </MDBox>
          }
          leftContent={
            <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Claims</MDBox>
          }
          rightContent={
            <MDBox
              sx={{
                textAlign: "right",
                fontWeight: "600",
              }}
            >
              <MDBox>New Claims Posted in last 7 days: {newClaimsCount}</MDBox>
            </MDBox>
          }
        />

        {filters && (
          <MDBox
            sx={{
              display: "flex",
              overflowX: "auto",
              justifyContent: "space-between",
              marginTop: pxToRem(15),
              fontSize: typography.size["sm"],
              fontWeight: typography.fontWeightMedium,
              borderBottom: `1px solid ${palette.grey[300]}`,
            }}
            color={palette.text.main}
          >
            <MDBox sx={{ display: "flex" }}>
              {tabs.map((tab, index) => (
                <MDBox
                  key={index}
                  sx={{
                    textTransform: "uppercase",
                    padding: pxToRem(8),
                    cursor: "pointer",
                    gap: "5px",
                    paddingBottom: pxToRem(5),
                    borderBottom:
                      filters?.["case_state"]?.includes(tab) ||
                      (filters?.["case_state"].length === 0 && tab === tabs[0])
                        ? `2px solid ${palette.primary.main}`
                        : ``,
                  }}
                  color={
                    filters?.["case_state"].includes(tab) ||
                    (filters?.["case_state"].length === 0 && tab === tabs[0])
                      ? palette.primary.main
                      : palette.text.disabled
                  }
                  onClick={() =>
                    handleSetActiveTab(removeOrAddByValue(tab), "case_state")
                  }
                >
                  {tab}
                </MDBox>
              ))}
            </MDBox>

            {userDetails?.permission !== "3PU" && <MDBox sx={{ display: "flex" }}>
              {userTabs.map((tab) => (
                <MDBox
                  sx={{
                    textTransform: "uppercase",
                    padding: pxToRem(8),
                    cursor: "pointer",
                    gap: "5px",
                    paddingBottom: pxToRem(5),
                    borderBottom:
                      filters?.["assigned_agent"]?.length === 1 &&
                      filters?.["assigned_agent"]?.[0] === userDetails?.email &&
                      tab !== userTabs[0]
                        ? `2px solid ${palette.primary.main}`
                        : (filters?.["assigned_agent"]?.length > 1 ||
                              filters?.["assigned_agent"]?.length === 0) &&
                            tab === userTabs[0]
                          ? `2px solid ${palette.primary.main}`
                          : ``,
                  }}
                  color={
                    filters?.["assigned_agent"]?.length === 1 &&
                    filters?.["assigned_agent"]?.[0] === userDetails?.email &&
                    tab !== userTabs[0]
                      ? palette.primary.main
                      : (filters?.["assigned_agent"]?.length > 1 ||
                            filters?.["assigned_agent"]?.length === 0) &&
                          tab === userTabs[0]
                        ? palette.primary.main
                        : palette.text.disabled
                  }
                  onClick={() =>
                    handleSetActiveTab(
                      tab === userTabs[1] ? [userDetails?.email] : [],
                      "assigned_agent"
                    )
                  }
                >
                  {tab}
                </MDBox>
              ))}
            </MDBox>}
          </MDBox>
        )}

        <MDBox pt={2} pb={1}>
          <Grid container spacing={6}>
            <Grid
              item
              xs={12}
              style={{
                paddingTop: "0px",
                marginTop: "48px",
              }}
            >
              <Card>
                <TabContext value={tabValue}>
                  <TabPanel sx={{ padding: "0" }} value={"default"}>
                    {" "}
                    <MDBox>
                      <DataTable
                        table={{ columns: pColumns, rows: pRows }}
                        isSorted={true}
                        entriesPerPage={{ defaultValue: filters?.limit }}
                        showTotalEntries={true}
                        downloadable={{ type: "bulk" }}
                        handleDownloadBulk={handleDownloadBulk}
                        noEndBorder
                        canSearch={true}
                        rowClick={handleRowClick}
                        filters={filters}
                        filterOptions={filterOptions}
                        handleFilterChange={handleFilterChange}
                        customPagination={true}
                        ignoreFilterList={(userDetails?.permission === "3PU") ? [...ignoreFilterList, "assigned_agent"] : ignoreFilterList}
                        pageInfo={pageInfo}
                        caseListType={"default"}
                        isLoading={isLoading}
                        handleSearchChange={(text) =>
                          handleSearchChange(text, "Filters")
                        }
                        setApplyFilterFlag={setApplyFilterFlag}
                        tableHeight={"calc(var(--h-100vh) - 238px)"}
                      />
                    </MDBox>
                  </TabPanel>
                  {/* <TabPanel
                    sx={{ padding: "0", marginTop: "0.5rem" }}
                    value={"grouped"}
                  >
                    {" "}
                    <MDBox>
                      <DataTable
                        table={{ columns: groupedColumns, rows: groupedRows }}
                        isSorted={true}
                        entriesPerPage={true}
                        showTotalEntries={true}
                        downloadable={{ type: "bulk" }}
                        handleDownloadBulk={handleDownloadBulk}
                        noEndBorder
                        canSearch={true}
                        rowClick={handleGroupedRowClick}
                        filters={groupedFilters}
                        filterOptions={filterOptions}
                        handleFilterChange={handleGroupedFilterChange}
                        customPagination={true}
                        ignoreFilterList={ignoreFilterList}
                        pageInfo={groupedPageInfo}
                        caseListType={"grouped"}
                        isLoading={isLoading}
                        handleSearchChange={(text) =>
                          handleSearchChange(text, "GroupedFilters")
                        }
                        // columnFilter={true}
                      />
                    </MDBox>
                  </TabPanel> */}
                </TabContext>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {selectedGroup.details && groupedCasesList.length > 0 && (
          <TableBanner
            open={true}
            handleClose={() => {
              setSelectedGroup({ ...selectedGroup, details: null });
            }}
            data={groupedCasesList}
            rowClick={handleCasesListRowClick}
          />
        )}
      </>
    </DashboardLayout>
  );
}

export default Tables;
