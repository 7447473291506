import { useTheme } from "@emotion/react"
import MDChip from "components/MDChip"

const CaseStateChip = ({state, style}) => {
    const { palette, functions, typography } = useTheme();
    const { primary, success, error, warning } = palette;
    const { pxToRem } = functions;

    const getStateColor = () => {
        switch (state?.toUpperCase()){
            case 'IN-PROGRESS':
                return warning.main;
            case 'NEW':
                return error.main;
            case 'RESOLVED':
                return success.main;
            default:
                return primary.main
        }

    }
    return (
        <MDChip sx={{
            backgroundColor:primary.light,
            color: getStateColor,
            fontSize: typography.fontSizeXS,
            fontWeight: typography.fontWeightBold,
            padding: `${pxToRem(2)} ${pxToRem(8)}`,
            height: pxToRem(28),
            ...style,
        }} label={state?.toUpperCase()} />
    )
}

export default CaseStateChip