import DateFnsUtils from "@date-io/date-fns";
import styled from "@emotion/styled";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const StyledDatePicker = styled(KeyboardDatePicker)`
  /* Scoped styles for the date picker */
  & .MuiInputBase-root {
    border-radius: 4px;
  }
  & .MuiInputAdornment-root {
    margin-right: 8px;
  }
`;

export default function DatePicker({
  name,
  label,
  value,
  onChange,
  disabled,
  ...props
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        disabled={disabled}
        inputVariant="outlined"
        label={label}
        format="MM/dd/yyyy"
        name={name}
        value={value}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
