import { useTheme } from "@emotion/react";
import Dropdown from "components/controls/Dropdown";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import { useEffect, useRef, useState } from "react";
import { convertKeyToName } from "utils/conversion";
import { getDateRange } from "../utils";
import DateRangePicker from "./DateRangePicker";

const ChartHolderPopup = ({
  title,
  handleFilterChange,
  chart,
  handleClosePopup,
  filters,
  ignoreFilterList,
  filterOptions,
  handleApplyFiter,
  showFilter,
  handleSelectTab,
  tabs,
  activeTab,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [height, setHeight] = useState(10);
  const divRef = useRef(null);
  useEffect(() => {
    setHeight(divRef?.current?.offsetHeight + 20);
  }, [divRef?.current]);

  return (
    <MDBox
      sx={{
        height: "var(--h-100vh)",
        width: "var(--w-100vw)",
        backdropFilter: "blur(3px)",
        position: "fixed",
        zIndex: "9999",
        top: "0",
        left: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleClosePopup}
    >
      <MDBox
        sx={{
          width: "calc(100% - 100px)",
          height: "calc(100% - 50px)",
          background: white.main,
          borderRadius: "10px",
          padding: "1.3em",
          overflow: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <MDBox ref={divRef}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <MDBox sx={{ fontSize: typography.size["lg"] }} color={text.main}>
              {title}
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3em",
              }}
            >
                <>
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {tabs.map((tab) => (
                      <MDBox
                        sx={{
                          padding: "0.5em 1em",
                          textAlign: "center",
                          borderRadius: "1em",
                          cursor: "pointer",
                          backgroundColor:
                            activeTab === tab ? primary.main : "#F7F7F7",
                        }}
                        color={activeTab === tab ? white.main : text.disabled}
                        onClick={() => handleSelectTab(tab, getDateRange(tab))}
                      >
                        {tab}
                      </MDBox>
                    ))}
                  </MDBox>
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* <span
                  class="material-symbols-outlined"
                  style={{
                    color: primary.main,
                    backgroundColor: primary.light,
                    borderRadius: "100%",
                  }}
                >
                  chevron_left
                </span>

                <MDBox mt={"3px"} color={text.disabled}>
                  Oct ‘23 – Sep ‘24
                </MDBox>
                <span
                  class="material-symbols-outlined"
                  style={{
                    color: primary.main,
                    backgroundColor: primary.light,
                    borderRadius: "100%",
                  }}
                >
                  chevron_right
                </span> */}
                    <DateRangePicker
                      from={filters?.from_date}
                      to={filters?.to_date}
                      onChangeFrom={(date) =>
                        handleSelectTab("custom", {from: date, to: filters?.to_date})
                      }
                      onChangeTo={(date) => handleSelectTab("custom", {to: date, from: filters?.from_date})}
                    />
                  </MDBox>
                </>
              <MDBox
                color={primary.main}
                onClick={handleClosePopup}
                sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
              >
                x Close
              </MDBox>
            </MDBox>
          </MDBox>

          {filters && showFilter && (
            <MDBox
              my={2}
              mx={1}
              sx={{
                display: "flex",
              }}
            >
              <MDBox
                sx={{
                  width: "70%",
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(filters).map((filterName, index) => {
                  if (!ignoreFilterList?.includes(filterName))
                    return (
                      <MDBox
                        sx={{
                          width: "calc(33% - 20px)",
                        }}
                      >
                        {filterOptions[filterName]?.length > 0 && (
                          <Dropdown
                            size={"small"}
                            value={filters[filterName]}
                            onChange={(event, newValue) => {
                              handleFilterChange({
                                target: {
                                  name: filterName,
                                  value: newValue,
                                },
                              });
                            }}
                            options={filterOptions[filterName]}
                            getOptionLabel={(option) => {
                              return option?.name ? option.name : option;
                            }}
                            placeholder={
                              filters[filterName] != "" ? "" : "Select"
                            }
                            label={convertKeyToName(filterName)}
                          />
                        )}
                      </MDBox>
                    );
                })}
              </MDBox>

              <MDBox
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <MDChip
                  sx={{
                    backgroundColor: secondary.main,
                    padding: "5px 40px",
                    color: white.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "14px",
                    height: "40px",
                    borderRadius: "100px",
                  }}
                  label={"Apply All Filters"}
                  onClick={handleApplyFiter}
                />
              </MDBox>
            </MDBox>
          )}
        </MDBox>

        <MDBox
          sx={{
            height: `calc(100% - ${height}px)`,
            marginRight: "1.5em",
            position: "relative",
          }}
        >
          {chart}
        </MDBox>
      </MDBox>
      <style jsx>{`
        .MuiPopper-root, .MuiDialog-root {
          z-index: 99999999 !important;
        }
      `}</style>
    </MDBox>
  );
};

export default ChartHolderPopup;
