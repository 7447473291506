import { useEffect, useMemo } from 'react';
import {
  useReactFlow,
  useNodesInitialized,
  useStore,
} from 'reactflow';

import { getSourceHandlePosition, getTargetHandlePosition } from '../utils';
import layoutAlgorithms from '../algorithms';

function useAutoLayout(options) {
    // Memoize options to avoid unnecessary updates
  const memoizedOptions = useMemo(() => options, [options.direction, options.algorithm]);
  const { setNodes, setEdges, fitView } = useReactFlow();
  const nodesInitialized = useNodesInitialized();
  const elements = useStore(
    (state) => ({
      nodeMap: state.nodeInternals,
      edgeMap: state.edges.reduce(
        (acc, edge) => acc.set(edge.id, edge),
        new Map()
      ),
    }),
    compareElements
  );

  useEffect(() => {
    if (!nodesInitialized || elements.nodeMap.size === 0) {
      return;
    }

    const runLayout = async () => {
      const layoutAlgorithm = layoutAlgorithms[memoizedOptions.algorithm];
      const nodes = [...elements.nodeMap.values()];
      const edges = [...elements.edgeMap.values()];

      const { nodes: nextNodes, edges: nextEdges } = await layoutAlgorithm(
        nodes,
        edges,
        memoizedOptions
      );

      for (const node of nextNodes) {
        node.style = { ...node.style, opacity: 1 };
        node.sourcePosition = getSourceHandlePosition(memoizedOptions.direction);
        node.targetPosition = getTargetHandlePosition(memoizedOptions.direction);
      }

      for (const edge of edges) {
        edge.style = { ...edge.style, opacity: 1 };
      }

      if (JSON.stringify(nodes) !== JSON.stringify(nextNodes) || 
          JSON.stringify(edges) !== JSON.stringify(nextEdges)) {
        setNodes(nextNodes);
        setEdges(nextEdges);
        fitView()
      }
    };

    runLayout();
  }, [nodesInitialized, elements,memoizedOptions, setNodes, setEdges]);
}

export default useAutoLayout;

function compareElements(xs, ys) {
  return (
    compareNodes(xs.nodeMap, ys.nodeMap) && compareEdges(xs.edgeMap, ys.edgeMap)
  );
}

function compareNodes(xs, ys) {
  if (xs.size !== ys.size) return false;

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id);
    if (!y) return false;

    if (x.resizing || x.dragging) return true;
    if (x.width !== y.width || x.height !== y.height) return false;
  }

  return true;
}

function compareEdges(xs, ys) {
  if (xs.size !== ys.size) return false;

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id);
    if (!y) return false;
    if (x.source !== y.source || x.target !== y.target) return false;
    if (x?.sourceHandle !== y?.sourceHandle) return false;
    if (x?.targetHandle !== y?.targetHandle) return false;
  }

  return true;
}

