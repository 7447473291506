import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";

export const OutlinedButton = ({ onClick, name, icon, sx, color }) => {
  const { palette, typography } = useTheme();
  const { primary, text } = palette;
  return (
    <Tooltip title={name}>
      <MDBox
        px={1}
        sx={{
          cursor: "pointer",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: primary.main,
          border: "1px solid #D6D9FF",
          borderRadius: "6px",
          fontWeight: "500",
          alignItems: "center",
          gap: "3px",
          fontSize: typography.size["sm"],
          ...sx,
        }}
        onClick={onClick}
      >
        {icon}
        <MDBox
          sx={{
            paddingRight: "5px",
            lineHeight: "1em",
          }}
          color={color || text.main}
        >
          {name}
        </MDBox>
      </MDBox>
    </Tooltip>
  );
};

export const ExpandingButton = ({
  onClick,
  name,
  icon,
  sx,
  color,
  iconPosition = "left",
}) => {
  const { palette, typography } = useTheme();
  const { primary, white } = palette;
  return (
    <MDBox
      sx={{
        backgroundColor: primary.main,
        borderRadius: "100px",
        paddingRight: "7px",
        height: "2em",
        minWidth: "2em",
        maxWidth: "2em",
        fontSize: typography.size["xs"],
        fontWeight: "bold",
        cursor: "pointer",
        color: white.main,
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        gap: "5px",
        transition: "all .8s",
        "&:hover .hide": {
          maxWidth: "200px !important",
        },
        "&:hover": {
          maxWidth: "200px",
          paddingLeft: "10px",
        },
        ...sx,
      }}
      onClick={onClick}
      color={color}
    >
      {iconPosition === "left" ? icon : ""}
      <span
        className="hide"
        style={{
          maxWidth: 0,
          transition: "max-width .8s",
          WebkitTransition: "max-width 1s", // For Safari (webkit)
          display: "inline-block",
          verticalAlign: "top",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {name}
      </span>
      {iconPosition === "right" ? icon : ""}
    </MDBox>
  );
};
