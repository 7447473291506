import { useTheme } from "@emotion/react";

export const useStyles = () => {
  const { palette, functions } = useTheme();
  const { primary, white, secondary, warning } = palette;
  const { pxToRem } = functions;

  return {
    pFont: {
      color: primary.main,
    },

    aFont: {
      color: warning.main,
    },

    navbarContainer: {
      borderRadius: pxToRem(10),
      boxShadow: '0px 0px 8px 1px #0000000D',
    },

    flexBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    leftCard: {
      display: "flex",
      alignItems: "center",
      gap: pxToRem(10),
    },

    iconHolder: {
      borderRadius: pxToRem(50),
      backgroundColor: primary.main,
      width: pxToRem(36),
      height: pxToRem(36),
      padding: pxToRem(10),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: white.main
    },

    infoBox: {
      color: primary.main,
      fontSize: pxToRem(12),
      lineHeight: "1em",
    },

    infoChip: {
      fontSize: pxToRem(12),
      padding: `${pxToRem(2)} ${pxToRem(8)}`,
      height: pxToRem(28),
      backgroundColor: primary.light,
      "&hover":{
        backgroundColor: primary.light+ " !important",
      }
    },

    tag: {
      fontSize: pxToRem(12),
      padding: `${pxToRem(1)} ${pxToRem(6)}`,
      height: pxToRem(26),
      color: primary.main,
      backgroundColor: primary.light,
      border: '1px solid '+ primary.main,
      '&:hover': {
        backgroundColor: primary.light,
      }
    },
  };
};
