// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  AppBar,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { TabContext, TabPanel } from "@mui/lab";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

//  React components

import MDButton from "components/MDButton";

// Billing page components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import LoadingOverlay from "react-loading-overlay";
import CustomInput from "components/controls/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import CustomerData from "./customerData";
import NameChange from "./nameChange";
import MaxHeightTextarea from "utils/maxHeightTextArea";
import CustomAutoComplete from "components/controls/AutoComplete";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import { useTheme } from "@emotion/react";

function WorkflowUpdate() {
  const navigation = useNavigate();
  const [assignAgents, setAssignAgents] = useState([]);
  const [state, setState] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [workflowDetail, setWorkflowDetail] = useState({});
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingWorflowDetails, setIsLoadingWorflowDetails] = useState(false);
  const [showComponent, setShowComponent] = useState({
    autoAssign: false,
    customerAssign: false,
  });
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [showNameChange, setShowNameChange] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});

  const tabs = ["Customer Wise User Update", "Workflow Step Wise Assignee"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleNameChange = (customer_code, key, value) => {
    setShowNameChange(true);
    setCustomerDetails({ customer_code, key, value });
  };

  const {
    columns: pColumns,
    rows: pRows,
    callbackFunction: callbackFunction,
  } = CustomerData({
    setIsLoading: setIsLoadingTable,
    agents: assignAgents,
    workflows: workflows,
    handleNameChange: handleNameChange,
  });

  const updateAutoTransfer = (stepName, agentEmail) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .updateWorflowAutoTransfer(state.workflow, stepName, agentEmail)
      .then((res) => {
        if (res.status == 200) {
          setWorkflowDetail({
            ...workflowDetail,
            [stepName]: {
              ...workflowDetail[stepName],
              auto_transfer_email: agentEmail,
            },
          });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const updateInitAgent = (agentEmail) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .updateWorflowInitAgent(state.workflow, agentEmail)
      .then((res) => {
        if (res.status == 200) {
          setState({ ...state, init_agent: agentEmail });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
        }
      })
      .catch((error) => {});
  };

  const getWorkflowDetails = (workflowId) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .getWorkflowById(workflowId)
      .then((res) => {
        if (res.status == 200) {
          setWorkflowDetail(res.data.workflow);
          setState((prevState) => {
            return {
              ...prevState,
              workflow: workflowId,
              init_agent: res.data.init_agent,
            };
          });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAssignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getWorkflows();
    getAgents();
  }, []);

  const handleWorkflowSelect = (id) => {
    // let init_agent = workflows.filter((workflow) => {
    //   return workflow._id == e.target.value;
    // })[0]?.init_agent;
    // setState({ ...state, workflow: e.target.value, init_agent: init_agent });
    getWorkflowDetails(id);
  };

  const selectedStyle = {
    backgroundColor: "rgb(56,143,237)",
    color: "white !important",
  };
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              rebase_edit
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            Workflow Update
          </MDBox>
        }
      />

      <MDBox
        sx={{
          display: "flex",
          fontSize: typography.size["sm"],
          fontWeight: typography.fontWeightMedium,
          borderBottom: `1px solid ${palette.grey[300]}`,
          gap: "1em",
        }}
        mt={2}
      >
        {tabs.map((tab, index) => (
          <MDBox
            key={index}
            sx={{
              padding: pxToRem(8),
              cursor: "pointer",
              gap: "5px",
              display: "flex",
              paddingBottom: pxToRem(5),
              borderBottom:
                activeTab === tab ? `2px solid ${palette.primary.main}` : ``,
            }}
            color={palette.text.header}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </MDBox>
        ))}
      </MDBox>

      <MDBox sx={{ marginTop: "16px" }}>
        {activeTab === tabs[0] ? (
          <DataTable
            table={{ columns: pColumns, rows: pRows }}
            isSorted={true}
            entriesPerPage={true}
            showTotalEntries={true}
            downloadable={false}
            noEndBorder
            canSearch={true}
            isLoading={isLoadingTable}
            // columnFilter={true}
            tableHeight={"calc(var(--h-100vh) - 235px)"}
          />
        ) : (
          <MDBox
            sx={{
              padding: "1em",
              backgroundColor: white.main,
              borderRadius: "10px",
            }}
          >
            <MDBox sx={{ display: "flex", gap: "2em" }} mx={4}>
              <CustomAutoComplete
                label={"WorkFlow Name"}
                id="workflow_id"
                labelId="email-to-label"
                value={state.workflow}
                name="workflow_id"
                onChange={(e, data) => {
                  handleWorkflowSelect(data?.["_id"]);
                }}
                options={workflows}
                disableClearable
              />
              <Tooltip
                title={
                  state.workflow
                    ? ""
                    : "Agent change is disabled. Please select Workflow before changing agent."
                }
                placement="top"
              >
                <CustomAutoComplete
                  value={state.init_agent}
                  name={"secondary_contact"}
                  label={"Initial Assigned Agent"}
                  options={assignAgents}
                  onChange={(e, data) => updateInitAgent(data?.email_id)}
                  key={state.init_agent}
                />
              </Tooltip>
            </MDBox>
            {Object.keys(workflowDetail).length != 0 && (
              <MDBox mt={2}>
                <LoadingOverlay
                  active={isLoadingWorflowDetails}
                  spinner
                  text="Loading your content..."
                >
                  <Grid spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Form>
                        <Grid container>
                          {Object.keys(workflowDetail).map((step) => {
                            return (
                              <>
                                <Grid item xs={4}>
                                  <CustomInput
                                    value={step}
                                    label={"Step Name"}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <CustomInput
                                    id="assigned_agent_email"
                                    labelId="email-to-label"
                                    label={"Rule Function"}
                                    value={
                                      workflowDetail[step].rule_function
                                        ? workflowDetail[step].rule_function
                                        : " Not assigned "
                                    }
                                    name={"Rule Function"}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <CustomAutoComplete
                                    sx={{padding: 0}}
                                    id="assigned_agent_email"
                                    labelId="email-to-label"
                                    value={
                                      workflowDetail[step].auto_transfer_email
                                        ? workflowDetail[step]
                                            .auto_transfer_email
                                        : " Not assigned "
                                    }
                                    name="assigned_agent_email"
                                    label={"Assigned Agent"}
                                    onChange={(e, data) =>
                                      updateAutoTransfer(step, data?.email_id)
                                    }
                                    options={assignAgents}
                                  />
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </Form>
                    </Grid>
                  </Grid>
                </LoadingOverlay>
              </MDBox>
            )}
          </MDBox>
        )}
      </MDBox>

      {showNameChange && Object.keys(customerDetails).length != 0 && (
        <NameChange
          open={showNameChange}
          handleClose={setShowNameChange}
          customerDetails={customerDetails}
          callbackFunction={callbackFunction}
        ></NameChange>
      )}
    </DashboardLayout>
  );
}

export default WorkflowUpdate;
