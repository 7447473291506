import MDBox from "components/MDBox";
import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../general/SearchBar";
import ContentBox from "../general/ContentBox";
import BottomInput from "../general/BottomInput";
import useMassage from "./hooks/useMessage";
import ExpandableCard from "../general/ExpandableCard";
import { messageService } from "./service/messageService";
import { setGlobalState } from "Global";
import { useTheme } from "@emotion/react";
import { formatDate, formatTime } from "../general/utils";
import MessageSkeleton from "skeletons/forms/MessageSkeleton";
import CustomAutoComplete from "components/controls/AutoComplete";
import EmptyResult from "utils/emptyResult";

const MessagesCard = ({ data, myEmail, handleReply }) => {
  const { palette, typography } = useTheme();
  const { primary, white, text } = palette;

  const myMessageStyle = {
    background: primary.light,
    padding: "7px 11px",
    margin: "0",
  };

  return (
    <MDBox
      sx={{
        display: "flex",
        margin: "20px 10px 20px 5px",
        ...(myEmail === data?.from
          ? {
              flexDirection: "row-reverse",
            }
          : {}),
      }}
      key={data?.timestamp}
    >
      <MDBox sx={{ width: "85%" }}>
        <MDBox>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {myEmail !== data?.from && (
              <MDBox
                sx={{
                  height: "38px",
                  width: "38px",
                  fontSize: typography.size["lg"],
                  fontWeight: "bold",
                  background: primary.light,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100%",
                  position: "relative",
                  zIndex: "1",
                  border: `1px solid ${palette.primary.main}`,
                }}
                title={data?.name}
              >
                {data?.name[0]?.toUpperCase()}
              </MDBox>
            )}
            <MDBox
              sx={{
                lineHeight: "1.2em",
              }}
            >
              {myEmail !== data?.from ? (
                <>
                  <MDBox color={palette.text.disabled}>{data?.name}</MDBox>
                  <MDBox
                    sx={{
                      fontSize: "12px",
                    }}
                    color={palette.text.disabled}
                  >
                    {data.from}
                  </MDBox>
                </>
              ) : (
                <MDBox
                  sx={{
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                  color={palette.text.disabled}
                >
                  To {data.to}
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
        <ExpandableCard
          style={{
            fontSize: typography.size["sm"],
            padding: "7px 0",
            marginLeft: "3px",
            background: white.main,
            borderRadius: "8px",
            ...(myEmail === data?.from ? myMessageStyle : {}),
          }}
          color={text.secondary}
          content={data?.content}
        />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "3px",
          }}
        >
          <MDBox
            sx={{
              fontSize: typography.size["sm"],
              marginTop: "5px",
              marginBottom: "5px",
            }}
            color={palette.text.disabled}
          >
            {formatDate(data?.timestamp)} . {formatTime(data?.timestamp)}
          </MDBox>
          {myEmail !== data?.from && (
            <MDBox
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                cursor: "pointer",
              }}
              color={primary.main}
              onClick={() => handleReply(data?.from)}
            >
              <span
                style={{ fontSize: "1.2em" }}
                class="material-symbols-outlined"
              >
                reply_all
              </span>
              Reply
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <MDBox sx={{ width: "15%" }}></MDBox>
    </MDBox>
  );
};

const Messages = ({ caseId, caseDetails }) => {
  const {
    message,
    setMessage,
    filterdMessage,
    setFilterdMessage,
    myEmail,
    agents,
  } = useMassage(caseId);

  const inputRef = useRef(null);
  const mailSubjectRef = useRef(null);
  const mailToRef = useRef(null);
  const contentBoxRef = useRef(null);
  const searchRef = useRef(null);
  const [mailTo, setMailTo] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const { palette } = useTheme();
  const { white, text } = palette;

  useEffect(() => {
    if (contentBoxRef)
      contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
  }, [message]);

  const searchMessagessBySenderOrContent = () => {
    const filterBy = searchRef?.current.value;
    const filteredResult = message?.filter(
      (data) =>
        data?.name.includes(filterBy) ||
        data?.from.includes(filterBy) ||
        data?.content.includes(filterBy)
    );
    setFilterdMessage(filteredResult);
  };

  const sendMessage = () => {
    // { case_id, emailTo, emailCC, subject, body, case_details }
    const data = {
      case_id: caseId,
      emailTo: mailTo,
      subject: mailSubjectRef?.current.innerText,
      body: inputRef?.current.innerText,
      case_details: getCaseSummary(),
    };
    
    if (!(data.emailTo && data.subject.trim().length && data.body.trim().length)) {
      setGlobalState("error", {
        open: true,
        message: "The required fields are missing",
        type: "error",
      });
      return;
    }
    setIsLoading(true)
    messageService
      .sendMessage(data)
      .then((response) => {
        const tempData = {
          name: "",
          subject: data.subject,
          content: data.body,
          timestamp: Date(),
          from: myEmail,
          to: data.emailTo,
          ...data,
        };
        if (message.lenght === filterdMessage.lenght)
          setFilterdMessage([...message, tempData]);
        setMessage([...message, tempData]);
        inputRef.current.innerHTML = "";
        setMailTo(null);
        mailSubjectRef.current.innerHTML = "";
        contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error);
      });
  };

  const getCaseSummary = () => {
    let tempCaseDetails = "";
    Object.keys(caseDetails).forEach((item) => {
      tempCaseDetails += ` ${item} : ${caseDetails[item].name} \n`;
    });
    let caseSummary = tempCaseDetails;
    return caseSummary;
  };

  const handleReply = (emailTo) => {
    setMailTo(emailTo);
    inputRef.current.focus();
  };

  return (
    <>
      <MDBox
        sx={{
          background: white.main,
          paddingBottom: "10px",
        }}
      >
        <SearchBar
          placeHolder={"Search Messages"}
          handleKeyUp={searchMessagessBySenderOrContent}
          inputRef={searchRef}
        />
      </MDBox>
      <ContentBox height={"calc(100% - 200px)"} contentBoxRef={contentBoxRef}>
        {!filterdMessage && <MessageSkeleton />}
        <MDBox>
          {filterdMessage?.map((data, index) => (
            <MessagesCard
              key={index}
              data={data}
              myEmail={myEmail}
              handleReply={handleReply}
            />
          ))}
        </MDBox>
        { filterdMessage?.length === 0 &&  <EmptyResult text={"No Messages Found"} />}
      </ContentBox>
      <MDBox
        sx={{
          position: "sticky",
          bottom: "0",
          background: white.main,
          paddingTop: "10px",
          borderTop: "1px solid " + text.light,
          zIndex: 2,
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
          }}
        >
          <CustomAutoComplete
            sx={{
              width: "40%",
              padding: 0,
              backgroundColor: palette.primary.light,
              borderRadius: '8px',
              "& .MuiOutlinedInput-root": {
                overflow: 'visible',
                color: text.secondary,
                paddingRight: '20px !important',
                "& .MuiInputBase-input": {
                  width: '100%'
                },
                "& .MuiAutocomplete-endAdornment": {
                  right: '0', "& .MuiAutocomplete-clearIndicator":{position:'absolute' , top: '-20px', right: '-7px'}
                },
                "& .MuiInputBase-input::placeholder":{
                  color: text.disabled,
                  opacity: 1
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  color: text.secondary,
                  border: 'none',
                },
                "&:hover fieldset": {
                  border: 'none'
                },
                "&.Mui-focused fieldset": {
                  border: 'none'
                },
              },
            }}
            placeholder={"Email Id"}
            name="assignedAgent"
            value={mailTo}
            onChange={(e, value) => {
              setMailTo(value?.email_id);
            }}
            options={agents}
            // disableClearable
          />
          <BottomInput
            placeHolder={"Subject"}
            inputRef={mailSubjectRef}
            style={{ width: "60%" }}
          />
        </MDBox>
        <BottomInput
          placeHolder={"Type your message here"}
          icon={<span class="material-symbols-outlined">send</span>}
          handleClick={sendMessage}
          inputRef={inputRef}
          isLoading={isLoading}
        />
      </MDBox>
    </>
  );
};

export default Messages;
