import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { featureAPI } from "api";
import { setGlobalState } from "Global";
import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import MDBox from "components/MDBox";

function FileModal({ file, downloadLink = null }) {
  const params = useParams();
  const [fileUrl, setFileUrl] = useState(null);

  const getFileType = (fileName) => {
    let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    return fileType === "msg" ? "pdf" : fileType;
  };

  const downloadFile = async () => {
    try {
      let res = null;
  
      if (downloadLink) {
        // For S3 or any external download link
        res = await fetch(downloadLink);
        if (res.ok) {
          const blob = await res.blob(); // Convert the response to Blob
          const url = window.URL.createObjectURL(blob);
          setFileUrl(url);
          sessionStorage.setItem(
            file["file_hash"] + file.fileName,
            await blobToBase64(blob)
          );
        } else {
          throw new Error("Failed to download file from the link");
        }
      } else {
        // For API-based download
        res = await featureAPI.downloadFile({
          case_id: params.case_id,
          file_name: file.fileName,
          file_feature_name: file.fileFeatureName,
        });
  
        if (res?.status === 200) {
          const blob = res.data;
          const url = window.URL.createObjectURL(new Blob([blob]));
          setFileUrl(url);
          sessionStorage.setItem(
            file["file_hash"] + file.fileName,
            await blobToBase64(blob)
          );
        }
      }
    } catch (error) {
      if (error?.response?.status && ![422].includes(error?.response?.status)) {
        setGlobalState("error", {
          open: true,
          message: "Error while retrieving the file. Please load again",
          type: "error",
          code: error?.response?.status,
        });
      } else {
        console.error("Error downloading the file:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sessionStorage.getItem(file["file_hash"] + file.fileName)) {
        const dataURI = sessionStorage.getItem(
          file["file_hash"] + file.fileName
        );
        const url = window.URL.createObjectURL(dataURItoBlob(dataURI));
        setFileUrl(url);
      } else {
        await downloadFile();
      }
    };

    fetchData();

    // Cleanup function to revoke the object URL
    return () => {
      if (fileUrl) {
        window.URL.revokeObjectURL(fileUrl);
      }
    };
  }, [file]);

  if (getFileType(file["name"]) === "pdf" && fileUrl) {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={fileUrl} />
      </Worker>
    );
  } else if (fileUrl) {
    return (
      <FileViewer fileType={getFileType(file["name"])} filePath={fileUrl} />
    );
  } else {
    return (
      <LoadingOverlay active={true} spinner text="Loading your content...">
        <MDBox sx={{ height: "100%" }} />
      </LoadingOverlay>
    );
  }
}

export default FileModal;

// Utility function to convert base64 to Blob
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([new DataView(arrayBuffer)], { type: mimeString });
}
