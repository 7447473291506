import { Card } from "@mui/material";
import { setGlobalState } from "Global";
import { AIAgentAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import CustomAutoComplete from "components/controls/AutoComplete";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import EmptyResult from "utils/emptyResult";
import MDTypography from "components/MDTypography";
import { LottieFiles } from "utils/lottieFiles";
import ActionMessage from "./components/ActionMessage";
import CustomInput from "components/controls/CustomInput";
import MDChip from "components/MDChip";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
      box-sizing: border-box;
      width: 320px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      box-shadow: 0px 2px 2px ${
        theme.palette.mode === "dark" ? grey[900] : grey[50]
      };
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${
          theme.palette.mode === "dark" ? blue[600] : blue[200]
        };
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `
);

function AutoTransferAction({
  action,
  index,
  handleNotificationChange,
  handlePerformAction,
  agents,
  loaders,
}) {
  return (
    action && (
      <MDBox sx={{ padding: "0", margin: "0" }}>
        <MDBox
          sx={{
            fontWeight: "500",
            margin: "0.5rem",
            display: "flex",
            alignItems: "center",
            marginBottom: "0.5rem",
            marginLeft: "0",
          }}
        >
          <ActionMessage
            isloading={loaders["autotransfer"]}
            action={loaders["autotransfer"]}
            successMessage={"Steps that AI will perform"}
            errorMessage={"Steps that AI will perform"}
            loadingMessage={"Refreshing..."}
          />
        </MDBox>
        {!loaders["autotransfer"] && 
        
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between !important",
            }}
            ml={3}
          >
            <MDBox mt={1}>
              <MDBox sx={{fontWeight: 'bold', fontSize: '.9em'}}>
                1) Go to Next Step:
              </MDBox>
              <MDBox sx={{ margin: "0rem 1rem 0.5rem 1rem", fontSize: '.9em'}}>{action.next_step_chosen} testing the wrap</MDBox>
            </MDBox>
            <MDBox>
              <MDBox sx={{fontWeight: 'bold' , fontSize: '.9em'}}>
                2) Transfer Agent with Message
              </MDBox>
              <MDBox
                style={{
                  width: "90%",
                  margin: "0rem 1rem 0.5rem 1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {action && (
                  <CustomAutoComplete
                    name={"auto_assign_agent_email"}
                    value={action?.value || action.auto_assign_agent_email}
                    label={"Transfer To"}
                    onChange={(e, value) =>
                      handleNotificationChange({target: {name:"auto_assign_agent_email", value: value?.email_id}}, "transfer_action")
                    }
                    options={agents}
                    size={"small"}
                  />
                )}
                <CustomInput
                  value={action["notification_for_next_person"]}
                  name={"notification_for_next_person"}
                  onChange={(e) => handleNotificationChange(e, "transfer_action")}
                  label={"Notification Content"}
                  maxRows={"4"}
                  size={"small"}
                />
              </MDBox>
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "right",
                cursor: "pointer",
              }}
            >
              <MDChip
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.main,
                  padding: "5px 10px",
                  color: theme.palette.white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                })}
                label={"Transfer"}
                onClick={(e) => handlePerformAction(action)}
              />
            </MDBox>
          </MDBox>
        
        }
      </MDBox>
    )
  );
}

export default AutoTransferAction;
