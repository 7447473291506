import { caseAPI } from "api";
import { userAPI } from "api";
import { useEffect, useState } from "react";

const useDetails = () => {
  const [userDetails, setUserDetials] = useState(null);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getAgents();
    getUserDetails();
  }, []);

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {});
  };
  const getUserDetails = () => {
    userAPI
      .getUserDetails()
      .then((res) => {
        setUserDetials(res.data);
      })
      .catch((error) => {
      });
  };

  return {
    userDetails: userDetails,
    setUserDetials: setUserDetials,
    agents: agents
  }
};

export default useDetails;
