export const COLORS = [
  "#666CFF",
  "#FF7B70",
  "#FEB686",
  "#34D7B0",
  "#D0D2FF",
  "#B8FFC7",
  "#FFD9E4",
  "#FFD9E4",
];

export const COLORS_2 = [
  "#666CFF",
  "#D0D2FF",
  "#34D7B0",
  "#FF7B70",
  "#FEB686",
  "#B8FFC7",
  "#FFD9E4",
  "#FFD9E4",
];
