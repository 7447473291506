import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import UploadFileCard from "layouts/formDetails/leftPanel/components/documents/uploadFileCard";
import ActionMessage from "./components/ActionMessage";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { caseAPI, docstoreAPI, featureAPI } from "api";
import FileThumbnailCard from "layouts/formDetails/leftPanel/components/documents/fileThumbnailCard";
import FileModal from "layouts/formDetails/leftPanel/components/documents/showFile";
import DialogWrapper from "utils/dialog/dialogWrapper";
import ReactPlayer from "react-player";

function FileUpdateAction({ action, index, handlePerformAction, loaders }) {
  const [suggestedFiles, setSuggestedFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const [searching,setSearching] = useState(false);
  const params = useParams();

  const uploadFiles = (stepName, fileListName, fileList) => {
    const formdata = new FormData();
    let files_feature_list = [];
    Array.from(fileList).forEach((file) => {
      files_feature_list.push(fileListName);

      formdata.append("files_list", file);
    });
    formdata.append("files_feature_list", files_feature_list);

    handlePerformAction(action, formdata);
  };

  const getCaseDetails = () => {
    setSearching(true);
    featureAPI
      .getFeatures(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          let featuresData = res.data["prev_features"]["Enter on claims report"];
          getSuggestedFiles(featuresData);
        }
      })
      .catch((error) => {
        setSearching(false);
        console.log(error);
      });
  };

  const getSuggestedFiles = (caseFeatures) => {
    let searchQuery = ` ${caseFeatures["Customer"]?.value} ${caseFeatures["Document Number"]?.value} ${caseFeatures["Invoice Number"]?.value} ${caseFeatures["P&G Delivery Number"]?.value} ${caseFeatures["Claim Amount (ZAR)"]?.value?.toString()} `;
    docstoreAPI
      .searchFiles({ search_query: searchQuery, vector_search: true })
      .then((res) => {
        if (res.status == 200) {
          let tempData = res.data;
          setSuggestedFiles(tempData);
          setSearching(false);
        }
      }).catch((error) => {
        setSearching(false);
        console.log(error);
      }
      );
  };

  useEffect(() => {
    if (action) getCaseDetails();
  }, [action]);

  return (
    <>
      <MDBox
        sx={{
          fontWeight: "500",
          margin: "0.5rem",
          display: "flex",
          alignItems: "center",
          marginLeft: "0",
        }}
      >
        {(action == null || action) && (
          <ActionMessage
            isloading={loaders["file_action"]}
            action={action}
            successMessage={"Done"}
            errorMessage={"Please Upload these Files"}
            loadingMessage={"Refreshing..."}
          />
        )}
      </MDBox>
      {!loaders["file_action"] && action && (
        <MDBox
          sx={{
            displayl: "flex",
            justifyContent: "space-between",
            border: "1px solid #E7E7E7",
            padding: "8px",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          <MDBox
            sx={{ display: "flex", gap: "10px", alignItems: "center" }}
            pl={"10px"}
          >
            <span
              class="material-symbols-outlined"
              style={{ color: "#666CFF" }}
            >
              upload_file
            </span>
            <MDBox sx={{ fontWeight: "400", paddingTop: "3px" }}>
              {action.file_feature_name}
            </MDBox>
          </MDBox>
          <MDBox sx={{ position: "relative" }}>
            <UploadFileCard
              fileName={action.file_feature_name}
              length={0}
              uploadFiles={uploadFiles}
              stepName={action.file_step_name}
            />
          </MDBox>
        </MDBox>
      )}
      
      <MDBox sx={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
      {searching && <ReactPlayer url='/gif/Searching Document.mp4' loop={true} playing={true} height={"200px"} width={"300px"} />}
      {suggestedFiles.length > 0 && action && (
       suggestedFiles.map((specificFile,index) => {
        if(index<5){
        return (
          <FileThumbnailCard
            key={specificFile["filename"]}
            file={{"name":specificFile["filename"],"download_link":specificFile["s3_url"]} }
            
            showIcons={true}
            fileFeatureName={specificFile["filename"]}
            handleThumbnailClick={() => {
              setShowModal(true);
              setSelectedFile({
                ...specificFile,
                name:specificFile["filename"],
                file_hash:specificFile["s3_url"],
                fileName: specificFile["filename"],
                fileFeatureName: action.file_feature_name,
              });
            }}
          />
        )};
      })
      )}</MDBox>
                {showModal && (
            <DialogWrapper
              handleClose={() => setShowModal(false)}
              width={"35%"}
            >
              <MDBox className={"fileViewer"}>
                <FileModal file={selectedFile} downloadLink={selectedFile.s3_url} key={selectedFile.fileName} />
              </MDBox>
            </DialogWrapper>
          )}
    </>
  );
}

export default FileUpdateAction;
