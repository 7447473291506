import { featureAPI } from "api";
import { useEffect, useState } from 'react';

const useLinkedCases = (caseId) => {
    const [linkedCases, setLinkedCases] = useState();

    useEffect(() => {
        featureAPI
            .getGroupedCases({ case_id: caseId })
            .then((res) => {
                if (res.status == 200) {
                    setLinkedCases(res.data.data);
                }
            })
            .catch((error) => { });
    }, [caseId])

    return {
        linkedCases: linkedCases
    }
}

export default useLinkedCases