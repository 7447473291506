import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import MDBox from 'components/MDBox';
import { useEffect, useRef, useState } from 'react';

const ExpandableCard = ({ style, color, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isContentOverflowing, setIsContentOverflowing] = useState(false);
    const contentRef = useRef(null);

    const { palette } = useTheme();
    const { white, text } = palette;

    useEffect(() => {
        contentRef.current.innerHTML = content.replace('\n', '<br/>')
    }, [content])

    const checkOverflow = () => {
        if (contentRef.current) {
            const { clientHeight } = contentRef.current;
            setIsContentOverflowing(67 < clientHeight);
        }
    }

    useEffect(() => {
        checkOverflow();
    }, [text]);

    const collapseStyle = {
        height: '4.05em',
        overflow: 'hidden',
        position: 'relative'
    };

    const collapseStyleForMore = {
        position: 'absolute',
        bottom: '5px',
        right: '10px',
        paddingLeft: '10px',
    };

    return (
        <MDBox
            sx={{
                ...style,
                ...(!isExpanded && isContentOverflowing ? collapseStyle : {})
            }}
            color={color}
        >
            <Box ref={contentRef}></Box>
            {isContentOverflowing && (
                <MDBox
                    sx={{
                        background: style?.background || white.main,
                        cursor: 'pointer',
                        ...(!isExpanded ? collapseStyleForMore : {})
                    }}
                    color={palette.primary.main}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? 'less...' : '...more'}
                </MDBox>
            )}
        </MDBox>
    );
};

export default ExpandableCard;
