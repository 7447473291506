import { useTheme } from "@emotion/react";
import { AIAgentAPI } from "api";
// import sparkle from "assets/images/newIcons/sparkle.svg";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import SummarySkeleton from "skeletons/forms/summarySkeleton";
import ContentBox from "../../rightPanel/components/general/ContentBox";
import { position } from "stylis";
import { sparkleSvg } from "examples/svgIcons/svgIcons";

const AISummery = ({ caseId, closeSummary }) => {
  const { palette, typography } = useTheme();
  const { primary, secondary, white } = palette;

  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    getAISummery();
  }, [caseId]);

  const getAISummery = () => {
    setIsLoading(true);
    AIAgentAPI.getCaseSummary({ case_id: caseId })
      .then((res) => {
        if (res.status == 200) {
          setSummary(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <ContentBox sx={{position: 'relative'}}>
      <MDBox sx={{position:'sticky', top:0, background: white.main, zIndex: 2}}>
      <MDBox
        sx={{
          background:
            "linear-gradient(90.05deg, #666CFF 9.14%, #00CC9B 88.27%)",
          borderRadius: "10px",
          height: "4px",
          opacity: '.4'
        }}
      ></MDBox>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        py={1.5}
      >
        <MDBox
          sx={{
            position: "relative",
            display: "inline-block",
            fontSize: "16px",
            fontWeight: typography.fontWeightBold,
          }}
          color={secondary.main}
        >
          Claim Summary
        <MDBox
          sx={{            
            position: "absolute",
            top: "0",
            right: "-25px",
          }}
        >
          {sparkleSvg(secondary.main)}
        </MDBox>
        </MDBox>

        <MDBox
          sx={{ cursor: "pointer" }}
          color={primary.main}
          onClick={closeSummary}
          mx={0.5}
        >
          x Close
        </MDBox>
      </MDBox>
      </MDBox>
      <MDBox>
        {isLoading == true ? (
          <SummarySkeleton />
        ) : (
          summary?.map((data, index) => (
            <MDBox
              sx={{
                display: "flex",
              }}
              key={index}
            >
              <MDBox
                sx={{
                  marginRight: "15px",
                  position: "relative",
                }}
              >
                <MDBox
                  sx={{
                    height: "30px",
                    width: "30px",
                    fontSize: typography.size["xs"],
                    fontWeight: "bold",
                    background: "#E6FFF9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                    position: "relative",
                    marginTop: "10px",
                    zIndex: "1",
                  }}
                  color={secondary.main}
                >
                  {index + 1}
                </MDBox>
                <MDBox
                  sx={{
                    height: "100%",
                    borderLeft: `1px solid ${palette.text.light}`,
                    zIndex: "0",
                    position: "absolute",
                    top: "0",
                    left: "50%",
                  }}
                ></MDBox>
              </MDBox>
              <MDBox
                sx={{
                  margin: "10px 15px 10px 0",
                  boxShadow: "0px 0px 8px 1px #00000014",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                {data}
              </MDBox>
            </MDBox>
          ))
        )}
      </MDBox>
    </ContentBox>
  );
};

export default AISummery;
