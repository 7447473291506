import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";

const InfoCard = ({
  title,
  iconName,
  pastWeekPercentincrease,
  count,
  iconColor,
  totalClaimAmount,
  enableDrag
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <MDBox
      sx={{
        height: "100%",
        width: "100%",
        padding: ".8em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...(enableDrag ? {
          boxShadow: '0px 0px 15px 1px #0000001a',
          transform: 'rotate(-1deg)'
        }: {})
      }}
      onClick={(e) => {
        if(!enableDrag) e.stopPropagation();
      }}
      onMouseDown={(e) => {if(!enableDrag) e.stopPropagation()}}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDBox color={text.disabled}>{title}</MDBox>
        <MDBox
          sx={{
            color: iconColor,
            cursor: "pointer",
            fontSize: "1.5em",
            height: "35px",
            width: "35px",
            display: "grid",
            placeItems: "center",
            position: "relative",
            "&::after": {
              position: "absolute",
              content: '""',
              height: "35px",
              width: "35px",
              backgroundColor: iconColor,
              borderRadius: ".5em",
              opacity: 0.2,
            },
          }}
        >
          <span class="material-symbols-outlined">{iconName}</span>
        </MDBox>
      </MDBox>
      <MDBox>
        <MDBox
        sx={{marginBottom: '5px',}}
        >
          <MDBox sx={{fontSize: typography.size['xs']}} color={text.disabled}>Count</MDBox>
          <MDBox sx={{
            fontSize: typography.size["2xl"],
            fontWeight: typography.fontWeightMedium,
            lineHeight: '1em'
          }} color={text.main}>{count}</MDBox>
        </MDBox>
        <MDBox sx={{marginBottom: '10px',}}>
          <MDBox sx={{fontSize: typography.size['xs']}} color={text.disabled}>Amount</MDBox>
          <MDBox sx={{
            lineHeight: '1em',
            fontSize: typography.size["1xl"],
          }} color={text.main}>{totalClaimAmount?.toFixed(2)}</MDBox>
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: '1em'
          }}
        >
          <MDBox>
            {pastWeekPercentincrease > 0 ? (
              <span
                style={{ fontSize: "1.5em", color: "#33C23A" }}
                class="material-symbols-outlined"
              >
                trending_up
              </span>
            ) : (
              <span
                style={{ fontSize: "1.5em", color: "#EF5350" }}
                class="material-symbols-outlined"
              >
                trending_down
              </span>
            )}
          </MDBox>
          <MDBox sx={{fontSize: typography.size['xs'], lineHeight:'1.2em'}} color={text.disabled}>
            {pastWeekPercentincrease > 0 ? (
              <>
                <span style={{ color: "#33C23A" }}>
                  {Math.round(pastWeekPercentincrease)}%
                </span>
                <span> up from past month</span>
              </>
            ) : (
              <>
                <span style={{ color: "#EF5350" }}>
                  {Math.round(pastWeekPercentincrease)}%
                </span>
                <span> down from past month</span>
              </>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default InfoCard;
