import CustomInput from "./CustomInput";
import RadioGroup from "./RadioGroup";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ListInput from "./ListInput";
import Bullet from "./Bullet";
import KeyValueSelect from "./KeyValueSelect";
import Integer from "./Integer";
import Paragraph from "./Paragraph"
import CustomAutoComplete from "./AutoComplete";
import KeyValueConst from "./KeyValueConst";

const Controls = {
    "Text":CustomInput,
    "radio":RadioGroup,
    "DropdownSingleSelect":CustomAutoComplete,
    "checkbox":Checkbox,
    "Date":DatePicker,
    "Button":Button,
    "Integer":Integer,
    "List":ListInput,
    "Bullet":Bullet,
    "Keyvalue":KeyValueSelect,
    "Paragraph":CustomInput,
    "KeyvalueConstOptions":KeyValueConst
}

export default Controls;

export const controlTypes = {
    text: "Text",
    radio: "radio",
    dropdown: "DropdownSingleSelect",
    checkBox: "checkbox",
    date: "Date",
    integer: "Integer",
    files: "ListofFiles"
}