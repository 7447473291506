import { chatAPI } from "api";

export const notesService = {
    getNotes: (caseId) => {
        return chatAPI
            .getComments(caseId)
            .then((res) => {
                if (res.status == 200) {
                    let tempComments = res.data.map((comment) => {
                        return {
                            id: comment._id,
                            sender: comment.performer,
                            message: comment.comment,
                            timestamp: comment.timestamp,
                        };
                    });
                    return tempComments
                }
            })
            .catch((error) => {

            });
    },

    sendNotes: (case_id, message) => {
        chatAPI
            .sendComments(case_id, message)
            .catch((error) => {

            });
    }
}