import { caseAPI } from "api";
import MDBox from "components/MDBox";
import WorkFlow from "../../../../../examples/workflow/Workflow";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MarkerType,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { featureAPI } from "api";
import ReactFlowPro from "../../../../../examples/workflow/Workflow";
import MDChip from "components/MDChip";
import { CircularProgress, Dialog, TextField } from "@mui/material";
import { setGlobalState, useGlobalState } from "Global";
import { useTheme } from "@emotion/react";
// const theme = useTheme();

const layoutOptions = {
  about: false,
  algorithm: "dagre",
  direction: "LR",
  spacing: [150, 150],
};

const NextStepWorkFlow = ({ currentStep, caseId }) => {
  const location = useLocation();
  const navigation = useNavigate();
  const theme = useTheme();
  const [userDetails] = useGlobalState("userDetails");
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [state, setState] = useState({
    showState: false,
    featuresIsEmpty: true,
    prevFeaturesIsEmpty: true,
    nextStep: {},
  });
  const { palette } = useTheme();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: theme.palette.primary.main,
    },
    pathOptions: { offset: 5 },
    style: {
      stroke: theme.palette.primary.main,
      strokeWidth: 2,
    },
    labelStyle: {
      fill: theme.palette.primary.main,
      fontSize: "12px",
      fontWeight: "bold",
    },
    labelBgStyle: {
      fill: theme.palette.primary.light,
      stroke: theme.palette.primary.main,
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 5,
  };

  useEffect(() => {
    getNextPaths();
    getFeatures();
  }, [location.pathname]);

  const handleSelectNextStep = (nextStep, emailId, status = null) => {

    if (["OOO", "Busy"].includes(status)) {
      setGlobalState("error", {
        open: true,
        message:
          "Selected agent is Busy/Out of Office. Please select the secondary assignee incase faster resolution is needed.",
        type: "warning",
      });
    }
    setState((prevState) => {
      return {
        ...prevState,
        nextStep: {
          ...prevState.nextStep,
          name: nextStep,
          emailId: emailId,
        },
      };
    });
  };

  const [nextStepMessage, setNextStepMessage] = useState('')

  const getFeatures = async () => {
    setIsLoading(true);
    featureAPI
      .getFeatures(caseId)
      .then((res) => {
        setState({
          ...state,
          features: res.data.features,
          prevFeatures: res.data.prev_features,
          featuresIsEmpty: res.data.features_isempty,
          prevFeaturesIsEmpty: res.data.prev_features_isempty,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let tempNodes = [
      {
        id: "start",
        data: {
          label: (
            <MDBox
              sx={{
                backgroundColor: theme.palette.primary.light,
                border: "1px solid " + theme.palette.primary.main,
                borderRadius: "5px",
              }}
              color={theme.palette.primary.main}
            >
              <MDBox
                sx={{
                  fontWeight: "bold",
                  borderBottom: "1px solid " + theme.palette.primary.main,
                  padding: "5px 10px",
                }}
                color={theme.palette.primary.main}
              >
                Current Step
              </MDBox>
              <MDBox
                color={theme.palette.primary.main}
                sx={{
                  padding: "10px",
                }}
              >
                {currentStep?.name}
              </MDBox>
            </MDBox>
          ),
        },
        position: { x: 0, y: 0 },
      },
    ];

    let tempEdges = [];

    status.forEach((item, index) => {
      tempNodes.push({
        id: index.toString(),
        data: {
          label: (
            <MDBox
              sx={{
                backgroundColor:
                  item.value == state.nextStep.name
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
                cursor: "pointer",
                border: item.value == state.nextStep.name ? "1px solid " +  theme.palette.white.main : "1px solid " +  theme.palette.primary.main,
                borderRadius: "5px",
                "&:hover":
                  item.value == state.nextStep.name
                    ? {}
                    : {
                        opacity:.8,
                      },
              }}
              color={item.value == state.nextStep.name ? theme.palette.white.main : theme.palette.primary.main }
              onClick={() => {
                handleSelectNextStep(
                  item.value,
                  item.agent_details.email_id,
                  item.agent_details?.active_status
                );
              }}
            >
              <MDBox color={"inherit"} sx={{
                  fontWeight: "bold",
                  borderBottom: item.value == state.nextStep.name ? "1px solid " +  theme.palette.white.main : "1px solid " +  theme.palette.primary.main,
                  padding: "5px 10px",
                }}>{item.value}</MDBox>
              <MDBox color={"inherit"} sx={{padding: "10px",}}>
              <MDBox style={{ fontSize: "small" }} color={"inherit"}>
                {item.agent_details?.name}
              </MDBox>
              <MDBox style={{ fontSize: "small" }} color={"inherit"}>
                ({item.agent_details?.active_status})
              </MDBox>
              </MDBox>
            </MDBox>
          ),
        },
        position: { x: 0, y: 0 },
      });

      tempEdges.push({
        id: "start->" + index.toString(),
        source: "start",
        target: index.toString(),
        label: item.edge?.includes("next_step") ? "Next step": item.edge,
      });
    });

    setNodes(tempNodes);
    setEdges(tempEdges);
  }, [status, state]);

  const getNextPaths = async () => {
    caseAPI
      .getNextPaths(caseId)
      .then((res) => {
        if (res.status == 200) {
          assignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  const assignAgents = async (currStatus) => {
    let tempStatus = currStatus;

    for (let i in tempStatus) {
      tempStatus[i]["agent_details"] = await getAutoAssignedDetails(
        currStatus[i].value
      );
    }
    setStatus(tempStatus);
  };

  const getAutoAssignedDetails = async (nextStep) => {
    let details = {
      error: true,
    };
    try {
      const res = await caseAPI.getAutoAssignedPerson(caseId, nextStep);

      if (res.data === null) {
        details["name"] = "No Person Available";
      } else {
        details["error"] = false;
        details = { ...details, ...res.data };
      }
    } catch (error) {}
    return details;
  };

  const handleStepChange = () => {
    setIsLoading(true)
    caseAPI
      .changeStatus(
        caseId,
        state.nextStep.name,
        state.nextStep.emailId ? true : false,
        state.nextStep.emailId,
        nextStepMessage
      )
      .then((res) => {
        setIsLoading(false)
        if (res.status == 200) {
          if (!["admin", "superuser", "3PA"].includes(userDetails.permission)) {
            navigation(`/${userDetails.id}/cases`);
          } else {
            window.location.reload();
          }
        }
      })
      .catch((error) => {setIsLoading(false)});
  };

  return (
    <MDBox sx={{ height: "100%" }}>
      <MDBox sx={{ fontSize: "1rem" }} mb={1}>
        Select Next Step
      </MDBox>

      <MDBox sx={{ height: "calc(100% - 30px)", position: "relative" }}>
        {nodes.length > 0 && edges.length > 0 ? (
          <WorkFlow
            nodes={nodes}
            edges={edges}
            zoomOnScroll={false}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            isAutoLayout={true}
            showBackground={true}
            showControls={false}
            edgeOptions={defaultEdgeOptions}
            nodesDraggable={false}
            layoutOptions={layoutOptions}
            nodeStyle={`
              padding: 0;
              border: none;
              width: auto;
              max-width: 300px;
            `}
            nodeDotStyle={`border:none; background-color: #00000000;`}
            key={edges + nodes}
          />
        ) : (
          <MDBox
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" />
          </MDBox>
        )}
      </MDBox>

      <Dialog
        open={state.nextStep.name}
        onClose={() => handleSelectNextStep(false, false)}
      >
        <MDBox sx={{ width: "400px" }} p={2}>
          <TextField
            id="message"
            label="Message"
            onChange={(e) => setNextStepMessage(e.target.value)}
            placeholder="Message to be sent..."
            name="message"
            size="small"
            multiline
            fullWidth
            variant="outlined"
          />
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <MDChip
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                padding: "5px 10px",
                color: theme.palette.white.main,
                fontWeight: "bold",
                cursor: "pointer",
                marginTop: "1em",
              })}
              label={isLoading ? "Updating..." : "Next Step"}
              avatar={
                isLoading ? (
                  <CircularProgress
                    color="white"
                    sx={{
                      height: "18px !important",
                      width: "18px !important",
                      backgroundColor: "#00000000 !important",
                    }}
                  />
                ) : (
                  <></>
                )
              }
              onClick={() => {
                isLoading ? {} : handleStepChange();
              }}
            />
          </MDBox>
        </MDBox>
      </Dialog>
    </MDBox>
  );
};

export default NextStepWorkFlow;
