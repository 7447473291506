import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import ChartHolderPopup from "./ChartHolderPopup";
import Chart from "examples/chart/Chart";
import { useEffect, useRef, useState } from "react";
import { analyticsAPI } from "api";
import Leaderboard from "./Leaderboard";
import { downloadImage, getDateRange, getFiltersCount } from "../utils";
import { setGlobalState, useGlobalState } from "Global";

const initialFilters = {
  case_state: [],
  age_bucket: [],
  category: [],
  sku: [],
  reason_codes: [],
  workflow_id: [],
  customer_name: [],
  claim_type: [],
  assigned_agent: [],
  claim_status: [],
  from_date: "",
  to_date: "",
  search: "",
  limit: 50,
  offset: 0,
};

const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];

const ChartHolder = ({
  title,
  children,
  setActivePopupComponent,
  chartProps,
  getData,
  id,
  showFilter,
  filterOptions,
  globalDateActiveTab,
  globalDateRange,
  enableDrag
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [chartData, setChartData] = useState(null);
  const [storedFilters] = useGlobalState("analyticsFilters");
  
  const [filters, setFilters] = useState(storedFilters[id] || initialFilters);
  const [filtersCount, setFiltersCount] = useState(0);
  const [tempFilters, setTempFilters] = useState(initialFilters);
  const [isPopupActive, setIsPopupActive] = useState(false);

  useEffect(()=>{
    if((id === "agentLeaderboard" || id === "claimByAgeBucket")&& !storedFilters[id]){
      setFilters({...initialFilters, case_state: ["In-Progress", "New"]})
    }
  }, [])

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const imageRef = useRef(null);

  const handleSelectTab = (tab, dateRange) => {
    if (tab === "Clear") {
        setActiveTab("");
        setFilters((pre) => ({ ...pre, from_date: "", to_date: "" }));
      } else {
        setActiveTab(tab);
        setFilters((pre) => ({
          ...pre,
          from_date: dateRange?.from || '',
          to_date: dateRange?.to || '',
        }));
      }
  };

  useEffect(() => {
    setTempFilters(filters)
    setFiltersCount(getFiltersCount(filters, ignoreFilterList));
    setChartData(null)
    if (getData && filters) {
      getData(filters).then((data) => {
        setChartData(data);
      }).catch(err => {})
    }
  }, [getData, filters]);

  useEffect(() => {
    // if (showFilter !== false) {
      handleSelectTab(globalDateActiveTab, globalDateRange);
    // }
  }, [globalDateRange]);

  const handleFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
            ? value._id
            : "";
      }
    }

    if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let filters = { ...tempFilters, [event.target.name]: event.target.value };

    if (event.target.name == "limit") {
      filters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      filters[event.target.name] = [];
    }
    setTempFilters(filters);
  };

  const handleClosePopup = () => {
    setActivePopupComponent(false);
    setIsPopupActive(false);
    setTempFilters(filters);
  };

  const handleApplyFiter = () => {
    setFilters(tempFilters);
    setGlobalState("analyticsFilters", {
      ...storedFilters,
      [id]: tempFilters,
    });
  };

  const handleClick = () => {
    if (setActivePopupComponent) setIsPopupActive(true);
    setActivePopupComponent(
      <ChartHolderPopup
        title={title}
        tabs={tabs}
        activeTab={activeTab}
        handleSelectTab={handleSelectTab}
        chart={
          chartProps ? (
            <Chart {...chartProps} data={chartData} />
          ) : (
            <Leaderboard data={chartData} />
          )
        }
        handleFilterChange={handleFilterChange}
        handleClosePopup={handleClosePopup}
        handleApplyFiter={handleApplyFiter}
        filters={tempFilters}
        filterOptions={filterOptions}
        showFilter={showFilter !== false}
        ignoreFilterList={ignoreFilterList}
      />
    );
  };
  useEffect(() => {
    if (isPopupActive) handleClick();
  }, [tempFilters,chartData]);

  return (
    <MDBox
      sx={{
        height: "100%",
        width: "100%",
        padding: "1em",
        position: "relative",
        backgroundColor: white.main,
        transition: 'all 100ms',
        borderRadius: '10px',
        ...(enableDrag ? {
          boxShadow: '0px 0px 15px 1px #0000001a',
          transform: 'rotate(-1deg)'
        }: {})
      }}
      onClick={(e) => {
        if(!enableDrag) e.stopPropagation();
      }}
      onMouseDown={(e) => {if(!enableDrag) e.stopPropagation()}}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <MDBox color={text.main}>{title}</MDBox>
        <MDBox sx={{ display: "flex", alignItems: "center", gap: "2em" }}>
          <MDBox sx={{ display: "flex", alignItems: "center", gap: ".5em" }}>
            {/* {showFilter !== false && <MDBox
              sx={{
                backgroundColor: primary.light,
                fontWeight: typography.fontWeightMedium,
                borderRadius: "1em",
                width: "40px",
                height: "30px",
                textAlign: "center",
                paddingTop: "5px",
                cursor: "pointer",
              }}
              color={text.disabled}
            >
              1M
            </MDBox>} */}
          </MDBox>

          <MDBox
            sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}
            onClick={handleClick}
          >
            {/* {showFilter !== false && ( */}
              <>
                <MDBox sx={{ position: "relative" }}>
                  {filtersCount !== 0 && (
                    <MDBox
                      sx={{
                        position: "absolute",
                        backgroundColor: primary.main,
                        top: "-1.5em",
                        right: "-3.2em",
                        transform: "scale(.8)",
                        paddingTop: '1px',
                        width: "20px",
                        height: "20px",
                        textAlign: "center",
                        fontSize: "12px",
                        borderRadius: "100px",
                      }}
                      color={white.main}
                    >
                      {filtersCount}
                    </MDBox>
                  )}
                </MDBox>
                <span
                  style={{
                    color: text.disabled,
                    fontSize: "1.5em",
                    cursor: "pointer",
                  }}
                  class="material-symbols-outlined"
                >
                  filter_alt
                </span>
              </>
            {/* )} */}
            <span
              style={{
                color: text.disabled,
                fontSize: "1.5em",
                cursor: "pointer",
              }}
              class="material-symbols-outlined"
            >
              open_in_full
            </span>
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          height: "calc(100% - 40px)",
          marginRight: "1.5em",
        }}
        ref={chartProps ? imageRef: null}
      >
        {chartProps ? (
          <Chart {...chartProps} data={chartData} />
        ) : (
          <Leaderboard data={chartData} imageRef={imageRef}/>
        )}
      </MDBox>
      <MDBox
        sx={{
          position: "absolute",
          top: "100%",
          left: "100%",
          transform: "translate(-2.5em, -2em)",
          width: "1.5em",
          height: "1.5em",
        }}

      >
        <span
          style={{
            color: text.disabled,
            fontSize: "1.5em",
            cursor: "pointer",
          }}
          class="material-symbols-outlined"
          onClick={() => downloadImage(imageRef.current, title)}
        >
          photo_camera
        </span>
      </MDBox>
    </MDBox>
  );
};

export default ChartHolder;
