/* eslint-disable prefer-destructuring */

// @mui material components
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles'

export default styled(Chip)(({ theme, ownerState }) => {
  const { palette, functions } = theme
  const { color, variant, darkMode, onClick } = ownerState

  const { white, text, transparent, grey, primary } = palette
  const { rgba } = functions

  // styles for the button with variant="contained"
  const filledStyle = () => {
    // background color value
    const backgroundValue = palette[color] ? palette[color].main : white.main

    // color value
    let colorValue = white.main

    if (!darkMode && (color === 'white' || color === 'light' || !palette[color])) {
      colorValue = text.main
    } else if (darkMode && (color === 'white' || color === 'light' || !palette[color])) {
      colorValue = grey[600]
    }

    // color value when button is focused
    let focusedColorValue = white.main

    if (color === 'white') {
      focusedColorValue = text.main
    } else if (color === 'primary' || color === 'error' || color === 'dark') {
      focusedColorValue = white.main
    }

    return {
      background: backgroundValue,
      color: colorValue,
      ":hover" : {
        backgroundColor: onClick ? primary.main : primary.light,
      },
      '&:disabled': {
        backgroundColor: backgroundValue,
        color: focusedColorValue
      }
    }
  }

  // styles for the button with variant="outlined"
  const outliedStyles = () => {
    // background color value
    const backgroundValue = color === 'white' ? rgba(white.main, 0.1) : transparent.main

    // color value
    const colorValue = palette[color] ? palette[color].main : white.main

    // border color value
    let borderColorValue = palette[color] ? palette[color].main : rgba(white.main, 0.75)

    if (color === 'white') {
      borderColorValue = rgba(white.main, 0.75)
    }

    return {
      background: backgroundValue,
      color: colorValue,
      borderColor: borderColorValue,

      '&:disabled': {
        color: colorValue,
        borderColor: colorValue
      }
    }
  }

  return {
    ...(variant === 'filled' && filledStyle()),
    ...(variant === 'outlined' && outliedStyles()),
  }
})
