import { useTheme } from "@emotion/react";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  Bar,
} from "recharts";
import { COLORS_2 } from "../js/const";
import PropTypes from "prop-types";

const CustomBarChart = ({
  data,
  axisStyle,
  grid,
  graph,
  legend,
  tooltip,
  variant,
  name
}) => {
  const { palette } = useTheme();

  const keys = Object.keys(data[0]);
  const tname = name || Object.keys(data?.[0])?.[0]
  if(keys.indexOf(tname) !== -1) keys.splice(keys.indexOf(tname), 1);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <BarChart
        data={data}
        layout={variant === "horizontal" ? "vertical" : "horizontal"}
        height={"100%"}
      >
        <CartesianGrid strokeDasharray="3 3" {...grid} />
        <XAxis
          type={variant === "horizontal" ? "number" : "category"}
          {...(variant === "horizontal" ? {} : { dataKey: tname })}
          stroke={axisStyle?.stroke || palette.text.light}
          tick={{
            fill: axisStyle?.fontColor || palette.text.disabled,
            fontSize: axisStyle?.fontSize || 12,
          }}
        />
        <YAxis
          type={variant === "horizontal" ? "category" : "number"}
          {...(variant === "horizontal" ? { dataKey: tname } : {})}
          stroke={axisStyle?.stroke || palette.text.light}
          tick={{
            fill: axisStyle?.fontColor || palette.text.disabled,
            fontSize: axisStyle?.fontSize || 12,
          }}
        />
        {tooltip?.show !== false && (
          <Tooltip
            itemStyle={{
              fontSize: axisStyle?.fontSize || 12,
            }}
            contentStyle={tooltip?.contentStyle}
          />
        )}
        {legend?.show !== false && (
          <Legend
            layout={legend?.layout || "horizontal"}
            align={legend?.align || "center"}
            verticalAlign={legend?.verticalAlign || "bottom"}
            wrapperStyle={{
              fontSize: axisStyle?.fontSize || 12,
              textTransform: "capitalize",
            }}
          />
        )}
        {keys.map((key, index) => (
          <Bar
            key={key}
            radius={5}
            dataKey={key}
            background={{ fill: COLORS_2[index] || COLORS_2[0], opacity: ".2" }}
            fill={COLORS_2[index] || COLORS_2[0]}
            barSize={10}
            {...(graph?.[key] || {})}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBarChart.propTypes = {
  variant: PropTypes.oneOf(["horizontal", "vertical"]),
  axisStyle: PropTypes.shape({
    stroke: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.number,
  }),
  grid: PropTypes.shape({
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool,
  }),
  graph: PropTypes.objectOf(
    PropTypes.shape({
      background: PropTypes.string,
      radius: PropTypes.number,
      fill: PropTypes.string,
      barSize: PropTypes.number,
    })
  ),
  legend: PropTypes.shape({
    align: PropTypes.string,
    show: PropTypes.bool,
  }),
  tooltip: PropTypes.shape({
    contentStyle: PropTypes.object,
    show: PropTypes.bool,
  }),
};

export default CustomBarChart;
