// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import LoadingOverlay from "react-loading-overlay";
import Controls from "components/controls/Controls";
import { convertKeyToName } from "utils/conversion";
import { AdminAPI } from "api";
import { useTheme } from "@emotion/react";
import MDChip from "components/MDChip";

const DefaultUserDetailsForm = {
  email_id: {
    type: "Text",
    editable: false,
    required: true,
  },
  name: {
    type: "Text",
    editable: true,
    required: true,
  },

  active_status: {
    type: "DropdownSingleSelect",
    options: ["Available", "Busy", "OOO"],
    editable: true,
    required: false,
  },
  secondary_contact: {
    type: "DropdownSingleSelect",
    options: [],
    editable: true,
    required: false,
  },
  permission: {
    type: "DropdownSingleSelect",
    options: ["admin", "3PA", "3PU", "agent1", "agent2"],
    editable: true,
    required: false,
  },
  manager_email: {
    type: "DropdownSingleSelect",
    options: [],
    editable: true,
    required: false,
  },
  organization: {
    type: "Text",
    editable: true,
    required: false,
  },
};

function UserDetailsForm({
  open,
  handleClose,
  userDetails,
  userList,
  getUsers,
  type = "Update",
}) {
  const [state, setState] = useState({});
  const location = useLocation();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleSubmit = () => {
    let tempState = { ...state };
    let nullFlag = false
    Object.keys(tempState).map((feature) => {
      tempState[feature] = tempState[feature].value || null;
      if(!tempState[feature]) nullFlag = true
    });
    if(nullFlag){
      setGlobalState("error", {
        open: true,
        message: "Please Fill Empty Fields",
        type: "error",
        code: 200,
      });
      return;
    }
    setIsLoading(true);
    if (type == "Create") {
        AdminAPI.addAdminUser(tempState)
          .then((res) => {
            if (res.status == 200) {
              setIsLoading(false);
              handleClose(!open);
              getUsers();
              setGlobalState("error", {
                open: true,
                message: "User created successfully",
                type: "success",
                code: 200,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
    } else {
      AdminAPI.updateAdminUserData(tempState)
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            handleClose(!open);
            getUsers();
            setGlobalState("error", {
              open: true,
              message: "User details updated successfully",
              type: "success",
              code: 200,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const initializeUserDetails = () => {
    let tempState = {};
    Object.keys(DefaultUserDetailsForm).map((feature) => {
      if (DefaultUserDetailsForm[feature].options?.length == 0) {
        DefaultUserDetailsForm[feature].options = userList;
      }

      tempState[feature] = DefaultUserDetailsForm[feature];
      tempState[feature].value = userDetails[feature];
    });

    if (type == "Create") {
      tempState["email_id"].editable = true;
    }
    setState(tempState);
  };

  useEffect(() => {
    if (userDetails) {
      initializeUserDetails();
    }
  }, [userDetails]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "29rem" }}
      >
        <LoadingOverlay
          active={isLoading}
          spinner
        >
          <MDBox p={3}>
            <MDBox
              sx={{
                display:'flex',
                justifyContent: 'space-between',
                fontSize: typography.size['xl'],
                fontWeight: typography.fontWeightBold
              }}
              pb={2}
            >
              <MDBox sx={{fontSize:'inherit'}}>
                {type == "Update" ? "Update User Details" : "Create New User"}
              </MDBox>
              <span class="material-symbols-outlined" style={{color: primary.main}}>
                group_add
              </span>
            </MDBox>
            <Grid container columnSpacing={2}>
              {Object.keys(state).map((feature, index) => {
                let ControlType = Controls[state[feature]?.type];
                return (
                  <Grid
                    item
                    xs={(index < 3)? 12 : 6}
                    sx={{
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <FormControl fullWidth>
                      <ControlType
                        name={feature}
                        variant={(index < 3)? 'standard' : 'outlined'}
                        label={convertKeyToName(feature)}
                        value={state[feature]?.value}
                        onChange={(e, value) => handleChange((value)? { target: { name: feature, value: value } }: e)}
                        disabled={!state[feature]?.editable}
                        options={state[feature]?.options}
                        disableClearable
                      />
                    </FormControl>
                  </Grid>
                );
              })}

              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: "1px solid " + error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />
                  <MDChip
                    sx={{
                      backgroundColor: primary.main,
                      padding: "5px 10px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "140px",
                    }}
                    label={type == "Update" ? "Update" : "Add User"}
                    onClick={handleSubmit}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default UserDetailsForm;
