import { useTheme } from '@emotion/react';
import MDBox from 'components/MDBox';
import { useEffect, useRef } from 'react';
import BottomInput from '../general/BottomInput';
import ContentBox from '../general/ContentBox';
import ExpandableCard from '../general/ExpandableCard';
import SearchBar from '../general/SearchBar';
import useNotes from './hooks/useNotes';
import { notesService } from './service/notesService';
import { formatDate, formatTime } from '../general/utils';
import NotesSkeleton from 'skeletons/forms/NotesSkeleton';
import EmptyResult from 'utils/emptyResult';

const NotesCard = ({ data, myEmail }) => {
    const { palette, typography, functions } = useTheme();
    const { primary, white } = palette;
    const { pxToRem } = functions;

    const myNotesStyle = {
        background: white.main,
        boxShadow: '0 0 5px 1px #00000014'
    }

    return (
        <MDBox
            key={data?.id}
            sx={{
                margin: pxToRem(20),
                marginTop: pxToRem(5)
            }}
        >
            <MDBox
                sx={{
                    fontSize: typography.size['sm'],
                    marginTop: '5px',
                    marginBottom: '5px',
                    textAlign: 'right'
                }}
                color={palette.text.disabled}
            >
                By {data?.sender}
            </MDBox>
            <ExpandableCard
                style={{
                    fontSize: typography.size['sm'],
                    padding: '7px 11px',
                    background: primary.light,
                    borderRadius: '8px',
                    ...((myEmail === data?.sender) ? myNotesStyle : {})
                }}
                color={palette.text.secondary}
                content={data?.message}
            />
            <MDBox
                sx={{
                    fontSize: typography.size['sm'],
                    marginTop: '5px',
                    marginBottom: '5px',
                    textAlign: 'right'
                }}
                color={palette.text.disabled}>
                {formatDate(data?.timestamp)} . {formatTime(data?.timestamp)}
            </MDBox>
        </MDBox>
    )
}

const Notes = ({ caseId }) => {
    const { notes, setNotes, filterdNotes, setFilterdNotes, myEmail, isLoading } = useNotes(caseId)
    const inputRef = useRef(null)
    const contentBoxRef = useRef(null)
    const searchRef = useRef(null)

    const { palette } = useTheme();
    const {white, text } = palette;

    useEffect(() => {
        if (contentBoxRef) contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
    }, [notes])

    const handleClick = () => {
        const data = {
            id: Date(),
            sender: myEmail,
            message: inputRef?.current.innerText,
            timestamp: Date(),
        }
        if (data.message.trim().length) {
            notesService.sendNotes(caseId, inputRef?.current.value)
            if (notes.lenght === filterdNotes.lenght) setFilterdNotes([...notes, data])
            setNotes([...notes, data])
            inputRef.current.innerHTML = ''
            contentBoxRef.current.scrollTop = contentBoxRef.current.scrollHeight;
        }
    }

    const searchNotesBySenderOrContent = () => {
        const filterBy = searchRef?.current.value
        const filteredResult = notes?.filter((data) => data?.sender.includes(filterBy) || data?.message.includes(filterBy))
        setFilterdNotes(filteredResult)
    }

    return (
        <>
            <MDBox
                sx={{
                    background: white.main,
                    paddingBottom: '10px',
                }}
            >
                <SearchBar
                    placeHolder={'Search Notes'}
                    handleKeyUp={searchNotesBySenderOrContent}
                    inputRef={searchRef}
                />
            </MDBox>
            <ContentBox height={"calc(100% - 145px)"} contentBoxRef={contentBoxRef}>
                {!filterdNotes && <NotesSkeleton/>}
                <MDBox>
                    {
                        filterdNotes?.map((data, index) => (
                            <NotesCard key={index} data={data} myEmail={myEmail} />
                        ))
                    }
                </MDBox>
                { filterdNotes?.length === 0 &&  <EmptyResult text={"No Notes Found"} />}
            </ContentBox>
            <MDBox
                sx={{
                    position: 'sticky',
                    bottom: '0',
                    background: white.main,
                    paddingTop: '10px',
                    borderTop: '1px solid '+ text.light
                }}
            >
                <BottomInput
                    placeHolder={'Add New Note'}
                    icon={
                        <span class="material-symbols-outlined">
                            assignment_add
                        </span>
                    }
                    handleClick={handleClick}
                    inputRef={inputRef}
                />
            </MDBox>
        </>
    );
}

export default Notes;