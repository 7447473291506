// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import { setGlobalState } from "Global";
import Input from "./CustomInput";
import { useTheme } from "@emotion/react";
import CustomAutoComplete from "./AutoComplete";
import CustomInput from "./CustomInput";
import MDChip from "components/MDChip";

function CategoryDialog({
  open,
  handleClose,
  categoryList,
  updateCategoryList,
}) {
  const [state, setState] = useState({ categoryName: "", amount: "" });
  const navigation = useNavigate();

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const throwError = (message) => {
    setGlobalState("error", {
      open: true,
      message: message,
      type: "error",
    });
  };

  const handleSubmit = () => {
    if (
      state.categoryName == "" ||
      state.amount == "" ||
      isNaN(parseFloat(state.amount))
    ) {
      throwError("Please select the category and enter the correct amount");
    } else {
      updateCategoryList(
        state.categoryName,
        state.amount != "" ? parseFloat(state.amount) : parseFloat("0")
      );
      handleClose(!open);
    }
  };
  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{
          marginRight: "0%",
          marginLeft: "0%",
          minWidth: "25rem",
          maxWidth: "30rem",
        }}
      >
        <MDBox p={3}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: typography.size["xl"],
              fontWeight: typography.fontWeightBold,
            }}
            pb={2}
          >
            <MDBox sx={{ fontSize: "inherit" }}>
              Add Category and Claim Amount
            </MDBox>
            <span
              class="material-symbols-outlined"
              style={{ color: primary.main }}
            >
              add
            </span>
          </MDBox>
          <MDBox>
            <CustomAutoComplete
              id="categoryName"
              labelId="categoryName-label"
              value={state.categoryName}
              name="categoryName"
              label={"Category Name"}
              onChange={(e, value) => {
                handleChange({
                  target: { name: "categoryName", value: value },
                });
              }}
              options={categoryList}
            />
            <CustomInput
              name={"amount"}
              label={"Amount (ZAR)"}
              value={state.amount}
              onChange={(e) => handleChange(e)}
            />
          </MDBox>

          <MDBox
            borderRadius="lg"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
          >
            <MDChip
              sx={{
                backgroundColor: error.light + "45",
                padding: "5px 10px",
                color: error.main,
                fontWeight: "bold",
                cursor: "pointer",
                border: "1px solid " + error.main,
                width: "140px",
                "&:hover": {
                  backgroundColor: error.light + "45",
                  boxShadow: "0 0 5px 1px " + error.main + "82",
                },
              }}
              label={"Cancel"}
              onClick={() => {
                handleClose(!open);
              }}
            />
            <MDChip
              sx={{
                backgroundColor: primary.main,
                padding: "5px 10px",
                color: white.main,
                fontWeight: "bold",
                cursor: "pointer",
                width: "140px",
              }}
              label={"Add"}
              onClick={handleSubmit}
            />
          </MDBox>
        </MDBox>
      </Paper>
    </Dialog>
  );
}

export default CategoryDialog;
