import { AnalyticsApi } from "api"

export const analyticsService = {
    getCaseStateCounts(filters){
        return AnalyticsApi.getCaseStateCounts(filters).then((res) => {
            return res.data
        })
    },
    getAgentLeaderboard(filters){
        return AnalyticsApi.getClaimsByAgent(filters).then((res) => {
            return res.data
        })
    },
    getClaimsByTime(filters){
        return AnalyticsApi.getClaimsByTime(filters).then((res) => (res.data))
    },
    getInvalidClaimsByCustomer(filters){
        return AnalyticsApi.getInvalidClaimsByCustomer(filters).then((res) => (res.data))
    },
    getClaimCountByReasonCodes(filters){
        return AnalyticsApi.getClaimCountByReasonCodes(filters).then((res) => (res.data))
    },
    getClaimsCountByAgeBucket(filters){
        return AnalyticsApi.getClaimsCountByAgeBucket(filters).then((res) => (res.data))
    },
    getClaimCostByCustomer(filters){
        return AnalyticsApi.getClaimCostByCustomer(filters).then((res) => (res.data))
    },
    getClaimsByCurrentStatus(filters){
        return AnalyticsApi.getClaimsByCurrentStatus(filters).then((res) => (res.data))
    },
    getClaimsByWorkflow(filters){
        return AnalyticsApi.getClaimsByWorkflow(filters).then((res) => (res.data))
    },    
    getClaimsByRetailer(filters){
        return AnalyticsApi.getClaimsByRetailer(filters).then((res) => (res.data))
    },
}