import MDBox from 'components/MDBox';
import MDChip from 'components/MDChip';
import CaseStateChip from 'examples/CaseStateChip';
import { Link } from 'react-router-dom';
import ContentBox from '../general/ContentBox';
import useLinkedCases from './hooks/useLinkedCases';
import { useTheme } from '@emotion/react';
import LinkedCaseSkeleton from 'skeletons/forms/LinkedCaseSkeleton';
import EmptyResult from 'utils/emptyResult';

const LinkedCasesCard = ({ data }) => {
    const { palette, typography } = useTheme();
    const { primary, white, text } = palette;
    const { size } = typography;

    return (
        <MDBox
            sx={{
                borderRadius: '10px',
                boxShadow: '0 0 5px 1px #00000014',
                margin: '20px 10px 20px 5px',
                padding: '15px'
            }}
        >
            <MDBox
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '5px',
                    justifyContent: 'space-between'
                }}
            >
                <MDChip sx={{
                    background: primary.light,
                    color: primary.main,
                    fontSize: size['sm'],
                    padding: `2px 8px`,
                    height: '28px',
                    fontSize: '600'
                }} label={data['Claim Amount (ZAR)']} />

                <CaseStateChip state={data?.case_state.toUpperCase()} />
            </MDBox>

            <MDBox sx={{
                fontSize: size['sm'],
                color: text.disabled,
                margin: '10px 0'
            }}>
                {data?.case_id.toUpperCase()}
            </MDBox>

            <MDBox
                sx={{
                    textAlign: 'right',
                    marginTop: '5px',
                }}
            >
                <Link to={`/caseDetails/${data?.case_id}`} >
                    <MDChip
                        sx={{
                            background: white.main,
                            height: '2em',
                            color: primary.main,
                            border: '1px solid '+ primary.main,
                            cursor: 'pointer'
                        }}
                        label={'View Claim'}
                    />
                </Link>
            </MDBox>
        </MDBox>
    );
}

const LinkedCases = ({ caseId }) => {
    const { linkedCases } = useLinkedCases(caseId)

    return (
        <ContentBox>
            {!linkedCases && <LinkedCaseSkeleton/>}
            {
                linkedCases?.map((data, index) => (
                    <LinkedCasesCard key={index} data={data} />
                ))
            }
            { linkedCases?.length === 0 &&  <EmptyResult text={"No Linked Cases Found"} />}
        </ContentBox>
    );
}

export default LinkedCases;