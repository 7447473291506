import { Link, useNavigate } from "react-router-dom";

// prop-types is library for typechecking of props

// @mui material components

//  React components
import { Avatar, Tooltip } from "@mui/material";
import { userAPI } from "api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import EmptyResult from "utils/emptyResult";
import NotificationSkeleton from "skeletons/profile/notificationSkeleton";
import { setGlobalState } from "Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import InitialsAvatar from "utils/initialsAvatar";
import { useTheme } from "@emotion/react";
import ExpandableCard from "layouts/formDetails/rightPanel/components/general/ExpandableCard";
import {
  formatDate,
  formatTime,
} from "layouts/formDetails/rightPanel/components/general/utils";
import MessageSkeleton from "skeletons/forms/MessageSkeleton";
function RenderNotifications({pastDays, fontSize, setNotificationsCount}) {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (data) => {
    if(data.status != "read"){
      userAPI.updateNotificationStatus(data._id, "read").then((res) => {
        if(res.status == 200){
          const updatedNotifications = notifications.map(notification => 
            notification._id === data._id 
              ? { ...notification, status: "read" }
              : notification
          );
          if(setNotificationsCount) setNotificationsCount((pre) => pre - 1);
          setNotifications(updatedNotifications)
        }
      })
    }
    window.open("/cases/" + data?.case_id, "_blank")
  }

  const getNotifications = () => {
    setIsLoading(true);
    userAPI
      .getNotifications({days: pastDays || 365})
      .then((res) => {
        if (res.status == 200) {
          const sortedData = res.data.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          const unreadCount = sortedData.filter(notification => notification.status !== "read").length;
          if(setNotificationsCount) setNotificationsCount(unreadCount);
          setNotifications(sortedData);
          setShowNotifications(Array(res.data.length).fill(false));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <MDBox sx={{ width: "100%" }}>
      {notifications.length == 0 && isLoading == true && <MessageSkeleton />}
      {notifications.map((data, index) => (
        <NotificationCard data={data} handleClick={handleClick} />
      ))}
      {notifications.length == 0 && isLoading == false && (
        <EmptyResult text={"No New Notifications Found"} fontSize={fontSize} />
      )}
    </MDBox>
  );
}

export default RenderNotifications;

const NotificationCard = ({ data, handleClick }) => {
  const { palette, typography } = useTheme();
  const { primary, white, text, grey } = palette;

  return (
    <MDBox
      my={"1px"}
      sx={{
        padding: "15px 10px",
        borderRadius: '10px',
        opacity: data?.status === "read" ? .7 : 1,
        backgroundColor: data?.status === "read" ? grey[200] : white.main,
      }}
      key={data?.timestamp}
    >
      <MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <MDBox
            sx={{
              height: "38px",
              width: "38px",
              fontSize: typography.size["lg"],
              fontWeight: "bold",
              background: primary.light,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
              position: "relative",
              zIndex: "1",
              border: `1px solid ${palette.primary.main}`,
            }}
            title={data?.name}
          >
            {data?.name[0]?.toUpperCase()}
          </MDBox>
          <MDBox
            sx={{
              lineHeight: "1.2em",
            }}
          >
            <>
              <MDBox color={palette.text.secondary}>{data?.name}</MDBox>
              <MDBox
                sx={{
                  fontSize: "12px",
                }}
                color={palette.text.secondary}
              >
                {data?.from}
              </MDBox>
            </>
          </MDBox>
        </MDBox>
      </MDBox>
      <ExpandableCard
        style={{
          fontSize: typography.size["sm"],
          padding: "7px 0",
          marginLeft: "3px",
          background: data?.status === "read" ? grey[200] : white.main,
          borderRadius: "8px",
        }}
        color={text.secondary}
        content={(data?.header) ? `<b>${data?.header}</b></br>`+ data?.content : data?.content}
      />
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "3px",
        }}
      >
        <MDBox
          sx={{
            fontSize: typography.size["sm"],
            marginTop: "5px",
            marginBottom: "5px",
          }}
          color={palette.text.secondary}
        >
          {formatDate(data?.timestamp)} . {formatTime(data?.timestamp)}
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            cursor: "pointer",
          }}
          color={primary.main}
          onClick={() => handleClick(data)}
        >
          <span style={{ fontSize: "1.2em" }} class="material-symbols-outlined">
            north_east
          </span>
          View Case
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
