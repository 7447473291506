import { useEffect, useState } from "react";

// react-router components
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

//  React components
import MDBox from "components/MDBox";

//  React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

//  React themes
import theme from "assets/theme";

import themePrimary from "assets/theme-primary";

import themeSecondary from "assets/theme-secondary";

//  React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins

//  React routes
// import routes from "routes";

//  React contexts
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "context";

// Images
import { setGlobalState } from "Global";
import { BannerAPI, client, docstoreAPI, docstoreClient } from "api";
import logo from "assets/images/sidenav-logo.png";

import AccessDenied from "layouts/authentication/access-denied";
import CustomLogin from "layouts/authentication/microsoftlogin";
import ResetPassword from "layouts/authentication/reset-password";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import CustomAlert from "utils/alert";
import Banner from "utils/banner";
import { defaultRoutes } from "routes";
import { useGlobalState } from "Global";
import { basicAPI } from "api";
import { createBrowserHistory } from "history";
import { replace } from "stylis";
import axios from "axios";
import OTPAuth from "layouts/authentication/2fa-auth";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [user, setUser] = useState({});
  const history = createBrowserHistory();
  const [bannerState, setBannerState] = useState({});

  const [routes, setRoutes] = useState(null);
  const [errorState] = useGlobalState("error");
  const [userDetails] = useGlobalState("userDetails");
  const [apiError, setApiError] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("Primary");

  client.interceptors.request.use(
    async (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = `Bearer ${userDetails?.access_token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error) => {
      let updatedRequest = await globalErrorHandler(error);
      if (updatedRequest) {
        return updatedRequest;
      }
      return Promise.reject(error);
    }
  );

  const globalErrorHandler = async (error) => {
    const prevRequest = error?.config;
    if (error.response) {
      if ([401].includes(error.response?.status) && prevRequest.url != "/me") {
        // Only one refresh token request should be sent
        if (userDetails?.access_token && !prevRequest.sent) {
          setGlobalState("userDetails", () => {});
          prevRequest.sent = true;
          let newAccessToken = await basicAPI
            .refreshToken()
            .then((res) => {
              if (res.status == 200) {
                const tempUser = res.data;
                setGlobalState("userDetails", (prevGlobalState) => res.data);
                return res.data["access_token"];
              }
            })
            .catch((error) => {
              // Incase of refresh token failure, logout the user and redirect to login page
              if (window.location.pathname != "/authentication/sign-in") {
                basicAPI.logout().then((res) => {
                  if (res.status == 200) {
                    navigation("/authentication/sign-in", {
                      state: { from: history.location, replace: true },
                    });
                    setGlobalState("userDetails", null)
                    setGlobalState("error", {
                      open: true,
                      message: "Login Expired! Please Login Again",
                      type: "error",
                      code: error.response.status,
                    });
                  }
                });
              }
              return null;
            });
          // If new access token is received, update the request header and resend the request
          if (newAccessToken) {
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            client.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
            return client(prevRequest);
          }
        }
      } else if ([422].includes(error.response?.status)) {
        setGlobalState("error", {
          open: true,
          message: "Invalid format. Input value or format is Invalid.",
          type: "error",
          code: error.response.status,
        });
      } else if (
        ![422, 455, 456, 304, 405, 403].includes(error.response?.status)
      ) {
        if (typeof error.response?.data == typeof "") {
          setGlobalState("error", {
            open: true,
            message: error.response?.data,
            type: "error",
            code: error.response.status,
          });
        }
      }
    }
  };

  const unlisten = history.listen(({ location, action }) => {
    console.log(action, location.pathname);
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getBannerState = () => {
    BannerAPI.getBannerStatus()
      .then((res) => {
        if (res.status == 200) {
          setBannerState([]);
        }
      })
      .catch((error) => {
        if ([455].includes(error.response?.status)) {
          let bannerList = error.response.data;
          let tempBannerState = {};
          bannerList.map((banner) => {
            let routeObject = defaultRoutes.find(
              (obj) => obj.name == banner.name
            );
            if (routeObject) {
              tempBannerState[routeObject.key] = banner;
            }
          });
          setBannerState(tempBannerState);
        }
      });
  };

  useEffect(() => {
    if (
      ["/authentication/sign-up", "/authentication/reset-password"].includes(
        window.location.pathname
      )
    ) {
      // basicAPI.userData().then((res)=>{
      //   sessionStorage.setItem("user", JSON.stringify(res.data));
      // })
    } else if (!userDetails.access_token) {
      let currLocation = window.location.pathname;
      if (window.location.pathname != "/logout") {
        basicAPI
          .refreshToken()
          .then((res) => {
            if (res.status == 200) {
              const tempUser = res.data;
              setGlobalState("userDetails", () => res.data);

              // navigation(currLocation);
            }
          })
          .catch((err) => {
            // sessionStorage.setItem("history-url", currLocation);
            if (window.location.pathname != "/authentication/sign-in") {
              navigation("/authentication/sign-in", {
                state: { from: history.location, replace: true },
              });
            }
          });
      }
    }
  }, []);

  useEffect(() => {
    // } else {
    //   client.defaults.headers.common.Authorization = `Bearer ${userDetails["access_token"]}`;
    //   setUser(userDetails);
    //   const temproutes = defaultRoutes.filter((route) => {
    //     if (route && route.access.includes(userDetails.permission)) {
    //       return route;
    //     }
    //   });

    //   setRoutes(temproutes);
    //   if (window.location.pathname == "/authentication/sign-in") {
    //     navigation("/");
    //   }
    //   // navigation("/dashboard")
    // }

    if (Object.keys(bannerState).length > 0) {
      const pattern = new RegExp(Object.keys(bannerState).join("|"), "g");

      let matchedString = window.location.pathname.match(pattern);

      if (matchedString) {
        setGlobalState("banner", {
          open: true,
          message: bannerState[matchedString[0]].message,
          type: bannerState[matchedString[0]].message_type,
        });
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (userDetails?.access_token) {
      client.defaults.headers.common.Authorization = `Bearer ${userDetails["access_token"]}`;
      docstoreClient.defaults.headers.common.Authorization = `Bearer ${userDetails["access_token"]}`;
      setUser(userDetails);

      const temproutes = defaultRoutes.filter((route) => {
        if (route && route.access.includes(userDetails.permission)) {
          return route;
        }
      });
      setRoutes(temproutes);
      getBannerState();
    }
  }, [userDetails]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="medium" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      <ThemeProvider theme={currentTheme!="Primary"?themePrimary:themeSecondary}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {/* {console.log(routes)} */}
            {routes?.length > 0 && (
              <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName="NeatProcess"
                routes={routes}
                user={user}
                setCurrentTheme={setCurrentTheme}
                // onMouseEnter={handleOnMouseEnter}
                // onMouseLeave={handleOnMouseLeave}
              />
            )}
          </>
        )}
        <CustomAlert />
        <Banner />

        <Routes>
          <Route path="/authentication/sign-in" element={<SignIn />} />
          <Route path="/authentication/sign-up" element={<SignUp />} />
          <Route
            path="/authentication/reset-password"
            element={<ResetPassword />}
          />
            <Route
            path="/authentication/verify-email"
            element={<OTPAuth />}
          />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/login-callback" element={<CustomLogin />} />

          {routes?.length > 0 &&
            routes.map((route) => {
              if (
                route &&
                route.path &&
                route.access?.includes(user.permission)
              ) {
                return (
                  <>
                    {
                      <Route
                        path={route.path}
                        element={route.component}
                        key={route.key}
                      />
                    }
                  </>
                );
              }
            })}

          {routes?.length > 0 && (
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    [
                      "agent1",
                      "agent2",
                      "superuser",
                      "admin",
                      "3PA",
                      "3PU",
                    ].includes(user.permission)
                      ? `/${user.id}/cases`
                      : "/access-denied"
                  }
                />
              }
            />
          )}
          {user?.access_token && (
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    [
                      "agent1",
                      "agent2",
                      "superuser",
                      "admin",
                      "3PA",
                      "3PU",
                    ].includes(user.permission)
                      ? `/${user.id}/cases`
                      : "/access-denied"
                  }
                />
              }
            />
          )}
          {/* {user?.access_token === undefined && (
            <Route
              path="*"
              element={
                <Navigate
                  to={"/authentication/sign-in"}
                />
              }
            />
          )} */}
        </Routes>
      </ThemeProvider>
    </>
  );
}
