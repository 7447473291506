import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Controls from "components/controls/Controls";
import UploadFileCard from "layouts/forms/file/uploadFileCard";
import { useEffect, useState } from "react";
import { LottieFiles } from "utils/lottieFiles";
import ActionMessage from "./components/ActionMessage";
import MDChip from "components/MDChip";

function FeatureUpdateAction({
  featureAction,
  index,
  handleChange,
  handlePerformAction,
  loaders,
}) {
  
  return (
    <>
      <MDBox
        sx={{
          fontWeight: "500",
          margin: "0.5rem",
          display: "flex",
          alignItems: "center",
          marginLeft: "0",
        }}
      >
        {(featureAction == null || featureAction) && (
          <ActionMessage
            isloading={loaders["feature_action"]}
            action={featureAction}
            successMessage={"Done"}
            errorMessage={"Please fill these Features"}
            loadingMessage={"Refreshing..."}
          />
        )}
      </MDBox>
      {!loaders["feature_action"] && featureAction && (
        <MDBox px={4}>
          <MDBox
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              columnGap: "2em",
              justifyContent: "center",
            }}
          >
            {featureAction?.map((data, index) => {
              const ControlType = featureAction ? Controls[data?.type] : Controls["Text"];
              return <ControlType
                name={data?.feature_name}
                label={data?.feature_name}
                placeholder={data?.feature_name}
                value={data?.value}
                onChange={(e, value) =>
                (data?.type === "DropdownSingleSelect") ? 
                  handleChange({target: {name: data?.feature_name, value: value}}, "feature_action", data?.type, index)
                :
                  handleChange(e, "feature_action", data?.type, index)
                }
                options={data?.scope}
                keyscopes={data?.keyscope}
              />;
            })}
          </MDBox>

          <MDBox
            sx={{
              display: "flex",
              alignItems: "end",
              cursor: "pointer",
              justifyContent: "end",
            }}
          >
            <MDChip
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                padding: "5px 10px",
                color: theme.palette.white.main,
                fontWeight: "bold",
                cursor: "pointer",
              })}
              label={"Submit"}
              onClick={(e) => handlePerformAction(featureAction)}
            />
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default FeatureUpdateAction;
