import { Dialog } from "@mui/material";
import MDBox from "components/MDBox";
import FormBuilder from "examples/formBuilder/FormBuilder";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

const AddFeatureAndFilesDialog = ({
  defaultInputs,
  inputTypes,
  permission,
  open,
  handleClose,
  handleSubmit,
  activeStep,
  type = "features",
  isLoading,
  heading,
}) => {
  const [formatedInputs, setFormatedInputs] = useState();

  const handleSubmitClick = (data) => {
    const tempFeatures = {};
    const tempPermission = permission;

    Object.keys(data).forEach((key) => {
      const { permissions, name, ...restData } = data[key];
      if (name) tempFeatures[name] = restData;
      Object.keys(permissions).forEach((permissionKey) => {
        if (!tempPermission[permissionKey]) {
          tempPermission[permissionKey] = {
            edit: {},
            view: {},
          };
        }
        if (!tempPermission[permissionKey].edit[activeStep]) {
          tempPermission[permissionKey].edit[activeStep] = { [type]: {} };
        }
        if (!tempPermission[permissionKey].view[activeStep]) {
          tempPermission[permissionKey].view[activeStep] = { [type]: {} };
        }
        if (name) {
          tempPermission[permissionKey].edit[activeStep][type][name] =
            permissions[permissionKey].edit;
          tempPermission[permissionKey].view[activeStep][type][name] =
            permissions[permissionKey].view;
        }
      });
    });

    handleSubmit({ [type]: tempFeatures, permission: tempPermission });
  };

  useEffect(() => {
    if (defaultInputs && permission) {
      const permissionKey = Object.keys(permission);
      let tempInputs = {};
      Object.keys(defaultInputs).forEach((key, index) => {
        let tempInput = defaultInputs[key];
        tempInput["name"] = key;
        permissionKey.forEach((pk) => {
          tempInput["permissions"] = {
            ...tempInput["permissions"],
            [pk]: {
              edit: permission[pk]["edit"][activeStep][type][key],
              view: permission[pk]["view"][activeStep][type][key],
            },
          };
        });
        tempInputs[index] = tempInput;
      });
      setFormatedInputs(tempInputs);
    }
  }, [defaultInputs, permission]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "700px",
          width: "70vw",
          height: "70vh",
        },
      }}
    >
      <style jsx>{`
        ._loading_overlay_wrapper {
          height: 100%;
        }
      `}</style>
      <LoadingOverlay active={isLoading} spinner>
        <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
          <FormBuilder
            defaultInputs={formatedInputs}
            inputTypes={inputTypes}
            handleSubmit={handleSubmitClick}
            heading={heading}
            handleClose={handleClose}
          />
        </MDBox>
      </LoadingOverlay>
    </Dialog>
  );
};

export default AddFeatureAndFilesDialog;
