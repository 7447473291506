import { ButtonBase } from '@mui/material'
import React from 'react'

export default function Button(props) {

    const { text, size, color, variant, onClick, ...other } = props


    return (
        <ButtonBase
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
            >
            {text}
        </ButtonBase>
    )
}
